// 2022-02-24 인수인계
// 기능 : 일정 카드결제 페이지

import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ChatHeader from "./chat_header";
import {
  noData,
  getParameterByName,
  numberWithCommas,
  inputChanger,
  dateForm,
} from "../shared/script";
import TeamworkList from "./teamwork_list";
import createPopup from "./createPopup";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
const Card = () => {
  const navigate = useNavigate();
  const prams = {
    url: `https://api.olapse.fifteenh.io/api/teamwork/all/schedule?`,
    teamwork_id: getParameterByName("idx")
      ? `&teamwork_id=${getParameterByName("idx")}`
      : null,
  };

  const filteringSchedules = (id) => {
    document
      .querySelectorAll("div.data_wrapper > ul.data_list > li")
      .forEach((item, idx) => {
        if (id !== "all") {
          item.classList.remove("active");
          if (id == item.getAttribute("idx")) {
            item.classList.add("active");
            noData(false);
          }
        } else {
          item.classList.add("active");
          noData(false);
        }
        if (
          document.querySelectorAll(
            "div.data_wrapper > ul.data_list > li.active"
          ).length == 0
        ) {
          noData(true);
        }
      });
  };

  const cardClickEv = (target) => {
    filteringSchedules(target.getAttribute("idx"));
  };
  const allEv = () => {
    filteringSchedules("all");
  };
  useEffect(() => {
    inputChanger();
    let isbusiness;
    let scheduleId = null;
    getScheduleData();
    function getScheduleData() {
      const appendTarget = document.querySelectorAll(".data_list")[0];
      inner(
        `https://api.olapse.fifteenh.io/api/teamwork/member/schedule?id=my&status[]=1&status[]=5&use_card=1`
      );
      function inner(url) {
        fetch(url, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.data.schedules.length !== 0) {
              noData(false);
              data.data.schedules.forEach((item, idx) => {
                let className, title;
                if (item.status == 0) {
                  (className = "type_1"), (title = "요청");
                } else if (item.status == 1) {
                  (className = "type_2"), (title = `수락 ${item.name}`);
                } else if (item.status == 2) {
                  (className = "type_3"), (title = `완료 ${item.name}`);
                } else if (item.status == 5) {
                  (className = "type_4"), (title = `보류 ${item.name}`);
                } else if (item.status == 10) {
                  (className = "type_5"), (title = `취소 ${item.name}`);
                }
                const htmlString = `<li idx="${item.teamwork_id}" scheduleId="${
                  item.id
                }" class="new_cal_item active" status="${
                  item.status
                }"> <p class="${className}"></p> <div class="data_list_textArea"> <h1>${title}</h1> <span>${
                  item.contents
                }</span> <h1> <span class="date_created_at">${dateForm(
                  item.created_at
                )}</span> <span class="data_price_detail">${numberWithCommas(
                  item.price
                )}원</span> </h1> </div> </li>`;
                appendTarget.insertAdjacentHTML("beforeend", htmlString);
                document
                  .querySelectorAll(".new_cal_item")[0]
                  .addEventListener("click", function () {
                    _animateRightModule(
                      true,
                      document.querySelectorAll(".d_9")[0]
                    );
                    document.querySelector("#payment_price").value = item.price;
                    document.querySelector("#payment_name").value = item.name;
                    document.querySelector("#payment_phone").value = item.phone;
                    document.querySelector("#payment_card_number").value = "";
                    document.querySelector("#payment_due_date").value = "";
                    scheduleId = this.getAttribute("scheduleId");
                  });
                document
                  .querySelectorAll(".new_cal_item")[0]
                  .classList.remove("new_cal_item");
              });
            } else {
              noData(true);
            }
          });
      }
    }
    document
      .querySelectorAll(".payment_submit_button")[0]
      .addEventListener("click", function () {
        payment(scheduleId);
      });
    //일정 결제하기
    let paymentLoading = true;
    function payment(scheduleId) {
      if (paymentLoading) {
        paymentLoading = false;
        document.querySelectorAll(".chat_loadingPay")[0].style.display = "flex";
        if (
          String(document.querySelector("#payment_name").value).length !== 0 &&
          String(document.querySelector("#payment_card_number").value)
            .length !== 0 &&
          String(document.querySelector("#payment_due_date").value).length !== 0
        ) {
          let formdata = new FormData(),
            apiUrl =
              "https://api.olapse.fifteenh.io/api/teamwork/schedule/payment";
          formdata.append(
            "name",
            document.querySelector("#payment_name").value
          );
          formdata.append(
            "price",
            document.querySelector("#payment_price").value
          );
          formdata.append(
            "card_num",
            document.querySelector("#payment_card_number").value
          );

          let dueDate = document.querySelector("#payment_due_date").value;
          formdata.append(
            "card_expire",
            dueDate.substr(2, 2) + dueDate.substr(0, 2)
          );

          if (scheduleId !== null) {
            formdata.append("schedule_id", scheduleId);
            apiUrl =
              "https://api.olapse.fifteenh.io/api/teamwork/schedule/payment";
          } else {
            apiUrl = "https://api.olapse.fifteenh.io/api/schedule/payment";
          }
          formdata.append(
            "card_quota",
            document.querySelectorAll("#payment_month option:checked")[0].value
          );

          fetch(apiUrl, {
            method: "POST",
            body: formdata,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (data) {
              paymentLoading = true;
              document.querySelectorAll(".chat_loadingPay")[0].style.display =
                "none";
              if (data.success == true) {
                createPopup({
                  type: "custom",
                  title: "결제 완료",
                  content: "결제가 완료되었습니다.",
                  custom: () => {
                    window.location.reload(true);
                  },
                });
              } else {
                createPopup({
                  type: "reTry",
                  title: "결제 실패",
                  content: data.alert,
                });
              }
            });
        } else {
          paymentLoading = true;
          document.querySelectorAll(".chat_loadingPay")[0].style.display =
            "none";
          createPopup({
            type: "reTry",
            title: "결제 실패",
            content: "모든 필수 정보를 입력해주세요.",
          });
        }
      }
    }

    document
      .querySelectorAll(".dir_payment")[0]
      .addEventListener("click", function () {
        scheduleId = null;
        if (isbusiness == true) {
          _animateRightModule(true, document.querySelectorAll(".d_9")[0]);
          document.querySelector("#payment_price").value = "";
          document.querySelector("#payment_name").value = "";
          document.querySelector("#payment_phone").value = "";
          document.querySelector("#payment_card_number").value = "";
          document.querySelector("#payment_due_date").value = "";
        } else {
          createPopup({
            type: "custom_select_2",
            title: "바로결제 안내",
            content: `<span>사업 인증 및 카드결제 연동을 진행한 후에<br>바로결제를 이용할 수 있습니다.</span><br><span>사업자 인증 및 카드결제 연동은<br><p class="blue">[더보기>내프로필>사업자 인증 및 카드결제 연동]</p>에서 신청할 수 있습니다.</span>`,
            firstButtonClickFunction: () => {
              navigate("/more/my_info");
            },
            secondButtonClickFunction: () => {},
            button_text_1: "취소",
            button_text_2: "신청",
          });
        }
      });

    fetch(`https://api.olapse.fifteenh.io/api/mypage`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (!data.data.user_business) {
          isbusiness = false;
        } else if (data.data.user_business.status == 0) {
          isbusiness = false;
        } else if (data.data.user_business.status == 1) {
          isbusiness = true;
        }
      });
  }, []);

  return (
    <>
      <div className="pc_right_area">
        <div className="pc_right_area_top">
          <div className="left">
            <img
              onClick={() => navigate(-1)}
              src="/assets/images/icon_back.svg"
              alt=""
            />
          </div>
          <h1 className="right_area_title">일정 카드결제</h1>
          <div style={{ right: "16px" }} className="right">
            <button className="dir_payment blue">바로결제</button>
          </div>
        </div>
        <TeamworkList clickEv={cardClickEv} allEv={allEv} />
        <div className="data_wrapper">
          <ul className="data_list" />
          <div className="no_data">
            <span>일정이 없습니다.</span>
          </div>
        </div>
      </div>
      {/* 일정 카드 결제 팝업 */}
      <div className="chat_wrapper white_m_wrapper right_popup d_9">
        <div className="white_m_header">
          <img
            onClick={() =>
              _animateRightModule(false, document.querySelectorAll(".d_9")[0])
            }
            src="/assets/images/icon_close_type_1.svg"
            alt=""
          />
          <span>일정 카드결제</span>
        </div>
        <div className="submit_date_wrapper submit_card_wrapper">
          {/* row_1 */}
          <div className="submit_date_row">
            <div className="textArea_row">
              <h1>
                고객명
                <span className="red">*</span>
              </h1>
              <input
                id="payment_name"
                autocomplete="off"
                placeholder="고객명"
                type="text"
              />
            </div>
            <div className="date_picker_row">
              <h1>
                고객연락처
                <span className="red">*</span>
              </h1>
              <input
                id="payment_phone"
                autocomplete="off"
                placeholder="연락처 (‘-’ 제외 입력)"
                type="number"
              />
            </div>
          </div>
          {/* row_2 */}
          <div className="submit_date_row">
            <div className="textArea_row">
              <h1>
                결제금액
                <span className="red">*</span>
              </h1>
              <div
                style={{
                  marginTop: 5,
                  position: "static",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: 44,
                  left: 16,
                  top: 25,
                  background: "#f2f2f2",
                  borderRadius: "8px",
                  flex: "none",
                  order: 1,
                  alignSelf: "stretch",
                  flexGrow: 0,
                  margin: "6px 0px",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
                className="input_outter"
              >
                <input
                  id="payment_price"
                  autocomplete="off"
                  placeholder="금액"
                  type="number"
                />
                <span>원</span>
              </div>
            </div>
            <div className="date_picker_row">
              <h1>
                카드번호
                <span className="red">*</span>
              </h1>
              <input
                id="payment_card_number"
                autocomplete="off"
                placeholder="카드번호 (‘-’ 제외)"
                type="number"
              />
            </div>
            <div className="date_picker_row">
              <h1>
                유효기간
                <span className="red">*</span>
              </h1>
              <input
                id="payment_due_date"
                autocomplete="off"
                className="half_input"
                placeholder="MMYY"
                type="number"
              />
            </div>
          </div>
          {/* row_3 */}
          <div style={{ borderBottom: 0 }} className="submit_date_row">
            <div className="textArea_row">
              <h1>
                할부
                <span className="red">*</span>
              </h1>
              <select id="payment_month">
                <option value={"00"}>일시불</option>
                <option value={"02"}>2개월</option>
                <option value={"03"}>3개월</option>
                <option value={"04"}>4개월</option>
                <option value={"05"}>5개월</option>
                <option value={"06"}>6개월</option>
                <option value={"07"}>7개월</option>
                <option value={"08"}>8개월</option>
                <option value={"09"}>9개월</option>
                <option value={"10"}>10개월</option>
                <option value={"11"}>11개월</option>
                <option value={"12"}>12개월</option>
              </select>
            </div>
          </div>
          <button className="payment_submit_button">등록</button>
        </div>
      </div>
      <div className="chat_loadingPay" style={{ display: "none" }}>
        <div className="load_item" />
        <span>결제 진행중 입니다.</span>
      </div>
    </>
  );
};
export default Card;
