// 2022-02-24 인수인계
// 기능 : 파일 모아보기 페이지입니다.

import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ChatHeader from "./chat_header";
import { noData, getParameterByName } from "../shared/script";
import TeamworkList from "./teamwork_list";

const File = () => {
  const navigate = useNavigate();
  let url;
  let keyword = "";
  let flow = 1,
    chkNumber = 3,
    lastChk = false;
  const getFileList = (teamworkId, all) => {
    const appendTarget = document.querySelectorAll(".file_list_wrapper")[0];
    appendTarget.querySelectorAll(".list_file_row").forEach((item, idx) => {
      item.remove();
    });

    if (all == true) {
      url = `https://api.olapse.fifteenh.io/api/teamwork/all/file?page=${flow}&keyword=${keyword}`;
      inner(url);
    } else {
      url = `https://api.olapse.fifteenh.io/api/teamwork/all/file?page=${flow}&teamwork_id=${teamworkId}&keyword=${keyword}`;
      inner(url);
    }

    function inner(url) {
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            lastChk = flow == data.data.messages.last_page ? true : false;

            if (data.data.messages.data.length !== 0) {
              noData(false);
              data.data.messages.data.forEach((item, idx) => {
                const rowString = `<div class="list_file_row"> <h1 class="created_at_photo">${
                  item.created_at.split(" ")[0]
                }</h1><ul> <li class="new_item"> <p class="${
                  item.file_extension
                }"></p> <div class="file_text_row"> <h1>${
                  item.file_url.split("/")[item.file_url.split("/").length - 1]
                }</h1> <span>${
                  item.file_size
                }KB</span> </div> <h3 class="due_date"> 유효기간 : ~<br /> ${
                  item.expiration_date.split(" ")[0]
                } </h3> </li></ul></div>`;
                appendTarget.insertAdjacentHTML("beforeend", rowString);
                document
                  .querySelectorAll(".new_item")[0]
                  .setAttribute("data-url", item.file_url);
                document
                  .querySelectorAll(".new_item")[0]
                  .setAttribute("data-fx", item.file_extension);
                document
                  .querySelectorAll(".new_item")[0]
                  .addEventListener("click", function () {
                    let downloader = document.createElement("a");
                    downloader.href = `https://api.olapse.fifteenh.io/api/file/download?src=${item.file_url}&file_type=image&file_extension=${item.file_extension}`;
                    downloader.download = "result.jpg";
                    downloader.click();
                  });
                document
                  .querySelectorAll(".new_item")[0]
                  .classList.remove("new_item");
              });
              insert();
              function insert() {
                document
                  .querySelectorAll(".list_file_row")
                  .forEach((item, idx) => {
                    if (
                      item.nextElementSibling &&
                      item.querySelectorAll(".created_at_photo")[0]
                        .textContent ==
                        item.nextElementSibling.querySelectorAll(
                          ".created_at_photo"
                        )[0].textContent
                    ) {
                      item.nextElementSibling
                        .querySelectorAll("li")[0]
                        .classList.add("new_item");
                      item
                        .querySelectorAll("ul")[0]
                        .insertAdjacentElement(
                          "beforeend",
                          item.nextElementSibling
                            .querySelectorAll("li")[0]
                            .cloneNode(true)
                        );
                      item.nextElementSibling.remove();

                      document
                        .querySelectorAll(".new_item")[0]
                        .addEventListener("click", function () {
                          let downloader = document.createElement("a");
                          downloader.href = `https://api.olapse.fifteenh.io/api/file/download?src=${this.getAttribute(
                            "data-url"
                          )}&file_type=image&file_extension=${this.getAttribute(
                            "data-fx"
                          )}`;
                          downloader.download = "result.jpg";
                          downloader.click();
                        });
                      document
                        .querySelectorAll(".new_item")[0]
                        .classList.remove("new_item");

                      insert();
                    }
                  });
              }

              if (flow < chkNumber && !lastChk) {
                flow++;
                if (all == true) {
                  url = `https://api.olapse.fifteenh.io/api/teamwork/all/file?page=${flow}&keyword=${keyword}`;
                } else {
                  url = `https://api.olapse.fifteenh.io/api/teamwork/all/file?page=${flow}&teamwork_id=${teamworkId}&keyword=${keyword}`;
                }
                inner(url);
              } else {
                chkNumber = flow + 2;
                document.querySelectorAll(
                  ".chat_loadingDate"
                )[0].style.display = "none";
              }
            } else {
              noData(true);
            }
          } else {
            // createPopup({
            //     type: "custom",
            //     title: "데이터 조회 실패",
            //     content: data.alert,
            //     custom: () => {
            //         location.href = "/sub/info/subs_1.html";
            //     },
            // });
          }
        });
    }

    //스크롤 하단 체크
    function bottomChk() {
      let scrollTop = document.documentElement.scrollTop,
        innerHeight = window.innerHeight,
        scrollHeight = document.querySelectorAll("body")[0].scrollHeight;

      if (scrollTop + innerHeight >= scrollHeight) {
        return true;
      } else {
        return false;
      }
    }

    //추가 페이지 있을 시 추가 세팅
    function addPage() {
      if (bottomChk() && !lastChk && flow !== 1) {
        flow++;
        if (all == true) {
          url = `https://api.olapse.fifteenh.io/api/teamwork/all/file?page=${flow}&keyword=${keyword}`;
        } else {
          url = `https://api.olapse.fifteenh.io/api/teamwork/all/file?page=${flow}&teamwork_id=${teamworkId}&keyword=${keyword}`;
        }
        inner(url);
      }
    }
    document.addEventListener("scroll", function () {
      addPage();
    });
  };
  const fileClickEv = (target) => {
    getFileList(target.getAttribute("idx"), false);
  };
  const allEv = () => {
    getFileList(null, true);
  };
  useEffect(() => {
    getFileList(null, true);

    const searchModuleElement = document.querySelectorAll(
      "div.top.search_module"
    )[0];
    //닫기
    document
      .querySelectorAll(".close_search_module")[0]
      .addEventListener("click", function () {
        searchModuleElement.style.display = "none";
      });
    //열기
    document
      .querySelectorAll(".open_search_module")[0]
      .addEventListener("click", function () {
        searchModuleElement.style.display = "flex";
        document
          .querySelectorAll(
            "div.top.search_module div.left div.search_input_wrapper input#search_input"
          )[0]
          .focus();
      });

    document
      .querySelectorAll(
        "div.top.search_module div.left div.search_input_wrapper input#search_input"
      )[0]
      .addEventListener("keydown", function (e) {
        if (e.keyCode == 13) {
          searchModuleElement.style.display = "none";
          keyword = this.value;
          getFileList(null, true);
        }
      });

    document
      .querySelectorAll(".search_submit_button")[0]
      .addEventListener("click", function () {
        keyword = document.querySelectorAll(
          "div.top.search_module div.left div.search_input_wrapper input#search_input"
        )[0].value;
        searchModuleElement.style.display = "none";
        getFileList(null, true);
      });
    document
      .querySelectorAll(
        "div.top.search_module div.left div.search_input_wrapper p.del_text"
      )[0]
      .addEventListener("click", function () {
        document.querySelectorAll(
          "div.top.search_module div.left div.search_input_wrapper input#search_input"
        )[0].value = "";
      });
  }, []);
  return (
    <>
      <div className="pc_right_area">
        <ChatHeader title="파일 모아보기" isSearch={1} isOpenMenu={1} />
        <TeamworkList clickEv={fileClickEv} allEv={allEv} />
        <div className="file_list_wrapper">
          <div className="list_file_row" />
          <div className="no_data">
            <span>파일이 없습니다.</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default File;
