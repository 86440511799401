// 2022-02-24 인수인계
// 기능 : 페이지 전체에서 공용으로 사용될 수 있는 함수들입니다.

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
import axios from "axios";

const getParameterByName = (name) => {
  //eslint-disable-next-line
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(window.location.search);
  return results == null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

const toggleClass = (trigger, target, className) => {
  trigger.addEventListener("click", function () {
    if (target.classList.contains(className)) {
      target.classList.remove(className);
    } else {
      target.classList.add(className);
    }
  });
};
//이미지 뷰어
const imgViewerFunction = (trigger, imgUrl, name, item) => {
  const viewer = document.querySelectorAll(".img_viewer")[0],
    viewerCloseElement = document.querySelectorAll(".close_viewer")[0],
    viewerSizeControllerText = document.querySelectorAll(
      "div.img_viewer div.img_viewer_bottom div.size_controller span"
    )[0];
  //시작
  trigger.addEventListener("click", function () {
    viewer.style.display = "block";
    viewer.style.background = `url('${imgUrl}') no-repeat`;
    document.querySelectorAll(
      "div.img_viewer div.img_viewer_header span"
    )[0].textContent = name;
    document
      .querySelectorAll(".img_viewer_downloader")[0]
      .setAttribute(
        "href",
        `https://api.olapse.fifteenh.io/api/file/download?src=${imgUrl}&file_type=image&file_extension=${
          item.file_extension ? item.file_extension : ""
        }`
      );
    viewer.style.backgroundPosition = "center";
    changeImgSize("contain");
  });
  function changeImgSize(size) {
    if (size !== "contain") {
      (viewerSizeControllerText.textContent = `${size}%`),
        viewerSizeControllerText.setAttribute("size", size),
        (viewer.style.backgroundSize = size + "%");
    } else {
      (viewerSizeControllerText.textContent = `자동`),
        viewerSizeControllerText.setAttribute("size", 50),
        (viewer.style.backgroundSize = "contain");
    }
  }
};
const noData = (bool) => {
  if (bool == true) {
    document.querySelectorAll(".no_data").forEach((item) => {
      item.style.display = "flex";
    });
  } else {
    document.querySelectorAll(".no_data").forEach((item) => {
      item.style.display = "none";
    });
  }
};

const phoneCall = (number) => {
  document.location.href = `tel:${number}`;
};

const IsLogin = () => {
  const navigate = useNavigate();
  useEffect(() => {
    !localStorage.getItem("token") &&
      window.location.replace("https://olapse.com");
  }, []);
};

const closeAllPopup = () => {
  document.querySelectorAll(".member_add_module")[0] &&
    (document.querySelectorAll(".member_add_module")[0].style.display = "none");
  document.querySelectorAll(".view_profile_module")[0].style.display = "none";
  document.querySelectorAll(".view_my_profile")[0].style.display = "none";
  document.querySelectorAll(".view_member_profile")[0].style.display = "none";
  document.querySelectorAll(".report_popup_wrapper")[0].style.display = "none";

  document.querySelectorAll(".member_add_module_box")[0].style.display = "none";
  document.querySelectorAll(".add_for_number_box")[0].style.display = "none";

  document.querySelectorAll(".add_for_number_result_box")[0].style.display =
    "none";
  document.querySelectorAll(".add_me_wrapper_box")[0].style.display = "none";
  document.querySelectorAll(".add_for_id_box")[0].style.display = "none";
  document.querySelectorAll(".report_sub_popup")[0].style.display = "none";
  document
    .querySelectorAll(
      "div.view_my_profile.view_member_profile img.view_my_profile_more_button"
    )[0]
    .classList.remove("active");
};

const timeForToday = (yyyy, mm, dd, hh, mm2, ss) => {
  const today = new Date();
  const timeValue = new Date(yyyy, Number(mm) - 1, dd, hh, mm2, ss);
  const betweenTime = Math.floor(
    (today.getTime() - timeValue.getTime()) / 1000 / 60
  );
  if (Number(betweenTime) < 1) return "방금전";
  if (Number(betweenTime) < 60) {
    return `${betweenTime}분전`;
  }
  const betweenTimeHour = Math.floor(Number(betweenTime) / 60);
  if (Number(betweenTimeHour) < 24) {
    return `${betweenTimeHour}시간전`;
  }
  const betweenTimeDay = Math.floor(Number(betweenTime) / 60 / 24);
  if (Number(betweenTimeDay) < 365) {
    return `${betweenTimeDay}일전`;
  }
  return `${Math.floor(betweenTimeDay / 365)}년전`;
};

//오늘 날짜 구하기
const getToday = (month) => {
  if (month) {
    var today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth() + 1 + month;
    var date = today.getDate();

    function doubleMon(monthData) {
      if (String(monthData).length == 2) {
        return false;
      } else {
        return true;
      }
    }
    if (date < 10) {
      return (
        year + "-" + (doubleMon(month) ? "0" + month : month) + "-" + "0" + date
      );
    } else {
      return year + "-" + (doubleMon(month) ? "0" + month : month) + "-" + date;
    }
  } else {
    var today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth() + 1;
    var date = today.getDate();

    function doubleMon(monthData) {
      if (String(monthData).length == 2) {
        return false;
      } else {
        return true;
      }
    }
    if (date < 10) {
      return (
        year + "-" + (doubleMon(month) ? "0" + month : month) + "-" + "0" + date
      );
    } else {
      return year + "-" + (doubleMon(month) ? "0" + month : month) + "-" + date;
    }
  }
};

const dateForm = (itemDateVal) => {
  var week = ["일", "월", "화", "수", "목", "금", "토"];

  var itemDate = new Date(itemDateVal.replace(/\-/g, "/")),
    itemDayWeek = week[itemDate.getDay()],
    nowHour = itemDate.getHours(),
    nowMt = itemDate.getMinutes(),
    itemTimeType = nowHour < 12 ? "오전" : "오후",
    itemY = itemDate.getFullYear(),
    itemM = itemDate.getMonth() + 1,
    itemD = itemDate.getDate();
  nowHour = nowHour == 12 ? 12 : nowHour % 12;

  if (itemM < 10) {
    itemM = "0" + itemM;
  }
  if (itemD < 10) {
    itemD = "0" + itemD;
  }

  if (nowHour < 10) {
    nowHour = "0" + nowHour;
  }
  if (nowMt < 10) {
    nowMt = "0" + nowMt;
  }

  var itemDateForm =
    itemY +
    "." +
    itemM +
    "." +
    itemD +
    ".(" +
    itemDayWeek +
    ") " +
    itemTimeType +
    " " +
    nowHour +
    ":" +
    nowMt;
  return itemDateForm;
};
const filteringBtns = (targetEl, activeClass) => {
  document.querySelectorAll(targetEl).forEach(function (e, i) {
    e.addEventListener("click", function () {
      var elements = Array.from(document.querySelectorAll(targetEl));
      var others = elements.filter(function (element) {
        return element !== e;
      });
      e.classList.add(activeClass);
      others.forEach(function (item) {
        item.classList.remove(activeClass);
      });
    });
  });
};

const activeCh = (item, allItem) => {
  // if(!item.classList.contains("active")){
  allItem.forEach(function (val, idx) {
    val.classList.remove("active");
    if (allItem.length - 1 == idx) {
      item.classList.add("active");
    }
  });
  // }
};

const numberWithCommas = (x) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return 0;
  }
};

//일정 계산기
function getDateStr(myDate) {
  let year = myDate.getFullYear(),
    month = myDate.getMonth() + 1,
    day = myDate.getDate();
  month = month < 10 ? "0" + String(month) : month;
  day = day < 10 ? "0" + String(day) : day;
  return year + "-" + month + "-" + day;
}
//일주일 전
const lastWeek = () => {
  const date = new Date(),
    dayOfMonth = date.getDate();
  date.setDate(dayOfMonth - 7);
  return getDateStr(date);
};

const imgViewerFunctionSetting = () => {
  const viewer = document.querySelectorAll(".img_viewer")[0],
    viewerCloseElement = document.querySelectorAll(".close_viewer")[0],
    viewerSizeControllerText = document.querySelectorAll(
      "div.img_viewer div.img_viewer_bottom div.size_controller span"
    )[0];
  let size = 50;
  //다운로드 처리
  document
    .querySelectorAll(
      "div.img_viewer div.img_viewer_header img.download_img"
    )[0]
    .addEventListener("click", function () {
      viewer.querySelectorAll("a")[0].click();
    });
  //닫기
  viewerCloseElement.addEventListener("click", function () {
    viewer.style.display = "none";
    changeImgSize("contain");
  });
  //사이즈 체인저
  function changeImgSize(size) {
    if (size !== "contain") {
      viewerSizeControllerText.textContent = `${size}%`;
      viewerSizeControllerText.setAttribute("size", size);
      viewer.style.backgroundSize = size + "%";
    } else {
      viewerSizeControllerText.textContent = `자동`;
      viewerSizeControllerText.setAttribute("size", 50);
      viewer.style.backgroundSize = "contain";
    }
  }
  //사이즈 체인지
  document
    .querySelectorAll("div.img_viewer div.img_viewer_bottom ul li")[0]
    .addEventListener("click", function () {
      (size = size - 10), changeImgSize(size);
    });
  document
    .querySelectorAll("div.img_viewer div.img_viewer_bottom ul li")[1]
    .addEventListener("click", function () {
      (size = size + 10), changeImgSize(size);
    });
};
const fetcherWithToken = (url, token) =>
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      return result.data;
    });

const inputChanger = () => {
  function preventNonNumbersInInput(event) {
    var characters = String.fromCharCode(event.which);
    if (!/[0-9]/.test(characters)) {
      event.preventDefault();
    }
  }
  function pasteTest(event) {
    window.setTimeout(() => {
      var characters = event.target.value;
      window.setTimeout(() => {
        if (!/^\d+$/.test(characters)) {
          event.target.value = event.target.value.replace(/\D/g, "");
        }
      });
    });
  }
  if (document.querySelectorAll('input[type="number"]')[0]) {
    document.querySelectorAll('input[type="number"]').forEach((item, idx) => {
      item.setAttribute("inputmode", "decimal");
      item.addEventListener("change", preventNonNumbersInInput);
      item.addEventListener("paste", pasteTest);
      item.addEventListener("blur", pasteTest);
    });
  }
};

const nameChk = (item) => {
  var regExp = /[0-9\{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi;
  if (item.value.length > 10) {
    item.value = item.value.substr(0, 10);
  } else {
    if (regExp.test(item.value)) {
      item.value = item.value.substring(0, item.value.length - 1);
    }
  }
};

export {
  inputChanger,
  getParameterByName,
  toggleClass,
  noData,
  IsLogin,
  phoneCall,
  closeAllPopup,
  getToday,
  filteringBtns,
  activeCh,
  lastWeek,
  numberWithCommas,
  imgViewerFunction,
  imgViewerFunctionSetting,
  timeForToday,
  fetcherWithToken,
  dateForm,
  nameChk,
};
