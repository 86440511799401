// 2022-02-24 인수인계
// 기능 : 팀워크 생성페이지입니다.

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import "../assets/css/cal.css";
import {
  getParameterByName,
  toggleClass,
  IsLogin,
  noData,
  getToday,
  numberWithCommas,
  inputChanger,
} from "../shared/script.js";
import createPopup from "../components/createPopup";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
import MainMenu from "../components/main_menu";
import TeamworkList from "../components/teamworkList";
import anime from "animejs";
import Basic from "../components/basic";
import ChatInner from "../components/chat_inner";
import ChatHeader from "../components/chat_header";
import * as Calendar from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import AddMember from "../components/addMember";
const Create2 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    inputChanger();
    init();
    let teamworkAddChk = 0;
    function createTeamWork() {
      if (teamworkAddChk == 0) {
        teamworkAddChk = 1;
        if (
          String(document.querySelector("#teamwork_name").value).length !== 0
        ) {
          if (
            document
              .querySelectorAll(".is_password")[0]
              .classList.contains("active")
          ) {
            if (
              String(document.querySelector("#teamwork_password_2").value)
                .length !== 0
            ) {
              createTeamworkExcute();
            } else {
              teamworkAddChk = 0;
              createPopup({
                type: "reTry",
                title: "팀워크 생성 실패",
                content: "팀워크 비밀번호를 입력해주세요.",
              });
            }
          } else {
            createTeamworkExcute();
          }
        } else {
          teamworkAddChk = 0;
          createPopup({
            type: "reTry",
            title: "팀워크 생성 실패",
            content: "팀워크 명을 입력해주세요.",
          });
        }
      }
    }

    fetch("https://api.olapse.fifteenh.io/api/mypage", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.data.user_business == null) {
          document.querySelectorAll(".is_card")[0].classList.remove("active");
        } else {
          if (data.data.user_business.status == 0) {
            document.querySelectorAll(".is_card")[0].classList.remove("active");
          } else {
            document.querySelectorAll(".is_card")[0].classList.add("active");
          }
        }
      });

    document
      .querySelectorAll(".is_card")[0]
      .addEventListener("click", function () {
        var $this = this;
        if (this.classList.contains("active")) {
          fetch("https://api.olapse.fifteenh.io/api/mypage", {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (data) {
              if (
                data.data.user_business == null ||
                data.data.user_business.status == 0
              ) {
                createPopup({
                  type: "reTry",
                  title: "인증 실패",
                  content: "사업자 인증 및 PG 사 연동 후 이용할 수 있습니다.",
                });
                $this.classList.remove("active");
              }
            });
        }
      });

    function createTeamworkExcute() {
      let formdata = new FormData();
      getParameterByName("idArr")
        .split(",")
        .forEach((item, idx) => {
          formdata.append("users[]", item);
        });

      formdata.append("title", document.querySelector("#teamwork_name").value);
      if (
        document.querySelectorAll(
          "div.create_wrapper_2 div.photo_area label input"
        )[0].files[0]
      ) {
        formdata.append(
          "image",
          document.querySelectorAll(
            "div.create_wrapper_2 div.photo_area label input"
          )[0].files[0],
          document.querySelectorAll(
            "div.create_wrapper_2 div.photo_area label input"
          )[0].files[0].name
        );
      }
      formdata.append(
        "use_card",
        document.querySelectorAll(".is_card")[0].classList.contains("active")
          ? "1"
          : "0"
      );

      formdata.append(
        "use_password",
        document
          .querySelectorAll(".is_password")[0]
          .classList.contains("active")
          ? "1"
          : "0"
      );

      formdata.append(
        "use_view_profile",
        document
          .querySelectorAll(".is_forbidden")[0]
          .classList.contains("active")
          ? "1"
          : "0"
      );

      if (
        document
          .querySelectorAll(".is_password")[0]
          .classList.contains("active")
      ) {
        formdata.append(
          "password",
          document.querySelector("#teamwork_password_2").value
        );
      }

      fetch(`https://api.olapse.fifteenh.io/api/teamwork/register`, {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            createPopup({
              type: "custom",
              title: "팀워크 생성 완료",
              content: "팀워크를 생성하였습니다.",
              custom: () => {
                navigate("/chat?idx=" + data.data.teamwork_id);
              },
            });
          } else {
            teamworkAddChk = 0;
            createPopup({
              type: "reTry",
              title: "팀워크 생성 실패",
              content: data.alert,
            });
          }
        });
    }

    function togglePassword() {
      document
        .querySelectorAll(".is_password")[0]
        .addEventListener("click", function () {
          if (!this.classList.contains("active")) {
            document.querySelector("#teamwork_password_2").style.display =
              "none";
          } else {
            document.querySelector("#teamwork_password_2").style.display =
              "block";
          }
        });
    }
    function toggleActive(element, className) {
      element.addEventListener("click", function () {
        this.classList.contains(className)
          ? this.classList.remove(className)
          : this.classList.add(className);
      });
    }
    function imgUpload(trigger, target) {
      // trigger.addEventListener("input", function (e) {
      //     let fr = new FileReader();
      //     fr.readAsDataURL(this.files[0]);
      //     fr.onload = function () {
      //         target.style.background = `url(${this.result}) no-repeat`;
      //     };
      // });

      trigger.addEventListener("change", function (e) {
        if (trigger.files && trigger.files[0]) {
          let fr = new FileReader();
          fr.onload = function () {
            target.style.background = `url(${this.result}) no-repeat`;
          };
          fr.readAsDataURL(trigger.files[0]);
        }
      });
    }
    function init() {
      imgUpload(
        document.querySelectorAll(
          "div.create_wrapper_2 div.photo_area label input"
        )[0],
        document.querySelectorAll(
          "div.create_wrapper_2 div.photo_area label"
        )[0]
      );
      document.querySelectorAll(".toggle_button").forEach((item, idx) => {
        toggleActive(item, "active");
      });
      togglePassword();
    }

    function nameChk(item) {
      var regExp =
        /[0123456789\{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi;
      let regExpE =
        /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/gi;
      let regExpE2 =
        /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
      let regExpE3 = /[\u{1F004}-\u{1F9E6}]|[\u{1F600}-\u{1F9D0}]/gu;
      if (item.value.length > 10) {
        item.value = item.value.substr(0, 10);
      } else {
        if (regExpE.test(item.value)) {
          item.value = item.value.replace(regExpE, "");
        }
        if (regExp.test(item.value)) {
          item.value = item.value.replace(regExp, "");
        }
        if (regExpE2.test(item.value)) {
          item.value = item.value.replace(regExpE2, "");
        }
        if (regExpE3.test(item.value)) {
          item.value = item.value.replace(regExpE3, "");
        }
      }
    }
    document
      .querySelector("#teamwork_name")
      .addEventListener("keyup", function () {
        nameChk(this);
      });
    document
      .querySelector("#teamwork_name")
      .addEventListener("keydown", function () {
        nameChk(this);
      });

    document
      .querySelector("#teamwork_name")
      .addEventListener("input", function () {
        if (String(this.value).length !== 0) {
          document
            .querySelectorAll(".create_submit_button")[0]
            .classList.add("active");
        } else {
          document
            .querySelectorAll(".create_submit_button")[0]
            .classList.remove("active");
        }
      });
    document
      .querySelectorAll(".create_submit_button")[0]
      .addEventListener("click", function () {
        createTeamWork();
      });
  }, []);
  return (
    <>
      <div className="wrap">
        <MainMenu />
        <AddMember />
        <div className="chat_wrapper white_m_wrapper">
          <div className="white_m_header">
            <img
              onClick={() => navigate(-1)}
              src="/assets/images/icon_back.svg"
              alt=""
            />
            <span>팀워크 개설</span>
          </div>
          <div className="create_wrapper_2">
            <div className="photo_area">
              <label>
                <input type="file" accept="image/*" />
                <p className="icon_photo" />
              </label>
            </div>
            <div className="input_wrapper">
              <h1>팀워크 명</h1>
              <input
                id="teamwork_name"
                placeholder="ex) 회사 명, 프로젝트 명"
                type="text"
                className="addTeam_name"
                maxLength="10"
              />
            </div>
            <ul>
              <li>
                <div className="create_col">
                  <h1>일정 카드 결제 받기</h1>
                  <span>
                    사업자 인증 및 카드결제 연동 후 이용할 수 있습니다.
                  </span>
                </div>
                <div className="toggle_button is_card">
                  <p />
                </div>
              </li>
              <li>
                <div className="flex_inner">
                  <div className="create_col">
                    <h1>팀워크 비밀번호 설정</h1>
                    <span>팀워크 링크로 합류하는 멤버에게만 적용합니다.</span>
                  </div>
                  <div className="toggle_button is_password">
                    <p />
                  </div>
                </div>
                <input
                  style={{ display: "none" }}
                  id="teamwork_password_2"
                  type="password"
                />
              </li>
              <li>
                <div className="create_col">
                  <h1>멤버간 대화, 프로필보기 제한</h1>
                  <span>
                    팀장 및 부팀장을 제외한 멤버들간의 대화 및 프로필 보기가
                    제한됩니다.
                  </span>
                </div>
                <div className="toggle_button is_forbidden">
                  <p />
                </div>
              </li>
            </ul>
            <button className="create_submit_button">완료</button>
          </div>
        </div>

        <Basic />
      </div>
    </>
  );
};

export default Create2;
