// 2022-02-24 인수인계
// 기능 : 팀워크 공지사항 작성 컴포넌트입니다.

import { useEffect } from "react";
import createPopup from "./createPopup";
import { getParameterByName } from "../shared/script";

const WriteTeamworkNotice = () => {
  const backToChat = () => {
    document.querySelectorAll(".writeTeamworkNotice")[0].style.display = "none";
    document.querySelectorAll(".chatting_area")[0].style.display = "flex";
    document.querySelectorAll(".edit_checker")[0].removeAttribute("notice");
  };

  const writeNotice = () => {
    if (
      String(document.querySelector("#noti_title").value).length !== 0 &&
      String(document.querySelector("#noti_content").value).length !== 0
    ) {
      const formdata = new FormData();

      if (
        !document.querySelectorAll(".edit_checker")[0].getAttribute("notice")
      ) {
        formdata.append("teamwork_id", getParameterByName("idx"));
      } else if (
        document.querySelectorAll(".edit_checker")[0].getAttribute("notice")
      ) {
        formdata.append(
          "teamwork_notice_id",
          document.querySelectorAll(".edit_checker")[0].getAttribute("notice")
        );
      }

      formdata.append("title", document.querySelector("#noti_title").value);
      formdata.append(
        "contents",
        document.querySelector("#noti_content").value
      );

      fetch(
        !document.querySelectorAll(".edit_checker")[0].getAttribute("notice")
          ? `https://api.olapse.fifteenh.io/api/teamwork/notice/register`
          : `https://api.olapse.fifteenh.io/api/teamwork/notice/modify`,
        {
          method: "POST",
          body: formdata,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          window.location.reload(true);
        });
    } else {
      createPopup({
        type: "reTry",
        title: "등록실패",
        content: "제목과 내용을 1글자 이상 입력해주세요.",
      });
    }
  };

  return (
    <>
      <div
        style={{ display: "none" }}
        className="pc_right_area writeTeamworkNotice"
      >
        <div className="pc_right_area_top pc_right_area_top_white notice_right_area">
          <div className="left">
            <img
              onClick={() => backToChat()}
              src="/assets/images/icon_back.svg"
              alt=""
            />
          </div>
          <h1 className="right_area_title">공지사항 작성</h1>
        </div>
        <div className="write_wrapper">
          <div className="row">
            <h1>공지 제목</h1>
            <input id="noti_title" placeholder="공지 제목" type="text" />
          </div>
          <div className="row">
            <h1>공지 내용</h1>
            <textarea id="noti_content" placeholder="공지 내용" />
          </div>
          <button onClick={() => writeNotice()} className="submit_notice">
            작성완료
          </button>
        </div>
        <div className="edit_checker"></div>
      </div>
    </>
  );
};
export default WriteTeamworkNotice;
