// 2022-02-24 인수인계
// 기능 : 로고를 포함한 헤더입니다.

import {useNavigate} from "react-router-dom";

const UserHeader = (props) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="header">
                <div onClick={() => navigate(-1)} className="left">
                    <img src="/assets/images/icon_back.svg" alt="historyBack" />
                </div>
                {props.isCenter && (
                    <div className="center">
                        <img onClick={() => navigate("/")} src="/assets/images/logo.svg" alt="logo" />
                    </div>
                )}
            </div>
        </>
    );
};

export default UserHeader;
