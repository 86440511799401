// 2022-02-24 인수인계
// 기능 : 회원가입 스탭2 페이지입니다.

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import UserHeader from "../components/userHeader";
import {
  getParameterByName,
  toggleClass,
  inputChanger,
} from "../shared/script.js";
import createPopup from "../components/createPopup";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";

const Account2 = () => {
  const navigate = useNavigate();
  let passwordRef =
    /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;

  const samePassword = () => {
    const originEl = document.querySelector("#password"),
      copyEl = document.querySelector("#password_confirm");
    if (originEl.value == copyEl.value) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    inputChanger();
  });
  const submitAccount = () => {
    let formdata = new FormData();
    formdata.append("login_id", localStorage.getItem("login_id"));
    formdata.append("password", document.querySelector("#password").value);
    formdata.append("name", localStorage.getItem("name"));
    formdata.append("phone", localStorage.getItem("phone"));
    formdata.append("certification_code", localStorage.getItem("code"));
    fetch("https://api.olapse.fifteenh.io/api/join", {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          createPopup({
            type: "custom",
            title: "화원가입 완료",
            content: `등록하신 아이디 ${localStorage.getItem(
              "login_id"
            )} 로 <br /> 회원가입이 완료되었습니다.`,
            custom: () => {
              navigate("/login");
            },
          });
        } else {
          createPopup({
            type: "reTry",
            title: "화원가입 실패",
            content: data.alert,
          });
        }
      });
  };

  function end() {
    if (
      samePassword() &&
      passwordRef.test(document.querySelector("#password").value)
    ) {
      submitAccount();
    } else if (!passwordRef.test(document.querySelector("#password").value)) {
      createPopup({
        type: "reTry",
        title: "비밀번호 재확인",
        content:
          "비밀번호는 영문,숫자,특수문자를 포함한 <br /> 8~20자리로 입력해주세요.",
      });
    } else if (!samePassword()) {
      createPopup({
        type: "reTry",
        title: "비밀번호 불일치",
        content: "비밀번호가 일치하지 않습니다.",
      });
    }
  }

  return (
    <>
      <div id="wrap">
        <UserHeader isCenter="true" />
        <div className="user_wrapper">
          <h1 className="page_title">계정 설정</h1>
          <div className="input_wrapper">
            <div className="input_row">
              <h1>비밀번호</h1>
              <div className="input_outter">
                <input
                  id="password"
                  placeholder="비밀번호 (영문+숫자+특수문자 8~20자리)"
                  type="password"
                />
              </div>
              <div className="input_outter">
                <input
                  id="password_confirm"
                  placeholder="비밀번호 확인"
                  type="password"
                />
              </div>
            </div>
          </div>
          <button className="button_type_1" onClick={() => end()}>
            완료
          </button>
        </div>
      </div>
    </>
  );
};

export default Account2;
