// 2022-02-24 인수인계
// 기능 : anime.js를 사용한 알림 함수 모음

import Anime, {anime} from "react-anime";
//등장 애니메이션(top down)
const _animationAppearTopDown = (targetElement) => {
    targetElement.style.display = "flex";
    anime({
        targets: targetElement,
        easing: "easeOutExpo",
        opacity: [0, 1],
        translateY: ["-15px", 0],
        duration: 500,
    });
};

//등장 애니메이션(left)
const _animationAppearLeft = (targetElement) => {
    targetElement.style.display = "flex";
    anime({
        targets: targetElement,
        easing: "easeOutExpo",
        opacity: [0, 1],
        translateX: ["-5%", 0],
        duration: 400,
    });
};

//퇴장 애니메이션(right)
const _animationDisappearRight = (targetElement) => {
    anime({
        targets: targetElement,
        easing: "easeOutExpo",
        opacity: [1, 0],
        translateX: [0, "-5%"],
        duration: 300,
        complete: function (i) {
            document.querySelectorAll(".popup_wrapper")[0].remove();
        },
    });
};

//등장 애니메이션(right)
const _animationAppearRight = (targetElement) => {
    anime.remove(targetElement);
    targetElement.style.display = "flex";
    anime({
        targets: targetElement,
        easing: "easeOutExpo",
        opacity: [0, 1],
        translateX: ["5%", 0],
        duration: 400,
    });
};

//퇴장 애니메이션(left)
const _animationDisappearLeft = (targetElement) => {
    anime.remove(targetElement);
    anime({
        targets: targetElement,
        easing: "easeOutExpo",
        opacity: [1, 0],
        translateX: [0, "5%"],
        duration: 300,
        complete: function (i) {
            targetElement.style.display = "none";
        },
    });
};

//퇴장 애니메이션(bottom up)
const _animationDisappearBottomUp = (targetElement) => {
    anime({
        targets: targetElement,
        easing: "easeOutExpo",
        opacity: [1, 0],
        translateY: [0, "-15px"],
        duration: 300,
        complete: function (i) {
            if (document.querySelectorAll(".popup_wrapper")[0]) {
                document.querySelectorAll(".popup_wrapper")[0].remove();
            }
        },
    });
};

//퇴장 애니메이션V2(bottom up)
const _animationDisappearBottomUpV2 = (targetElement, wrapperElement) => {
    anime({
        targets: targetElement,
        easing: "easeOutExpo",
        opacity: [1, 0],
        translateY: [0, "-15px"],
        duration: 300,
        complete: function (i) {
            wrapperElement.style.display = "none";
            targetElement.style.display = "none";
        },
    });
};

//퇴장 애니메이션V3(bottom up)
const _animationDisappearBottomUpV3 = (targetElement) => {
    anime({
        targets: targetElement,
        easing: "easeOutExpo",
        opacity: [1, 0],
        translateY: [0, "-15px"],
        duration: 300,
        complete: function (i) {
            targetElement.style.display = "none";
        },
    });
};

//팝업 등장 애니메이션(top down)
const _popupAnimationAppearTopDown = (wrapper, targetElement) => {
    document.body.style.overflow = "hidden";
    document.querySelectorAll(".background_black")[0].style.display = "block";
    wrapper.style.display = "flex";

    if (window.matchMedia("(min-width: 768px)").matches) {
        anime({
            targets: targetElement,
            easing: "easeOutExpo",
            opacity: [0, 1],
            translateY: ["-15px", 0],
            duration: 500,
        });
    } else {
        anime({
            targets: targetElement,
            easing: "easeOutExpo",
            opacity: [0, 1],
            translateY: ["100%", 0],
            duration: 500,
        });
    }
};

//팝업 퇴장 애니메이션(bottom up)
const _popupAnimationDisappearBottomUp = (wrapper, targetElement, isRemove) => {
    document.body.style.overflow = "visible";
    document.querySelectorAll(".background_black")[0].style.display = "none";
    if (window.matchMedia("(min-width: 768px)").matches) {
        anime({
            targets: targetElement,
            easing: "easeOutExpo",
            opacity: [1, 0],
            translateY: [0, "-15px"],
            duration: 300,
            complete: function (i) {
                if (isRemove == true) {
                    wrapper.remove();
                } else {
                    wrapper.style.display = "none";
                }
            },
        });
    } else {
        anime({
            targets: targetElement,
            easing: "easeOutExpo",
            opacity: [1, 0],
            translateY: [0, "30%"],
            duration: 300,
            complete: function (i) {
                if (isRemove == true) {
                    wrapper.remove();
                } else {
                    wrapper.style.display = "none";
                }
            },
        });
    }
};

//bottom up 100% 등장 애니메이션
const _agreePopupActive = (wrapper, targetElement) => {
    wrapper.style.display = "flex";
    anime({
        targets: targetElement,
        easing: "easeOutExpo",
        opacity: [0, 1],
        translateY: ["100%", 0],
        duration: 500,
    });
};

//bottom up 100% 퇴장 애니메이션
const _agreePopupOut = (wrapper, targetElement) => {
    anime({
        targets: targetElement,
        easing: "easeOutExpo",
        opacity: [1, 0],
        translateY: [0, "30%"],
        duration: 300,
        complete: function () {
            wrapper.style.display = "none";
        },
    });
};

// 컨트롤러 등장 애니메이션
const _controllerActive = (controllerElement, trigger) => {
    if (trigger) {
        trigger.style.pointerEvents = "none";
        trigger.parentNode.style.pointerEvents = "none";
    }

    if (controllerElement.getAttribute("floated") !== "true") {
        anime({
            targets: controllerElement,
            easing: "easeOutExpo",
            opacity: [0, 1],
            translateY: ["15px", "0"],
            duration: 300,
            complete: function (i) {
                controllerElement.setAttribute("floated", true);
                if (trigger) {
                    trigger.style.pointerEvents = "auto";
                    trigger.parentNode.style.pointerEvents = "auto";
                }
            },
        });
    }
};

// 컨트롤러 퇴장 애니메이션
const _controllerDisappear = (controllerElement, trigger) => {
    if (trigger) {
        trigger.style.pointerEvents = "none";
        trigger.parentNode.style.pointerEvents = "none";
    }
    if (controllerElement.getAttribute("floated") !== "false") {
        anime({
            targets: controllerElement,
            easing: "easeOutExpo",
            opacity: [1, 0],
            translateY: ["0", "15px"],
            duration: 300,
            complete: function (i) {
                controllerElement.setAttribute("floated", false);
                if (trigger) {
                    trigger.style.pointerEvents = "auto";
                    trigger.parentNode.style.pointerEvents = "auto";
                }
            },
        });
    }
};

const _animateRightModule = (bool, controllerElement) => {
    if (bool == true) {
        //열기
        controllerElement.style.display = "block";
        anime({
            targets: controllerElement,
            easing: "easeOutExpo",
            translateX: ["100%", "0"],
            duration: 300,
        });
    } else {
        //닫기
        anime({
            targets: controllerElement,
            easing: "easeOutExpo",
            translateX: ["0", "100%"],
            duration: 300,
            complete: function (i) {
                controllerElement.style.display = "none";
            },
        });
    }
    if (document.querySelectorAll(".common_more_wrapper")[0]) {
        document.querySelectorAll(".common_more_wrapper")[0].style.display = "none";
        document.querySelectorAll(".view_schedule_more_button")[0] && document.querySelectorAll(".view_schedule_more_button")[0].classList.remove("active");
    }
};

export {_animationAppearTopDown, _animationAppearLeft, _animationDisappearRight, _animationAppearRight, _animationDisappearLeft, _animationDisappearBottomUp, _animationDisappearBottomUpV2, _animationDisappearBottomUpV3, _popupAnimationAppearTopDown, _popupAnimationDisappearBottomUp, _agreePopupActive, _agreePopupOut, _controllerActive, _controllerDisappear, _animateRightModule};
