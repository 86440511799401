// 2022-02-24 인수인계
// 기능 : 사업자 인증 페이지입니다.

import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { numberWithCommas, toggleClass, getToday } from "../shared/script";
import ChatHeader from "./chat_header";
import createPopup from "./createPopup";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
import "../assets/css/agree_text.css";
const Submitb = () => {
  const navigate = useNavigate();
  useEffect(() => {
    let uploadedfileArr = [];
    init();

    function passPhone(phoneNumber) {
      const patternPhone =
        /^((01[1|6|7|8|9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/;
      if (!patternPhone.test(phoneNumber)) {
        return false;
      } else {
        return true;
      }
    }
    function CheckEmail(emailValue) {
      // const reg_email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
      const reg_email =
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
      if (emailValue.indexOf(" ") !== -1) {
        emailValue.replace(/ /g, "");
      }

      if (!reg_email.test(emailValue)) {
        return false;
      } else {
        return true;
      }
    }
    function submitInfo() {
      if (CheckEmail(document.querySelector("#b_email").value)) {
        if (passPhone(document.querySelector("#b_phone_number").value)) {
          if (
            String(document.querySelector("#b_name").value).length !== 0 &&
            String(document.querySelector("#name").value).length !== 0 &&
            String(document.querySelector("#b_number").value).length !== 0 &&
            String(document.querySelector("#address_1").value).length !== 0 &&
            String(document.querySelector("#address_2").value).length !== 0 &&
            String(document.querySelector("#b_phone_number").value).length !==
              0 &&
            String(document.querySelector("#b_email").value).length !== 0
          ) {
            if (document.querySelector("#b_agree").checked == true) {
              let formdata = new FormData();
              formdata.append("name", document.querySelector("#b_name").value);
              formdata.append(
                "ceo_name",
                document.querySelector("#name").value
              );
              formdata.append(
                "business_number",
                document.querySelector("#b_number").value
              );
              formdata.append(
                "address",
                document.querySelector("#address_1").value
              );
              formdata.append(
                "address_detail",
                document.querySelector("#address_2").value
              );
              formdata.append(
                "phone",
                document.querySelector("#b_phone_number").value
              );
              formdata.append(
                "email",
                document.querySelector("#b_email").value
              );

              if (uploadedfileArr.length !== 0) {
                uploadedfileArr.forEach((item, idx) => {
                  formdata.append("files[]", item, item.name);
                });
              }

              fetch(`https://api.olapse.fifteenh.io/api/business/register`, {
                method: "POST",
                body: formdata,
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              })
                .then(function (response) {
                  return response.json();
                })
                .then(function (data) {
                  if (data.success == true) {
                    createPopup({
                      type: "custom",
                      title: "요청완료",
                      content: "사업자정보 인증을 요청하였습니다.",
                      custom: () => {
                        navigate("/more");
                      },
                    });
                  } else {
                    createPopup({
                      type: "reTry",
                      title: "요청 실패",
                      content: data.alert,
                    });
                  }
                });
            } else {
              createPopup({
                type: "reTry",
                title: "요청 실패",
                content: "약관에 동의해주세요.",
              });
            }
          } else {
            createPopup({
              type: "reTry",
              title: "요청 실패",
              content: "모든 정보를 입력해주세요.",
            });
          }
        } else {
          createPopup({
            type: "reTry",
            title: "요청 실패",
            content: "휴대폰 번호를 정확히 입력해주세요.",
          });
        }
      } else {
        createPopup({
          type: "reTry",
          title: "요청 실패",
          content: "이메일 주소를 정확히 입력해주세요.",
        });
      }
    }
    //파입 업로드 기능
    function fileUpload(trigger, appendTarget) {
      trigger.addEventListener("input", function (e) {
        if (document.querySelectorAll(".added_file")[0]) {
          document.querySelectorAll(".added_file").forEach((item, idx) => {
            item.remove();
          });
        }

        for (let i = 0; i < e.target.files.length; i++) {
          uploadedfileArr.push(e.target.files[i]);
        }
        uploadedfileArr.forEach(function (item, idx) {
          const HTMLString = `<li class="added_file"> <p class="new_file_del_button file_del_button"></p> <div class="file_icon"></div> <span class="file_name">${
            item.name.split("/")[item.name.split("/").length - 1]
          }</span> <span class="file_size">${item.size}kb</span> </li>`;
          setTimeout(function () {
            appendTarget.insertAdjacentHTML("afterbegin", HTMLString);

            document
              .querySelectorAll(".new_file_del_button")[0]
              .addEventListener("click", function () {
                uploadedfileArr.splice(
                  Array.from(
                    document.querySelectorAll(".new_file_del_button")
                  ).indexOf(this),
                  1
                ),
                  this.parentNode.remove();
              });
            document
              .querySelectorAll(".new_file_del_button")[0]
              .classList.remove("new_file_del_button");
          }, 100);
        });
      });
    }

    function init() {
      activeAgreePopup();
      fileUpload(
        document.querySelectorAll(
          "div.submit_b_wrapper ul.b_submit_file_list_2 li.file_add_button input"
        )[0],
        document.querySelectorAll(".b_submit_file_list_2")[0]
      );
    }

    function activeAgreePopup() {
      document
        .querySelectorAll("div.submit_b_wrapper div.agree_submit_b")[0]
        .addEventListener("click", function (e) {
          e.target.classList.contains("agree_submit_b") &&
            _agreePopupActive(
              document.querySelectorAll(".agree_popup_wrapper")[0],
              document.querySelectorAll(".agree_popup_wrapper")[0]
            );
        });
      document
        .querySelectorAll(".agree_popup_wrapper button")[0]
        .addEventListener("click", function () {
          _agreePopupOut(
            document.querySelectorAll(".agree_popup_wrapper")[0],
            document.querySelectorAll(".agree_popup_wrapper")[0]
          );
        });
    }

    document
      .querySelectorAll(".b_submit_button")[0]
      .addEventListener("click", function () {
        submitInfo();
      });
  }, []);

  const address = () => {
    new daum.Postcode({
      oncomplete: function (data) {
        document.querySelector("#address_1").value = data.address;
        document.getElementById("addrPopup").style.display = "none";
      },
    }).embed(document.getElementById("addrPopup"));
    document.getElementById("addrPopup").style.display = "block";
  };

  const addressClose = () => {
    document.getElementById("addrPopup").style.display = "none";
  };

  return (
    <>
      <div style={{ paddingBottom: "4rem" }} className="pc_right_area">
        <ChatHeader title="사업자 인증" />
        <div className="submit_b_wrapper">
          <ul>
            <li className="b_submit_row">
              <h1>상호명</h1>
              <input id="b_name" placeholder="상호명" type="text" />
            </li>
            <li className="b_submit_row">
              <h1>대표자명</h1>
              <input id="name" placeholder="대표자명" type="text" />
            </li>
            <li className="b_submit_row">
              <h1>사업자번호</h1>
              <input id="b_number" placeholder="사업자번호" type="number" />
            </li>
            <li className="b_submit_row">
              <h1>사업장주소</h1>
              <input
                onClick={() => address()}
                id="address_1"
                readOnly
                placeholder="주소검색"
                type="text"
              />
              <input id="address_2" placeholder="상세주소" type="text" />
            </li>
            <li className="b_submit_row">
              <h1>담당자연락처</h1>
              <input
                id="b_phone_number"
                placeholder="연락처 (‘-’ 제외)"
                type="number"
              />
            </li>
            <li className="b_submit_row">
              <h1>담당자이메일</h1>
              <input id="b_email" placeholder="이메일" type="text" />
            </li>
          </ul>
          <div className="b_submit_textArea">
            <h1>사진/파일 첨부</h1>
            <h2>
              <span>사업자 등록증, 대표자 신분증, 계좌 사본</span> 의 사진 또는
              pdf파일을 첨부해주세요. 계좌 사본은 카드결제 연동(PG) 및 카드 결제
              금액을 정산받는 수단입니다.
            </h2>
          </div>
          <ul className="b_submit_file_list b_submit_file_list_2">
            <li className="file_add_button">
              <label>
                <input type="file" />
              </label>
            </li>
          </ul>
          <div className="agree_submit_b">
            <label>
              <input id="b_agree" type="checkbox" />
              <span>개인정보 수집 및 제3자 제공 동의</span>
            </label>
          </div>
          <button className="b_submit_button">
            사업자 인증 및 카드결제 연동 요청
          </button>
        </div>
      </div>

      <div className="agree_popup_wrapper">
        <div className="agree_header">
          <h1>개인정보 수집 및 제3자 제공 동의</h1>
        </div>
        <div className="agree_content">
          <p className="c68">
            <span className="c30">
              개인/가맹점(신용) 정보활용 필수적동의서{" "}
            </span>
          </p>
          <p className="c84">
            <span className="c0">
              주식회사 인피니소프트(이하 '회사')는 전자금융거래법, 정보통신망
              이용촉진 및 정보보호 등에 관한 법률, 전자상거래 등에서의
              소비자보호에 관한 법률, 개인정보보호법 등에 관한 법률 및
              행정기관의 행정지침을 준수하고, 관련 법 령에 의거한 개인정보
              취급방침을 정하여 “이용자” 권익을 보호하고 있습니다. 회사는
              개인정보가 어떠한 목적으로 수집 및 이용되고 있으며, 개인정보를
              위해 어떠한 조치가 취해지고 있는지 알려드립니다.
            </span>
          </p>
          <p className="c19">
            <span className="c0" />
          </p>
          <h3 className="c69">
            <span className="c38">개인정보 [필수적] 수집 및 이용</span>
          </h3>
          <ol className="c12 lst-kix_list_6-0 start" start={1}>
            <li className="c80 li-bullet-0">
              <span className="c0">
                개인정보의 수집 및 이용목적: 회사는 수집한 개인정보를 다음의
                목적을 위해 활용합니다.{" "}
              </span>
            </li>
            <li className="c80 li-bullet-0">
              <span className="c0">
                가. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
              </span>
            </li>
          </ol>
          <p className="c47">
            <span className="c0">
              - 서비스 가입, 변경 및 해지, 요금정산 등 서비스 관련 문의 등을
              포함한 이용계약 관련 사항의 처리 - 청구서 등의 발송, 금융거래 본인
              인증 등 - 통신 과금 서비스 이용 시 구매상품 결제 및 결제와 관련된
              서비스 제공 나. 고객 관리 : 서비스 이용에 따른 본인확인, 개인식별,
              공지사항의 전달, 고객 불만 및 민원처리 등
            </span>
          </p>
          <ol className="c12 lst-kix_list_6-0" start={3}>
            <li className="c45 li-bullet-1">
              <span className="c0">수집하는 개인정보 항목</span>
            </li>
          </ol>
          <p className="c58">
            <span className="c0">
              가. “이용자”의 성명(가맹점의경우 대표자명, 담당자명), 생년월일,
              휴대폰번호(가맹점의 경우 대표자 휴대폰번호, 담당자 휴대폰번호),
              전화번호, 이메일주소(가맹점의 경우 대표자 이메일주소, 담당자
              이메일주소), 상호명, 사업자 번호(법인번호), 업종 및 업태, 쇼핑몰
              URL, 사업장주소, 대표번호, 팩스번호, 거래대금지급정보(결제은행,
              계좌번호, 계좌명), 상품, 용역거래정보 등
            </span>
          </p>
          <p className="c71">
            <span className="c0">
              나. 상기 명시된 개인정보 수집항목 이외의 서비스 이용과정이나
              서비스 처리과정에서 추가 정보(접속 IP/MAC Address, 쿠키, e-mail,
              서비스 접속 일시, 서비스 이용기록, 불량 혹은 비정상 이용기록,
              결제기록)들이 자동 혹은 수 동으로 생성되어 수집 될 수 있습니다.
            </span>
          </p>
          <ol className="c12 lst-kix_list_6-0" start={4}>
            <li className="c45 li-bullet-1">
              <span className="c0">개인정보의 보유 및 이용기간</span>
            </li>
          </ol>
          <p className="c54">
            <span className="c0">
              “이용자”의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이
              달성되면 지체 없이 파기합니다. 단, 전자금융거래법,
              전자상거래등에서의 소비자보호에 관한법률 등 관련 법령에 의하여
              보존할 필요가 있는 경우 관련 법령 에서 정한 일정한 기간 동안
              개인정보를 보존합니다. 이 경우 회사는 보관하는 정보를 그 보관의
              목적으로만 이용하며 보존기간은 아래와 같습니다.
            </span>
          </p>
          <p className="c23">
            <span className="c0" />
          </p>
          <p className="c23">
            <span className="c0" />
          </p>
          <p className="c23">
            <span className="c0" />
          </p>
          <p className="c23">
            <span className="c0" />
          </p>
          <p className="c23">
            <span className="c0" />
          </p>
          <p className="c43">
            <span className="c0" />
          </p>
          <a id="t.2b4a7449ff3dfc9f5a5d8fde5d93dceca636c310" />
          <a id="t.0" />
          <table className="c15">
            <tbody>
              <tr className="c73">
                <td className="c4 c33" colSpan={1} rowSpan={1}>
                  <p className="c55">
                    <span className="c7">보관하는 정보</span>
                  </p>
                </td>
                <td className="c34 c33" colSpan={1} rowSpan={1}>
                  <p className="c60">
                    <span className="c7">보존 근거</span>
                  </p>
                </td>
                <td className="c10 c33" colSpan={1} rowSpan={1}>
                  <p className="c81">
                    <span className="c7">보존기간</span>
                  </p>
                </td>
              </tr>
              <tr className="c48">
                <td className="c4" colSpan={1} rowSpan={1}>
                  <p className="c37">
                    <span className="c7">
                      계약 또는 청약 철회 등에 관한 기록
                    </span>
                  </p>
                </td>
                <td className="c34" colSpan={1} rowSpan={1}>
                  <p className="c37">
                    <span className="c7">
                      전자상거래 등에서의 소비자보호에 관한 법률
                    </span>
                  </p>
                </td>
                <td className="c10" colSpan={1} rowSpan={1}>
                  <p className="c3">
                    <span className="c6">5년</span>
                  </p>
                </td>
              </tr>
              <tr className="c48">
                <td className="c4" colSpan={1} rowSpan={1}>
                  <p className="c28">
                    <span className="c7">
                      대금결제 및 재화 등의 공급에 관한 기록
                    </span>
                  </p>
                </td>
                <td className="c34" colSpan={1} rowSpan={1}>
                  <p className="c28">
                    <span className="c7">
                      전자상거래 등에서의 소비자보호에 관한 법률
                    </span>
                  </p>
                </td>
                <td className="c10" colSpan={1} rowSpan={1}>
                  <p className="c3">
                    <span className="c6">5년</span>
                  </p>
                </td>
              </tr>
              <tr className="c20">
                <td className="c4" colSpan={1} rowSpan={1}>
                  <p className="c28">
                    <span className="c7">
                      건당 1만원 초과 전자금융거래에 관한 기록
                    </span>
                  </p>
                </td>
                <td className="c34" colSpan={1} rowSpan={1}>
                  <p className="c28">
                    <span className="c7">전자금융거래법</span>
                  </p>
                </td>
                <td className="c10" colSpan={1} rowSpan={1}>
                  <p className="c3">
                    <span className="c6">5년</span>
                  </p>
                </td>
              </tr>
              <tr className="c48">
                <td className="c4" colSpan={1} rowSpan={1}>
                  <p className="c37">
                    <span className="c7">
                      소비자의 불만 또는 분쟁 처리에 관한 기록
                    </span>
                  </p>
                </td>
                <td className="c34" colSpan={1} rowSpan={1}>
                  <p className="c37">
                    <span className="c7">
                      전자상거래 등에서의 소비자보호에 관한 법률
                    </span>
                  </p>
                </td>
                <td className="c10" colSpan={1} rowSpan={1}>
                  <p className="c3">
                    <span className="c6">3년</span>
                  </p>
                </td>
              </tr>
              <tr className="c48">
                <td className="c4" colSpan={1} rowSpan={1}>
                  <p className="c76">
                    <span className="c7">
                      건당 1만원 이하 전자금융거래에 관한 기록
                    </span>
                  </p>
                </td>
                <td className="c34" colSpan={1} rowSpan={1}>
                  <p className="c76">
                    <span className="c7">전자금융거래법</span>
                  </p>
                </td>
                <td className="c10" colSpan={1} rowSpan={1}>
                  <p className="c3">
                    <span className="c6">1년</span>
                  </p>
                </td>
              </tr>
              <tr className="c48">
                <td className="c4" colSpan={1} rowSpan={1}>
                  <p className="c37">
                    <span className="c7">본인확인에 관한 기록</span>
                  </p>
                </td>
                <td className="c34" colSpan={1} rowSpan={1}>
                  <p className="c37">
                    <span className="c7">
                      정보통신 이용촉진 및 정보보호 등에 관한 법률
                    </span>
                  </p>
                </td>
                <td className="c10" colSpan={1} rowSpan={1}>
                  <p className="c32">
                    <span className="c6">6개월</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p className="c50">
            <span className="c0" />
          </p>
          <h3 className="c52">
            <span className="c38">
              개인정보 [필수적] 제 3자 &nbsp;제공 및 위탁
            </span>
          </h3>
          <ol className="c12 lst-kix_list_5-0 start" start={1}>
            <li className="c42 li-bullet-2">
              <span className="c0">
                회사는 제휴서비스 및 부가서비스 제공을 위하여 개인정보를 아래와
                같이 제3자에게 제공하고 있습니다. (아래 개인정보 제공받는 자가
                변동되는 경우 홈페이지상 개인정보취급방침으로 고지 합니다)
              </span>
            </li>
          </ol>
          <p className="c27">
            <span className="c0" />
          </p>
          <a id="t.5efb6854e9dbc8f3aceb2c0613534207213cdabc" />
          <a id="t.1" />
          <table className="c41">
            <tbody>
              <tr className="c35">
                <td className="c25 c33" colSpan={1} rowSpan={1}>
                  <p className="c70">
                    <span className="c7">제공받는 자</span>
                  </p>
                </td>
                <td className="c29 c33" colSpan={1} rowSpan={1}>
                  <p className="c65">
                    <span className="c7">이용목적</span>
                  </p>
                </td>
                <td className="c46 c33" colSpan={1} rowSpan={1}>
                  <p className="c18">
                    <span className="c7">개인정보 항목</span>
                  </p>
                </td>
                <td className="c14" colSpan={1} rowSpan={1}>
                  <p className="c49">
                    <span className="c7">제공받는 자의 보유 및 이용기간</span>
                  </p>
                </td>
              </tr>
              <tr className="c31">
                <td className="c25" colSpan={1} rowSpan={1}>
                  <ul className="c12 lst-kix_list_4-0 start">
                    <li className="c66 li-bullet-3">
                      <span className="c7">신용카드사 :</span>
                      <span className="c6">
                        국민/비씨/롯데/삼성/NH농협/현대/신한/하나
                      </span>
                    </li>
                    <li className="c79 li-bullet-4">
                      <span className="c7">PG사: </span>
                      <span className="c6">㈜케이에스넷/세틀뱅크㈜</span>
                    </li>
                  </ul>
                </td>
                <td className="c29" colSpan={1} rowSpan={1}>
                  <p className="c8">
                    <span className="c6">
                      신용카드 결제 (제휴서비스 및 부가 서 비스 제공)
                    </span>
                  </p>
                </td>
                <td className="c46" colSpan={1} rowSpan={6}>
                  <p className="c22">
                    <span className="c7" />
                  </p>
                  <p className="c22">
                    <span className="c7" />
                  </p>
                  <p className="c27">
                    <span className="c7" />
                  </p>
                  <p className="c83">
                    <span className="c7">
                      사업자정보(회사 명, 대표자명, 대표 자 생년월일, 홈페 이지
                      주소, 사업자 등록번호, 전화번 호, 팩스번호, 이메 일주소,
                      사업장주 소, 담당자명, 담당 자 휴대폰번호 등)
                    </span>
                  </p>
                </td>
                <td className="c17" colSpan={1} rowSpan={6}>
                  <p className="c22">
                    <span className="c7" />
                  </p>
                  <p className="c43">
                    <span className="c7" />
                  </p>
                  <p className="c51">
                    <span className="c6">
                      정보제공일로부터 서 비스 해지일 또는 이 용계약 종료일까지
                      보 유 및 이용(단, 관계법 령에 따라 보존할 필 요성이 있는
                      경우는 규정에 따라 보존)
                    </span>
                  </p>
                </td>
              </tr>
              <tr className="c57">
                <td className="c25" colSpan={1} rowSpan={1}>
                  <ul className="c12 lst-kix_list_3-0 start">
                    <li className="c67 li-bullet-5">
                      <span className="c6">금융결제원</span>
                    </li>
                    <li className="c24">
                      <span className="c7">은행 : </span>
                      <span className="c6">
                        경남/광주/국민/기업/농협/대구/부산/산업/새마을금고/수협/신한/신협/
                        외환/우리/우체국/ 전북/제주/하나/씨티/SC제일은행
                      </span>
                    </li>
                  </ul>
                  <p className="c11">
                    <span className="c7">-증권: </span>
                    <span className="c6">
                      동양/미래에셋/삼성/신한/한투/한화
                    </span>
                  </p>
                </td>
                <td className="c29" colSpan={1} rowSpan={1}>
                  <p className="c77">
                    <span className="c6">
                      계좌이체 결제 (제휴서비스 및 부가 서 비스 제공)
                    </span>
                  </p>
                </td>
              </tr>
              <tr className="c31">
                <td className="c25" colSpan={1} rowSpan={1}>
                  <ul className="c12 lst-kix_list_2-0 start">
                    <li className="c56 li-bullet-5">
                      <span className="c7">은행 :</span>
                      <span className="c6">
                        기업/국민/수협/농협/우리/씨티/대구/부산/광주/경남/우체국/하나/신한
                      </span>
                    </li>
                    <li className="c67 li-bullet-3">
                      <span className="c6">쿠콘㈜/㈜세틀뱅크</span>
                    </li>
                  </ul>
                </td>
                <td className="c29" colSpan={1} rowSpan={1}>
                  <p className="c64">
                    <span className="c6">
                      가상계좌 결제 (제휴서비스 및 부가 서 비스 제공)
                    </span>
                  </p>
                </td>
              </tr>
              <tr className="c20">
                <td className="c25" colSpan={1} rowSpan={1}>
                  <p className="c11">
                    <span className="c6">금융결제원</span>
                  </p>
                </td>
                <td className="c29" colSpan={1} rowSpan={1}>
                  <p className="c13">
                    <span className="c6">계좌간편결제</span>
                  </p>
                </td>
              </tr>
              <tr className="c48">
                <td className="c25" colSpan={1} rowSpan={1}>
                  <p className="c11">
                    <span className="c6">국세청/㈜케이에스넷</span>
                  </p>
                </td>
                <td className="c29" colSpan={1} rowSpan={1}>
                  <p className="c13">
                    <span className="c6">현금영수증 발행</span>
                  </p>
                </td>
              </tr>
              <tr className="c48">
                <td className="c25" colSpan={1} rowSpan={1}>
                  <p className="c11">
                    <span className="c6">쿠콘㈜</span>
                  </p>
                </td>
                <td className="c29" colSpan={1} rowSpan={1}>
                  <p className="c62">
                    <span className="c6">계좌 인증</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p className="c50">
            <span className="c0" />
          </p>
          <ol className="c12 lst-kix_list_5-0" start={2}>
            <li className="c9 li-bullet-6">
              <span className="c0">
                회사는 고객님의 개인정보를 본 동의서에서 고지한 범위 내에서
                수집•이용하며, 사전동의 없이 동범위를 초과하여 수집•이용하거나
                개인정보를 제3자에게 제공하지 않습니다. 다만, 아래의 경우에는
                개인정보를 제공할 수 있습니다.
              </span>
            </li>
          </ol>
          <p className="c39">
            <span className="c0">
              가. 사전에 고객님의 공개 동의를 구한 경우
            </span>
          </p>
          <p className="c39">
            <span className="c0">
              나. 통계작성, 학술연구 또는 시장조사 등을 위하여 필요한 경우로서
              특정개인을 식별할 수 없는 형태로 제공하는 경우
            </span>
          </p>
          <p className="c59">
            <span className="c0">
              다. 관계법령의 규정에 의하거나, 수사목적으로 법령에 정해진 절차와
              방법에 따른 수사기관의 요구가 있거나, 법원이 재판과 관련하여
              정보의 제공을 요청하는 경우 라. 회사와 관련하여 합병, 인수 또는
              포괄적인 영업양도가 있는 경우 해당 합병 후 회사, 인수인 및 영업
              양수인에 대하여 정보를 제공하는 경우
            </span>
          </p>
          <p className="c39">
            <span className="c0">
              마. 도용방지를 위하여 본인확인에 필요한 경우
            </span>
          </p>
          <ol className="c12 lst-kix_list_5-0" start={3}>
            <li className="c21 li-bullet-1">
              <span className="c0">
                개인정보의 위탁: 회사는“이용자”의 동의 없이 고객님의정보를
                외부업체에 위탁하지 않습니다. 향후 그러한 필요가 생길 경우,
                위탁대상자와 위탁업무 내용에 대해 “이용자”에게 사전통지하고
                필요한 경우 사전 동의를 받습니다.
              </span>
            </li>
            <li className="c21 c75 li-bullet-1">
              <span className="c0" />
            </li>
          </ol>
          <p className="c2">
            <span className="c5">개인/가맹점(신용)정보조회 필수적동의서 </span>
          </p>
          <p className="c2">
            <span className="c38 c40">개인(신용)정보 조회 필수적 동의서</span>
          </p>
          <p className="c72 c75">
            <span className="c0" />
          </p>
          <p className="c72">
            <span className="c0">
              전자상거래 및 금융거래와 관련하여 귀사가 본인의 개인(신용)정보를
              수집, 이용하고자 하는 경우 [개인정보보호법] 제15조 및 제 22조,
              제24조, [신용정보의 이용 및 보호에 관한 법률] 제32조, 제33조 및
              제34조에 따라 동의를 얻어야 합니다. 이에 본인은 귀사가 아래의
              내용과 같이 본인의 개인[신용]정보를 수집, 이용하는데 동의 합니다.
            </span>
          </p>
          <p className="c61">
            <span className="c0" />
          </p>
          <ul className="c12 lst-kix_list_8-1 start">
            <li className="c26 li-bullet-7">
              <span className="c0">
                개인(신용)정보의 수집, 이용 목적 : 본인인증내역을 통한
                본인식별확인, 계약의 체결, 유지, 이행, 관리, 개선 및 관련 상담
                등 신용 조회 업무
              </span>
            </li>
            <li className="c26 li-bullet-7">
              <span className="c0">수집 이용 할 개인(신용)정보 내용</span>
            </li>
          </ul>
          <p className="c36">
            <span className="c0">
              ①개인식별정보 : 성명, 생년월일, 성별, 내외국인, 휴대폰번호,
              출생등록지
            </span>
          </p>
          <p className="c36">
            <span className="c0">
              ②신용거래정보 : 귀사 및 타 금융사의 본거래 이전 및 이후의 대출,
              보증, 담보제공, 신용카드 할부금융 등 상거래 관련 거래의 종류,
              기간, 금액 이용한도 등 거래내용을 판단할 수 있는 정보
            </span>
          </p>
          <p className="c36">
            <span className="c0">
              ③신용도정보 : 신용등급, 신용조회기록, 채무재조정약정, 연체, 부도,
              대위변제, 기타 신용질서문란행위 관련 금액, 발생시기등 신용도
              판단할 수 있는 정보
            </span>
          </p>
          <p className="c36">
            <span className="c0">
              ④신용능력정보 : 재산,재무,소득의 총액, 납세실적 등 신용거래능력을
              판단 할 수 있는 정보
            </span>
          </p>
          <p className="c36">
            <span className="c0">
              ⑤공공정보 : 개인회생 및 파산, 면책, 채무불이행자 등재 등 법원의
              재판 결정정보, 체납정보, 주민등록 관련정보, 사회보험,
              공공요금정보, 행정처분에 관한 정보 등 공공기관 보유 정보
            </span>
          </p>
          <p className="c36">
            <span className="c0">
              ⑥기타 계약 및 서비스의 체결,유지,이행,관리,개선 등관 관련한본인이
              제공한정보
            </span>
          </p>
          <ul className="c12 lst-kix_list_8-1">
            <li className="c26 li-bullet-8">
              <span className="c0">개인(신용)정보의 보유 및 이용기간 : </span>
              <span className="c63 c30">
                거래종료, 회원탈회 또는 채권, 채무 분쟁 종료일로부터 5년
              </span>
            </li>
          </ul>
          <h3 className="c82">
            <span className="c38">개인(신용)정보 조회에 관한 사항</span>
          </h3>
          <p className="c74">
            <span className="c0">
              [신용정보의 이용 및 보호에 관한 법률] 제32조 제2항 및
              [개인정보보호법] 제24조에 따라 귀사가 아래와 같은 내용으로
              신용조회회사, 신용정보집중기관으로부터 본인의 신용정보를
              조회하거나 공공기관을 통해 본인임을 확인 하는 것에 대하여
              동의합니다.
            </span>
          </p>
          <p className="c22">
            <span className="c0" />
          </p>
          <ul className="c12 lst-kix_list_1-1 start">
            <li className="c26 li-bullet-9">
              <span className="c0">
                조회 할 개인(신용)정보 : 개인식별정보, 신용거래정보,
                신용도판단정보, 신용능력정보, 공공정보
              </span>
            </li>
            <li className="c26 li-bullet-7">
              <span className="c0">
                개인(신용)정보의 조회 목적 : 계약의 체결,유지,이행,관리,개선
              </span>
            </li>
            <li className="c26 li-bullet-9">
              <span className="c0">
                개인(신용)정보의 효력기간 : 귀하의 본 동의서를 제출한 시점부터
                당해 거래 종료일까지 본 동의의 효력이 유지됩니다.
              </span>
            </li>
            <li className="c26 li-bullet-8">
              <span className="c0">
                조회자(신용정보를 제공받는자)의 개인(신용)정보의 보유, 이용기간
                :{" "}
              </span>
              <span className="c30 c63">
                정보를 제공받는 날로부터 개인(신용)정보의 조회 목적을 달성 할
                때까지
              </span>
            </li>
          </ul>
          <p className="c75 c78">
            <span className="c44" />
          </p>
        </div>
        <button>닫기</button>
      </div>
      <div id="addrPopup" className="addrPopup">
        <div className="addrPopupCloseBox">
          <img
            id="addrPopupClose"
            onClick={() => addressClose()}
            className="addrPopupClose"
            src="/assets/images/icon_close_type_1.svg"
            alt=""
          />
        </div>
      </div>
    </>
  );
};
export default Submitb;
