// 2022-02-24 인수인계
// 기능 : 메세지를 소켓으로 받으면 읽지않은 메세지 수를 업데이트해줍니다.

import React, { useEffect } from "react";
import Echo from "laravel-echo/dist/echo";
import socketio from "socket.io-client";
import {
  getParameterByName,
  timeForToday,
  fetcherWithToken,
} from "../shared/script.js";
import { readLastMsg } from "../shared/chat.js";
import createPopup from "./createPopup.js";
import useSWR from "swr";

// const echo = new Echo({
//     host: "https://api.olapse.com:6006/",
//     broadcaster: "socket.io",
//     client: socketio,
// });

const updateUnreadMsg = () => {
  fetch(`https://api.olapse.fifteenh.io/api/teamwork/unread/message`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      const targetElement = document.querySelectorAll(".chat span")[0];
      if (targetElement && data.data.unread_message_count !== 0) {
        targetElement.style.display = "flex";
        targetElement.textContent =
          data.data.unread_message_count >= 100
            ? "99+"
            : data.data.unread_message_count;
      } else if (targetElement && data.data.unread_message_count == 0) {
        targetElement.style.display = "none";
      }
    })
    .catch((e) => {
      if (localStorage.getItem("token")) {
        // location.reload(true);
      } else {
        createPopup({
          type: "custom",
          title: "세션 만료",
          content:
            "세션이 만료되었거나 회원탈퇴되었습니다.<br />재 로그인, 또는 재 가입 후 이용가능합니다.",
          custom: () => {
            if (getParameterByName("token")) {
              window.location.href = `/login?redirectUrl=/more/data_save_2`;
            } else {
              window.location.href = "/login";
            }
          },
        });
      }
    });
};
if (localStorage.getItem("token")) {
  updateUnreadMsg();
}
let timer = 1000,
  timerInterver;
const TeamChat = () => {
  updateUnreadMsg();
  // echo.channel("laravel_database_chat").listen("MessagePushed", (e) => {
  // if (timer == 1000) {
  //     excuteUpdate();
  //     function excuteUpdate() {
  //         if (timer == 0) {
  //             setTimeout(() => {
  //                 fetch(`https://api.olapse.fifteenh.io/api/teamwork/unread/message`, {
  //                     method: "GET",
  //                     headers: {
  //                         Authorization: "Bearer " + localStorage.getItem("token"),
  //                     },
  //                 })
  //                     .then(function (response) {
  //                         return response.json();
  //                     })
  //                     .then(function (data) {
  //                         const targetElement = document.querySelectorAll(".chat span")[0];
  //                         if (targetElement && data.data.unread_message_count !== 0) {
  //                             targetElement.style.display = "flex";
  //                             targetElement.textContent = data.data.unread_message_count >= 100 ? "99+" : data.data.unread_message_count;
  //                         } else if (targetElement && data.data.unread_message_count == 0) {
  //                             targetElement.style.display = "none";
  //                         }
  //                     });
  //                 updateInfinity();
  //                 function updateInfinity() {
  //                     if (document.querySelectorAll("div.chat_list_wrapper ul.chat_list li")[0]) {
  //                         document.querySelectorAll("div.chat_list_wrapper ul.chat_list li").forEach((item, idx) => {
  //                             if (item.getAttribute("idx") == e.teamwork_id) {
  //                                 if (e.type !== "read" && e.type !== "member_invite" && e.type !== "member_add" && e.type !== "info_modify" && e.type !== "enter" && e.type !== "complete" && e.type !== "accept" && e.type !== "calculate_complete" && e.type !== "hold" && e.type !== "return" && e.type !== "cancel" && e.type !== "delete") {
  //                                     item.querySelectorAll(".chat_thumb_text span")[0].style.display = "block";
  //                                     item.querySelectorAll(".chat_thumb_text span")[0].textContent = e.message;
  //                                     let dateValueString = e.chat_date.replace(/-/g, ",").replace(/:/g, ",").replace(/ /g, ",");
  //                                     item.querySelectorAll(".chat_list_col > span")[0].textContent = timeForToday(dateValueString.split(",")[0], dateValueString.split(",")[1], dateValueString.split(",")[2], dateValueString.split(",")[3], dateValueString.split(",")[4], dateValueString.split(",")[5]);
  //                                 } else {
  //                                     // readInfinity();
  //                                     // function readInfinity() {
  //                                     //     if (document.querySelectorAll(".chat_data_inner > div:last-of-type")[0] && document.querySelectorAll(".chat_data_inner > div:last-of-type")[0].getAttribute("idx") && getParameterByName("idx")) {
  //                                     //         readLastMsg(document.querySelectorAll(".chat_data_inner > div:last-of-type")[0].getAttribute("idx"), getParameterByName("idx"));
  //                                     //     } else {
  //                                     //         setTimeout(() => {
  //                                     //             readInfinity();
  //                                     //         }, 1000);
  //                                     //     }
  //                                     // }
  //                                 }

  //                                 fetch(`https://api.olapse.fifteenh.io/api/teamwork/detail?page=1&id=${e.teamwork_id}`, {
  //                                     method: "GET",
  //                                     headers: {
  //                                         Authorization: "Bearer " + localStorage.getItem("token"),
  //                                     },
  //                                 })
  //                                     .then(function (response) {
  //                                         return response.json();
  //                                     })
  //                                     .then(function (data) {
  //                                         if (data.data.teamwork.unread_message_count == 0) {
  //                                             item.querySelectorAll(".chat_msg_count")[0].style.display = "none";
  //                                         } else {
  //                                             item.querySelectorAll(".chat_msg_count")[0].style.display = "flex";
  //                                             item.querySelectorAll(".chat_msg_count")[0].textContent = Number(data.data.teamwork.unread_message_count) > 99 ? "99+" : data.data.teamwork.unread_message_count;
  //                                         }
  //                                         updateUnreadMsg();
  //                                     });

  //                             }
  //                         });
  //                     } else {
  //                         setTimeout(() => {
  //                             updateInfinity();
  //                         }, 3000);
  //                     }
  //                 }
  //             }, 10);
  //         } else {
  //             timerInterver = setInterval(() => {
  //                 if (timer !== 0) {
  //                     timer--;
  //                 } else if (timer == 0) {
  //                     excuteUpdate();
  //                     clearInterval(timerInterver);
  //                     timer = 1000;
  //                 }
  //             }, 1);
  //         }
  //     }
  // }
  // });
};

export default TeamChat;
