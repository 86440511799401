// 2022-02-24 인수인계
// 기능 : 멤버페이지입니다(로그인이후 노출)

import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import {getParameterByName, toggleClass, IsLogin, inputChanger} from "../shared/script.js";
import createPopup from "../components/createPopup";
import {_animationAppearTopDown, _animationAppearLeft, _animationDisappearRight, _animationAppearRight, _animationDisappearLeft, _animationDisappearBottomUp, _animationDisappearBottomUpV2, _animationDisappearBottomUpV3, _popupAnimationAppearTopDown, _popupAnimationDisappearBottomUp, _agreePopupActive, _agreePopupOut, _controllerActive, _controllerDisappear, _animateRightModule} from "../components/animation.js";
import MainMenu from "../components/main_menu";
import MemberList from "../components/memberList";
import Basic from "../components/basic";
import {bridge, colorBridgeGray} from "../components/bridge";

const Main = () => {
    IsLogin();
    useEffect(() => {
        bridge();
        colorBridgeGray();
        inputChanger();
    }, []);

    return (
        <>
            <div className="wrap">
                <MainMenu />
                <MemberList />
                <Basic />
            </div>
        </>
    );
};

export default Main;
