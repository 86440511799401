// 2022-02-24 인수인계
// 기능 : 받은일정 / 보낸일정 페이지

import ChatHeader from "./chat_header";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import {
  getParameterByName,
  getToday,
  filteringBtns,
  numberWithCommas,
  noData,
} from "../shared/script.js";
import createPopup from "../components/createPopup";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
import DatePicker from "./datePicker";
import TeamworkModule from "./teamworkModules";

const CalList = (props) => {
  const navigate = useNavigate();
  const [isOpen, setMenu, setSatatus, isReq] = useState(false);

  const toggleMenu = () => {
    setMenu((isOpen) => !isOpen);
  };

  useEffect(() => {
    if (getParameterByName("isReq") == "1") {
      document.querySelectorAll(
        "div.data_wrapper > div.data_detail_select > div.select_right > a"
      )[0].style.display = "none";
    } else {
      document.querySelectorAll(
        "div.data_wrapper > div.data_detail_select > div.select_right > a"
      )[0].style.display = "block";
    }

    // filteringBtns("div.payment_select_popup_wrapper_1 div.payment_select_popup_box ul li", "active");
    filteringBtns(
      "div.payment_select_popup_wrapper_2 div.payment_select_popup_box ul li",
      "active"
    );
    //일정 완료처리
    let paymentType1Chk = true;
    document
      .querySelectorAll(
        ".payment_select_popup_wrapper_1 .payment_select_popup_box_button_wrapper button:nth-of-type(2)"
      )[0]
      .addEventListener("click", function () {
        if (paymentType1Chk) {
          paymentType1Chk = false;
          if (
            document.querySelectorAll(
              "div.payment_select_popup_wrapper_1 div.payment_select_popup_box ul li.active span"
            )[0]
          ) {
            let formdata = new FormData();
            formdata.append("schedule_id", this.getAttribute("schedule_index"));
            document
              .querySelectorAll(
                "div.payment_select_popup_wrapper_1 div.payment_select_popup_box ul li.active span"
              )
              .forEach((item, idx) => {
                formdata.append("payment_type[]", item.textContent);
              });
            fetch(
              `https://api.olapse.fifteenh.io/api/teamwork/schedule/complete`,
              {
                method: "POST",
                body: formdata,
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
              .then(function (response) {
                return response.json();
              })
              .then(function (data) {
                paymentType1Chk = true;
                if (data.success == true) {
                  createPopup({
                    type: "custom",
                    title: "일정완료",
                    content: "일정을 완료하였습니다.",
                    custom: () => {
                      // location.reload(true);
                      var pel = document.querySelectorAll(
                        ".payment_select_popup_wrapper_1"
                      )[0];
                      _animationDisappearBottomUp(
                        pel.querySelectorAll(".payment_select_popup_box")[0]
                      );
                      _animateRightModule(
                        false,
                        document.querySelectorAll(".d_4")[0]
                      );
                      setTimeout(() => {
                        pel.style.display = "none";
                      }, 400);
                    },
                  });
                } else {
                  createPopup({
                    type: "reTry",
                    title: "일정완료 실패",
                    content: data.alert,
                  });
                }
              });
          } else {
            paymentType1Chk = true;
            createPopup({
              type: "reTry",
              title: "일정 완료 불가",
              content: "일정을 하나 이상 선택해주세요.",
            });
          }
        }
      });

    //일정 완료처리
    let paymentType2Chk = true;
    document
      .querySelectorAll(
        ".payment_select_popup_wrapper_2 .payment_select_popup_box_button_wrapper button:nth-of-type(2)"
      )[0]
      .addEventListener("click", function () {
        if (paymentType2Chk) {
          paymentType2Chk = false;
          if (
            document.querySelectorAll(
              "div.payment_select_popup_wrapper_2 div.payment_select_popup_box ul li.active span"
            )[0]
          ) {
            let formdata = new FormData();
            formdata.append("schedule_id", this.getAttribute("schedule_index"));
            document
              .querySelectorAll(
                "div.payment_select_popup_wrapper_2 div.payment_select_popup_box ul li.active span"
              )
              .forEach((item, idx) => {
                formdata.append("payment_type[]", item.textContent);
              });
            fetch(
              `https://api.olapse.fifteenh.io/api/teamwork/schedule/type/modify`,
              {
                method: "POST",
                body: formdata,
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
              .then(function (response) {
                return response.json();
              })
              .then(function (data) {
                paymentType2Chk = true;
                if (data.success == true) {
                  createPopup({
                    type: "custom",
                    title: "변경완료",
                    content: "결제타입을 변경하였습니다.",
                    custom: () => {
                      location.reload(true);
                    },
                  });
                } else {
                  createPopup({
                    type: "reTry",
                    title: "타입변경 실패",
                    content: data.alert,
                  });
                }
              });
          } else {
            paymentType2Chk = true;
            createPopup({
              type: "reTry",
              title: "타입 변경 불가",
              content: "타입을 하나 이상 선택해주세요.",
            });
          }
        }
      });
  });

  return (
    <>
      <div className="pc_right_area">
        <ChatHeader title="일정내역" isSearch={1} />
        <div className="data_wrapper">
          <div className="data_select_row">
            <div className="send_data">
              {getParameterByName("isReq") == 0 ? (
                <select className="cal_select">
                  <option value={"req"} selected={true}>
                    보낸 일정
                  </option>
                  <option value={"req_2"}>받은 일정</option>
                </select>
              ) : (
                <select className="cal_select">
                  <option value={"req"}>보낸 일정</option>
                  <option value={"req_2"} selected={true}>
                    받은 일정
                  </option>
                </select>
              )}
            </div>
            <div onClick={() => toggleMenu()} className="data_info">
              <img src="/assets/images/icon_date.svg" alt="" />
              <span></span>
            </div>
          </div>
          <div className="data_price_row">
            <h1>
              <span></span>
              <span></span>
            </h1>
          </div>
          <div className="data_detail_select">
            <div className="select_sort">
              <select>
                <option value="newest">최근순</option>
                <option value="price_high">견적 높은 순</option>
              </select>
            </div>
            <div className="select_right">
              <a onClick={() => navigate("/more/confirm")}>정산확인</a>
              <img
                className="excel_download"
                src="/assets/images/icon_download.svg"
                alt=""
              />
              <img
                className="status_filter_button"
                src="/assets/images/icon_setting_2.svg"
                alt=""
              />
            </div>
          </div>
          <ul className="data_list iosPadding" />
          <div className="no_data">
            <span>일정이 없습니다.</span>
          </div>
        </div>
        <div className="chat_loading chat_loadingSub">
          <div className="load_item" />
          <span>일정내역을 불러오고 있습니다.</span>
        </div>
      </div>
      <DatePicker style={isOpen == true ? "display:flex" : "display:none"} />
      <TeamworkModule />
    </>
  );
};
export default CalList;
