// 2022-02-24 인수인계
// 기능 : 계정 설정 페이지입니다.

import {Navigate, useNavigate, useParams} from "react-router-dom";
import ChatHeader from "./chat_header";
const Setting = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="pc_right_area">
                <ChatHeader title="계정설정" />
                <div className="setting_gray_wrapper">
                    <div className="setting_contents_wrapper">
                        <ul>
                            <li>
                                <a onClick={() => navigate("/change_phone")}>휴대폰번호 수정</a>
                            </li>
                            <li>
                                <a onClick={() => navigate("/change_id")}>아이디 수정</a>
                            </li>
                            <li>
                                <a onClick={() => navigate("/change_password")}>비밀번호 수정</a>
                            </li>
                            <li>
                                <a onClick={() => navigate("/exit")}>회원탈퇴</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Setting;
