// 2022-02-24 인수인계
// 기능 : 데이터 저장소 첫번째 페이지입니다.

import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { numberWithCommas } from "../shared/script";
import ChatHeader from "./chat_header";
import createPopup from "./createPopup";
const DataSave1 = () => {
  const navigate = useNavigate();
  useEffect(() => {
    fetch(`https://api.olapse.fifteenh.io/api/data/store`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (
          data.data.user_data_store &&
          data.data.user_data_store.status == 0
        ) {
          createPopup({
            type: "custom",
            title: "데이터 저장소",
            content: "이미 데이터저장소를 구독하고 있습니다.",
            custom: () => {
              navigate("/more/data_save_3");
            },
          });
        }
      });

    fetch(`https://api.olapse.fifteenh.io/api/data/store/price`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        document.querySelectorAll(
          "div.data_save_area_1 div.text_box_big"
        )[0].innerHTML = `<h1>${numberWithCommas(
          data.data.price
        )}원<span> / 월</span></h1>`;
      });
  }, []);
  return (
    <>
      <div className="pc_right_area">
        <ChatHeader isCompleteBack={true} title="데이터 저장소란?" />
        <div className="data_save_area_1">
          <h1>
            데이터관리와 백업기능을 하나로!
            <span>팀워크를 더 편하게 해주는 데이터 저장소</span>
          </h1>
          <ul>
            <li>
              <img src="/assets/images/icon_check_type_2.svg" alt="" />
              <span>보낸 일정/받은 일정을 엑셀로 저장하세요!</span>
            </li>
            <li>
              <img src="/assets/images/icon_check_type_2.svg" alt="" />
              <span>대화와 일정이 영구적으로 저장돼요!</span>
            </li>
            <li>
              <img src="/assets/images/icon_check_type_2.svg" alt="" />
              <span>모든 팀워크의 사진/파일/일정을 한눈에 볼 수 있어요!</span>
            </li>
          </ul>
          <div className="text_box_big" />
          <button
            onClick={() => navigate("/more/data_save_2")}
            className="submit_button"
          >
            구독하기
          </button>
        </div>
      </div>
    </>
  );
};
export default DataSave1;
