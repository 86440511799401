// 2022-02-24 인수인계
// 기능 : 휴대폰인증 스탭1페이지입니다.

import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import UserHeader from "../components/userHeader";
import {getParameterByName, toggleClass, inputChanger} from "../shared/script.js";
import createPopup from "../components/createPopup";
import {_animationAppearTopDown, _animationAppearLeft, _animationDisappearRight, _animationAppearRight, _animationDisappearLeft, _animationDisappearBottomUp, _animationDisappearBottomUpV2, _animationDisappearBottomUpV3, _popupAnimationAppearTopDown, _popupAnimationDisappearBottomUp, _agreePopupActive, _agreePopupOut, _controllerActive, _controllerDisappear, _animateRightModule} from "../components/animation.js";

const Phone = () => {
    const navigate = useNavigate();

    function next() {
        if (String(document.querySelector("#name").value).length > 1) {
            localStorage.setItem("name", document.querySelector("#name").value);
            navigate("/phone_2");
        } else {
            createPopup({
                type: "reTry",
                title: "이름확인",
                content: "이름을 두 글자 이상 입력해주세요.",
            });
        }
    }
    useEffect(() => {
        inputChanger();
    }, []);
    return (
        <>
            <div id="wrap">
                <UserHeader isCenter="true" />
                <div className="user_wrapper">
                    <h1 className="page_title">휴대폰 인증</h1>
                    <div className="input_wrapper">
                        <div className="input_row">
                            <h1>이름</h1>
                            <div className="input_outter">
                                <input id="name" placeholder="이름" type="text" />
                            </div>
                        </div>
                    </div>
                    <button className="button_type_1" onClick={() => next()}>
                        다음
                    </button>
                </div>
            </div>
        </>
    );
};

export default Phone;
