// 2022-02-24 인수인계
// 기능 : 내 프로필 조회 기능입니다.

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import UserHeader from "../components/userHeader";
import {
  getParameterByName,
  toggleClass,
  noData,
  phoneCall,
  closeAllPopup,
} from "../shared/script.js";
import createPopup from "../components/createPopup";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
import Anime, { anime } from "react-anime";

//내 정보
let reportUserId;
let uploadedfileArr = [];
function getMyInfo() {
  fetch("https://api.olapse.fifteenh.io/api/mypage", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      if (data.success == true) {
        let msgString;
        if (data.data.message) {
          if (String(data.data.message).length > 18) {
            msgString =
              String(data.data.message).substr(0, 18) +
              "…" +
              `<span origintext='${data.data.message}' class='new_view_all_msg_button_0'>더보기</span>`;
          } else {
            msgString = data.data.message;
          }
        } else {
          msgString = "";
        }
        document.querySelectorAll(".view_profile_module")[0].style.display =
          "flex";
        _animationAppearTopDown(
          document.querySelectorAll(".view_my_profile")[0]
        );
        document.querySelectorAll(
          "div.view_profile_module div.view_my_profile div.view_profile_inner p"
        )[0].style.background = `url(${
          data.data.profile_url
            ? data.data.profile_url
            : "/assets/images/icon_profile_basic.svg"
        })`;
        document.querySelectorAll(
          "div.view_profile_module div.view_my_profile div.view_profile_inner h1"
        )[0].textContent = data.data.name;
        document.querySelectorAll(
          "div.view_profile_module div.view_my_profile div.view_profile_inner span"
        )[0].innerHTML = msgString;
        if (
          document.querySelectorAll(
            ".view_my_profile .new_view_all_msg_button_0"
          )[0]
        ) {
          document
            .querySelectorAll(".view_my_profile .new_view_all_msg_button_0")[0]
            .addEventListener("click", function () {
              this.parentNode.textContent = this.getAttribute("origintext");
              this.style.display = "none";
            });
          document
            .querySelectorAll(".view_my_profile .new_view_all_msg_button_0")[0]
            .classList.remove("new_view_all_msg_button_0");
        }
        document
          .querySelectorAll(".view_profile_module b")[0]
          .addEventListener("click", function () {
            document.querySelectorAll(
              ".view_profile_inner h5"
            )[0].style.display = "flex";
            if (data.data.user_business == null) {
              document.querySelectorAll(
                ".view_profile_inner h5"
              )[0].textContent = "사업자 인증이 완료되지 않은 멤버입니다.";
            } else if (data.data.user_business.status == 1) {
              document.querySelectorAll(
                ".view_profile_inner h5"
              )[0].textContent = "사업자 인증이 완료된 멤버입니다.";
            } else {
              document.querySelectorAll(
                ".view_profile_inner h5"
              )[0].textContent = "사업자 인증이 완료되지 않은 멤버입니다.";
            }
            setTimeout(() => {
              document.querySelectorAll(
                ".view_profile_inner h5"
              )[0].style.display = "none";
            }, 1500);
          });
      }
    });
}

//유저 신고/삭제
function userReport() {
  const trigger = document.querySelectorAll(
      "div.view_my_profile.view_member_profile img.view_my_profile_more_button"
    )[0],
    reportTrigger = document.querySelectorAll(
      "div.view_my_profile.view_member_profile ul li"
    )[0],
    removeTrigger = document.querySelectorAll(
      "div.view_my_profile.view_member_profile ul li"
    )[1];
  if (trigger) {
    trigger.addEventListener("click", function () {
      if (this.classList.contains("active")) {
        this.classList.remove("active");
        document.querySelectorAll(".report_sub_popup")[0].style.display =
          "none";
      } else {
        this.classList.add("active");
        _animationAppearTopDown(
          document.querySelectorAll(".report_sub_popup")[0]
        );
      }
    });

    fileUpload(
      document.querySelectorAll(
        "div.report_popup_wrapper div.file_add_area ul.b_submit_file_list label input"
      )[0],
      document.querySelectorAll(".b_submit_file_list")[0]
    );
  }
}
//파입 업로드 기능
function fileUpload(trigger, appendTarget) {
  trigger.addEventListener("input", function (e) {
    if (document.querySelectorAll(".added_file")[0]) {
      document.querySelectorAll(".added_file").forEach((item, idx) => {
        item.remove();
      });
    }

    for (let i = 0; i < e.target.files.length; i++) {
      uploadedfileArr.push(e.target.files[i]);
    }
    uploadedfileArr.forEach(function (item, idx) {
      const HTMLString = `<li class="added_file"> <p class="new_file_del_button file_del_button"></p> <div class="file_icon"></div> <span class="file_name">${
        item.name.split("/")[item.name.split("/").length - 1]
      }</span> <span class="file_size">${item.size}kb</span> </li>`;
      setTimeout(function () {
        appendTarget.insertAdjacentHTML("afterbegin", HTMLString);

        document
          .querySelectorAll(".new_file_del_button")[0]
          .addEventListener("click", function () {
            uploadedfileArr.splice(
              Array.from(
                document.querySelectorAll(".new_file_del_button")
              ).indexOf(this),
              1
            );
            this.parentNode.remove();
          });
        document
          .querySelectorAll(".new_file_del_button")[0]
          .classList.remove("new_file_del_button");
      }, 100);
    });
  });
}
function reportInit() {
  const trigger = document.querySelectorAll(".report_sub_popup li")[0],
    secondTrigger = document.querySelectorAll(".report_sub_popup li")[1];
  if (trigger) {
    trigger.addEventListener("click", function () {
      document.querySelectorAll(".view_profile_module")[0].style.display =
        "none";
      document.querySelectorAll(".view_my_profile")[0].style.display = "none";
      document.querySelectorAll(".view_member_profile")[0].style.display =
        "none";
      document.querySelectorAll("div.report_popup_wrapper")[0].style.display =
        "none";
      setTimeout(() => {
        document.querySelectorAll(".view_profile_module")[0].style.display =
          "flex";
        _animationAppearTopDown(
          document.querySelectorAll(".report_popup_wrapper")[0]
        );
      }, 300);
    });
    secondTrigger.addEventListener("click", function () {
      document
        .querySelectorAll(
          "div.view_my_profile.view_member_profile img.view_my_profile_more_button"
        )[0]
        .classList.remove("active");
      document.querySelectorAll(".report_sub_popup")[0].style.display = "none";
      createPopup({
        type: "custom_select_2",
        title: "멤버 삭제",
        content: `멤버를 멤버 목록에서 삭제하시겠습니까?`,
        firstButtonClickFunction: () => {
          removeMemberExcute(
            document
              .querySelectorAll(".report_sub_popup")[0]
              .getAttribute("data-user_id")
          );
        },
        secondButtonClickFunction: () => {},
        button_text_1: "취소",
        button_text_2: "삭제",
      });
    });

    document
      .querySelectorAll(
        ".report_popup_wrapper_button_list li:nth-of-type(2)"
      )[0]
      .addEventListener("click", function () {
        reportExcute(
          document
            .querySelectorAll(".report_sub_popup")[0]
            .getAttribute("data-user_id"),
          document
            .querySelectorAll(".report_type_list input:checked")[0]
            .getAttribute("reason"),
          uploadedfileArr
        );
      });
  }
}

//멤버 신고 실행
function reportExcute(userId, reason, fileArr) {
  let formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("reason", reason);
  if (fileArr.length !== 0) {
    fileArr.forEach((item, idx) => {
      formdata.append("images[]", item, item.name);
    });
  }

  fetch("https://api.olapse.fifteenh.io/api/member/report", {
    method: "POST",
    body: formdata,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      if (data.success == true) {
        document.querySelectorAll("div.member_add_module")[0].style.display =
          "none";
        document.querySelectorAll(".view_profile_module")[0].style.display =
          "none";
        document.querySelectorAll(".view_my_profile")[0].style.display = "none";
        document.querySelectorAll(".view_member_profile")[0].style.display =
          "none";
        document.querySelectorAll("div.report_popup_wrapper")[0].style.display =
          "none";
        setTimeout(() => {
          createPopup({
            type: "confirm",
            title: "신고 완료",
            content: "신고가 완료되었습니다.",
          });
        }, 300);
      } else {
        createPopup({
          type: "confirm",
          title: "신고 실패",
          content: data.alert,
        });
      }
    });
}

//멤버 삭제 실행
function removeMemberExcute(userId) {
  fetch(`https://api.olapse.fifteenh.io/api/member/delete?user_id=${userId}`, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      if (data.success == true) {
        document.querySelectorAll("div.member_add_module")[0].style.display =
          "none";
        document.querySelectorAll(".view_profile_module")[0].style.display =
          "none";
        document.querySelectorAll(".view_my_profile")[0].style.display = "none";
        document.querySelectorAll(".view_member_profile")[0].style.display =
          "none";
        document.querySelectorAll("div.report_popup_wrapper")[0].style.display =
          "none";
        setTimeout(() => {
          createPopup({
            type: "custom",
            title: "삭제 완료",
            content: "멤버삭제가 완료되었습니다.",
            custom: function () {
              window.location.reload(true);
            },
          });
        }, 300);
      }
    });
}

//시작
function initMyInfo() {
  if (document.querySelectorAll(".user_profile")[0]) {
    document
      .querySelectorAll(".user_profile")[0]
      .addEventListener("click", function () {
        getMyInfo();
      });
  }
  userReport();
  reportInit();
}

export default initMyInfo;
