// 2022-02-24 인수인계
// 기능 : 고객센터 문의 작성페이지입니다.

import react, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ChatHeader from "./chat_header";
import createPopup from "../components/createPopup.js";
import { useState } from "react/cjs/react.development";
const CustomerCenterWrite = () => {
  const navigate = useNavigate();
  let uploadedfileArr = [];
  useEffect(() => {
    //파입 업로드 기능

    function sendFaq() {
      let formdata = new FormData();
      formdata.append(
        "type",
        document.querySelectorAll("#faq_type_select option:checked")[0].text
      );
      formdata.append(
        "contents",
        document.querySelectorAll("div.input_wrapper textarea")[0].value
      );
      if (uploadedfileArr.length !== 0) {
        uploadedfileArr.forEach((item, idx) => {
          formdata.append("images[]", item, item.name);
        });
      }

      fetch("https://api.olapse.fifteenh.io/api/qna/register", {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            createPopup({
              type: "custom",
              title: "문의완료",
              content: "문의가 작성되었습니다.",
              custom: function () {
                navigate("/more/customer_center/0");
              },
            });
          } else {
            createPopup({
              type: "reTry",
              title: "작성 실패",
              content: data.alert,
            });
          }
        });
    }
    document
      .querySelectorAll(".faq_submit_button")[0]
      .addEventListener("click", function () {
        sendFaq();
      });
  }, []);

  const fileChangedHandler = (e) => {
    if (document.querySelectorAll(".added_file")[0]) {
      document.querySelectorAll(".added_file").forEach((item, idx) => {
        item.remove();
      });
    }
    for (let i = 0; i < e.target.files.length; i++) {
      uploadedfileArr.push(e.target.files[i]);
    }
    uploadedfileArr.forEach(function (item, idx) {
      const HTMLString = `<li class="added_file"> <p class="new_file_del_button file_del_button"></p> <div class="file_icon"></div> <span class="file_name">${
        item.name.split("/")[item.name.split("/").length - 1]
      }</span> <span class="file_size">${item.size}kb</span> </li>`;
      document
        .querySelectorAll(".b_submit_file_list_board")[0]
        .insertAdjacentHTML("afterbegin", HTMLString);

      document
        .querySelectorAll(".new_file_del_button")[0]
        .addEventListener("click", function () {
          uploadedfileArr.splice(
            Array.from(
              document.querySelectorAll(".new_file_del_button")
            ).indexOf(this),
            1
          ),
            this.parentNode.remove();
        });
      document
        .querySelectorAll(".new_file_del_button")[0]
        .classList.remove("new_file_del_button");
    });
  };

  return (
    <>
      <div className="pc_right_area">
        <ChatHeader title="고객센터" />
        <ul className="button_type_sq_select">
          <li className="active">
            <a onClick={() => navigate("/more/customer_center/1")}>1:1문의</a>
          </li>
          <li>
            <a onClick={() => navigate("/more/customer_center/0")}>문의내역</a>
          </li>
        </ul>
        <div className="input_wrapper">
          <h1>문의 유형</h1>
          <select id="faq_type_select">
            <option>이용문의</option>
            <option>정산/수수료 문의</option>
            <option>계정 및 로그인</option>
            <option>데이터 저장소 문의</option>
            <option>제휴 및 제안</option>
            <option>기타</option>
          </select>
        </div>
        <div className="input_wrapper">
          <h1>문의 내용</h1>
          {/* <textarea placeholder="문의 내용" defaultValue={"문의 내용"} /> */}
          <textarea placeholder="문의 내용" />
        </div>
        <span className="file_sub_text">사진/파일 첨부</span>
        <ul className="b_submit_file_list b_submit_file_list_board">
          <li className="file_add_button">
            <label>
              <input onChange={(e) => fileChangedHandler(e)} type="file" />
            </label>
          </li>
        </ul>
        <button className="faq_submit_button">문의하기</button>
      </div>
    </>
  );
};
export default CustomerCenterWrite;
