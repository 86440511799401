// 2022-02-24 인수인계
// 기능 : 멤버 목록 페이지입니다.

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import Echo from "laravel-echo/dist/echo";
import socketio from "socket.io-client";
import UserHeader from "../components/userHeader";
import {
  getParameterByName,
  toggleClass,
  noData,
  phoneCall,
  closeAllPopup,
  filteringBtns,
  fetcherWithToken,
} from "../shared/script.js";
import createPopup from "../components/createPopup";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
import Anime, { anime } from "react-anime";
import initSearchModule from "../shared/search";
import AddMember from "./addMember";
import SearchModuleDom from "../components/search_module";
import useSWR from "swr";

const MemberList = () => {
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`https://api.olapse.fifteenh.io/api/member`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        document
          .querySelectorAll(".profile_row_my")[0]
          .setAttribute("idx", data.data.my_info.id);
        let myHtml = `<p style="background: ${
          data.data.my_info.profile_url !== null
            ? "url(" + data.data.my_info.profile_url + ") no-repeat"
            : "url('/assets/images/icon_profile_basic.svg') no-repeat"
        }" class="user_profile_img"></p>
            <div class="text_col">
                <h1>${data.data.my_info.name}</h1>
                <span class="memberListMsg">${data.data.my_info.message}</span>
            </div>`;
        document
          .querySelectorAll(".profile_row_my")[0]
          .insertAdjacentHTML("beforeend", myHtml);

        document.querySelectorAll(".memberCount")[0].textContent =
          data.data.user_friends.length + "명";

        data.data.user_friends.forEach(function (item, idx) {
          let memberHtml = `<div key=${idx} idx=${
            item.id
          } class="profile_row user_profile_row">
                    <p style="background: ${
                      item.profile_url !== null
                        ? "url(" + item.profile_url + ") no-repeat"
                        : "url('/assets/images/icon_profile_basic.svg') no-repeat"
                    }" class="user_profile_img member_profile_img" />
                    <div class="text_col">
                        <h1>${item.name}</h1>
                        <span class="memberListMsg">${
                          item.message ? item.message : ""
                        }</span>
                    </div>
                </div>`;
          document
            .querySelectorAll(".memberListBox")[0]
            .insertAdjacentHTML("beforeend", memberHtml);
        });
      });

    const echo = new Echo({
      host: "https://api.olapse.fifteenh.io:6006/",
      broadcaster: "socket.io",
      client: socketio,
    });
    echo.channel("laravel_database_chat").listen("MessagePushed", (e) => {
      if (e.type == "user_info_modify") {
        let profileItem = document.querySelectorAll(
          ".profile_row[idx='" + e.id + "']"
        )[0];
        profileItem.querySelectorAll("h1")[0].textContent = e.name;
        profileItem.querySelectorAll(".user_profile_img")[0].style.background =
          e.profile_url == null
            ? `background: url("/assets/images/icon_profile_basic.svg") no-repeat`
            : `background: url("${e.profile_url}") no-repeat`;
        profileItem.querySelectorAll(".memberListMsg")[0].textContent =
          e.message;
      }
    });
    setTimeout(() => {
      //유저 프로필 조회(팝업)
      const getUserProfile = (trigger, userId) => {
        trigger.addEventListener("click", function (e) {
          if (
            e.target.textContent !== "더보기" &&
            e.target.classList.contains("lp_menu_wrapper_background") ==
              false &&
            !e.target.classList.contains("lp_menu_wrapper") &&
            !e.target.parentNode.parentNode.classList.contains(
              "lp_menu_wrapper"
            )
          ) {
            fetch(
              `https://api.olapse.fifteenh.io/api/member/detail?user_id=${userId}`,
              {
                method: "GET",
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
              .then(function (response) {
                return response.json();
              })
              .then(function (data) {
                let msgString;
                if (data.data.user.message) {
                  if (String(data.data.user.message).length > 18) {
                    msgString =
                      String(data.data.user.message).substr(0, 18) +
                      "…" +
                      `<span origintext='${data.data.user.message}' class='popup_shwo_all'>더보기</span>`;
                  } else {
                    msgString = data.data.user.message;
                  }
                } else {
                  msgString = "";
                }
                document.querySelectorAll(
                  ".view_profile_module"
                )[0].style.display = "flex";
                _animationAppearTopDown(
                  document.querySelectorAll(".view_member_profile")[0]
                );
                document.querySelectorAll(
                  "div.view_profile_module div.view_member_profile div.view_profile_inner p"
                )[0].style.background = `url(${
                  data.data.user.profile_url
                    ? data.data.user.profile_url
                    : "/assets/images/icon_profile_basic.svg"
                })`;
                document.querySelectorAll(
                  "div.view_profile_module div.view_member_profile div.view_profile_inner h1"
                )[0].textContent = data.data.user.name;
                document.querySelectorAll(
                  "div.view_profile_module div.view_member_profile div.view_profile_inner span"
                )[0].innerHTML = msgString;
                document.querySelectorAll(
                  "div.view_my_profile.view_member_profile ul li:nth-of-type(2) span"
                )[0].textContent = data.data.user.phone;
                document
                  .querySelectorAll(".report_sub_popup")[0]
                  .setAttribute("data-user_id", data.data.user.id);

                document
                  .querySelectorAll(".popup_shwo_all")[0]
                  ?.addEventListener("click", function () {
                    this.parentNode.innerHTML = this.getAttribute("origintext");
                  });
                document
                  .querySelectorAll(".popup_shwo_all")[0]
                  ?.classList.remove("popup_shwo_all");
                document
                  .querySelectorAll(".view_member_profile b")[0]
                  .addEventListener("click", function () {
                    document.querySelectorAll(
                      ".view_member_profile h5"
                    )[0].style.display = "flex";
                    if (data.data.user.user_business_status == 1) {
                      document.querySelectorAll(
                        ".view_member_profile h5"
                      )[0].textContent = "사업자 인증이 완료된 멤버입니다.";
                    } else {
                      document.querySelectorAll(
                        ".view_member_profile h5"
                      )[0].textContent =
                        "사업자 인증이 완료되지 않은 멤버입니다.";
                    }
                    setTimeout(() => {
                      document.querySelectorAll(
                        ".view_member_profile h5"
                      )[0].style.display = "none";
                    }, 1500);
                  });
              });
          }
        });
        document
          .querySelectorAll(
            "div.view_my_profile.view_member_profile ul.view_profile_sub_list li"
          )[0]
          .addEventListener("click", function () {
            if (
              document
                .querySelectorAll(".report_sub_popup")[0]
                .getAttribute("data-user_id") !== null
            ) {
              navigate(
                `/create?selected_id=${document
                  .querySelectorAll(".report_sub_popup")[0]
                  .getAttribute("data-user_id")}`
              );
            }
          });
        document
          .querySelectorAll(
            "div.view_my_profile.view_member_profile ul.view_profile_sub_list li"
          )[1]
          .addEventListener("click", function () {
            phoneCall(this.querySelectorAll("span")[0].textContent);
          });
      };

      //내 프로필 탭홀드
      document.querySelectorAll(".profile_row_my")[0] &&
        document
          .querySelectorAll(".profile_row_my")[0]
          .addEventListener("long-press", function (e) {
            e.preventDefault();
            this.insertAdjacentHTML(
              "beforeend",
              `<div class="lp_menu_wrapper lp_menu_wrapper_my"><div class="lp_menu_wrapper_background"></div><ul><li>나와의 채팅</li><li>내 일정 보기</li></ul></div>`
            );
            document
              .querySelectorAll(".lp_menu_wrapper_my li")[0]
              .addEventListener("click", function () {
                document.querySelectorAll(".chat_with_me")[0].click();
              });
            document
              .querySelectorAll(".lp_menu_wrapper_my li")[1]
              .addEventListener("click", function () {
                navigate("/cal");
              });
            document
              .querySelectorAll(".lp_menu_wrapper_background")[0]
              .addEventListener("click", function (e) {
                e.preventDefault();
                document.querySelectorAll(".lp_menu_wrapper")[0].remove();
              });
          });

      document.querySelectorAll(".profile_row_my")[0] &&
        document
          .querySelectorAll(".profile_row_my")[0]
          .addEventListener("mousedown", function (e) {
            if (e.button == 2 || e.which == 3) {
              e.preventDefault();
              this.insertAdjacentHTML(
                "beforeend",
                `<div class="lp_menu_wrapper lp_menu_wrapper_my"><div class="lp_menu_wrapper_background"></div><ul><li>나와의 채팅</li><li>내 일정 보기</li></ul></div>`
              );
              document
                .querySelectorAll(".lp_menu_wrapper_my li")[0]
                .addEventListener("click", function () {
                  document.querySelectorAll(".chat_with_me")[0].click();
                });
              document
                .querySelectorAll(".lp_menu_wrapper_my li")[1]
                .addEventListener("click", function () {
                  navigate("/cal");
                });
              document
                .querySelectorAll(".lp_menu_wrapper_background")[0]
                .addEventListener("click", function (e) {
                  e.preventDefault();
                  document.querySelectorAll(".lp_menu_wrapper")[0].remove();
                });
            }
          });

      //등록된 사용자 탭홀드
      document.querySelectorAll(".user_profile_row").forEach((item, idx) => {
        item.addEventListener("long-press", function (e) {
          if (!document.querySelectorAll(".lp_menu_wrapper_my")[0]) {
            e.preventDefault();
            this.insertAdjacentHTML(
              "beforeend",
              `<div class="lp_menu_wrapper lp_menu_wrapper_my"><div class="lp_menu_wrapper_background"></div><ul><li>팀워크로 이동</li><li class="redText">멤버 목록에서 삭제</li></ul></div>`
            );
            let idx = this.getAttribute("idx");
            item
              .querySelectorAll(".lp_menu_wrapper_my li")[0]
              .addEventListener("click", function () {
                navigate(`/create?selected_id=${idx}`);
              });
            item
              .querySelectorAll(".lp_menu_wrapper_my li")[1]
              .addEventListener("click", function () {
                e.preventDefault();
                item.querySelectorAll(".lp_menu_wrapper")[0].remove();
                createPopup({
                  type: "custom_select_2",
                  title: "멤버 삭제",
                  content: `멤버를 멤버 목록에서 삭제하시겠습니까?`,
                  firstButtonClickFunction: () => {
                    removeMemberExcute(idx);
                  },
                  secondButtonClickFunction: () => {},
                  button_text_1: "취소",
                  button_text_2: "삭제",
                });
              });
            item
              .querySelectorAll(".lp_menu_wrapper_background")[0]
              .addEventListener("click", function (e) {
                e.preventDefault();
                item.querySelectorAll(".lp_menu_wrapper")[0].remove();
              });
          }
        });
        getUserProfile(item, item.getAttribute("idx"));

        item.addEventListener("mousedown", function (e) {
          if (!document.querySelectorAll(".lp_menu_wrapper_my")[0]) {
            if (e.button == 2 || e.which == 3) {
              e.preventDefault();
              this.insertAdjacentHTML(
                "beforeend",
                `<div class="lp_menu_wrapper lp_menu_wrapper_my"><div class="lp_menu_wrapper_background"></div><ul><li>팀워크로 이동</li><li class="redText">멤버 목록에서 삭제</li></ul></div>`
              );
              let idx = this.getAttribute("idx");
              item
                .querySelectorAll(".lp_menu_wrapper_my li")[0]
                .addEventListener("click", function () {
                  navigate(`/create?selected_id=${idx}`);
                });
              item
                .querySelectorAll(".lp_menu_wrapper_my li")[1]
                .addEventListener("click", function () {
                  e.preventDefault();
                  item.querySelectorAll(".lp_menu_wrapper")[0].remove();
                  createPopup({
                    type: "custom_select_2",
                    title: "멤버 삭제",
                    content: `멤버를 멤버 목록에서 삭제하시겠습니까?`,
                    firstButtonClickFunction: () => {
                      removeMemberExcute(idx);
                    },
                    secondButtonClickFunction: () => {},
                    button_text_1: "취소",
                    button_text_2: "삭제",
                  });
                });
              item
                .querySelectorAll(".lp_menu_wrapper_background")[0]
                .addEventListener("click", function (e) {
                  e.preventDefault();
                  item.querySelectorAll(".lp_menu_wrapper")[0].remove();
                });
            }
          }
        });
      });

      //멤버 삭제 실행
      function removeMemberExcute(userId) {
        fetch(
          `https://api.olapse.fifteenh.io/api/member/delete?user_id=${userId}`,
          {
            method: "DELETE",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.success == true) {
              document.querySelectorAll(
                "div.member_add_module"
              )[0].style.display = "none";
              document.querySelectorAll(
                ".view_profile_module"
              )[0].style.display = "none";
              document.querySelectorAll(".view_my_profile")[0].style.display =
                "none";
              document.querySelectorAll(
                ".view_member_profile"
              )[0].style.display = "none";
              document.querySelectorAll(
                "div.report_popup_wrapper"
              )[0].style.display = "none";
              setTimeout(() => {
                createPopup({
                  type: "custom",
                  title: "삭제 완료",
                  content: "멤버삭제가 완료되었습니다.",
                  custom: function () {
                    window.location.reload(true);
                  },
                });
              }, 300);
            }
          });
      }

      //내 프로필 클릭 이벤트
      document
        .querySelectorAll(".my_profile .profile_row")[0]
        .addEventListener("click", function (e) {
          if (
            e.target.textContent !== "더보기" &&
            !e.target.classList.contains("lp_menu_wrapper_background") &&
            !e.target.classList.contains("lp_menu_wrapper") &&
            !e.target.parentNode.parentNode.classList.contains(
              "lp_menu_wrapper"
            )
          ) {
            document
              .querySelectorAll("div.left_menu_bar ul li.user_profile")[0]
              .click();
          }
        });

      document
        .querySelectorAll(
          ".report_popup_wrapper_button_list li:nth-of-type(1)"
        )[0]
        .addEventListener("click", function () {
          closeAllPopup();
        });

      filteringBtns(".search_result_wrapper ul li", "active");
      initSearchModule();
    }, 1000);
  }, []);
  return (
    <>
      <div className="chat_wrapper">
        <div className="chat_header">
          <h1>멤버</h1>
          <ul>
            <li className="btnShOpen">
              <a>
                <img src="/assets/images/icon_search.svg" alt="" />
              </a>
            </li>
            <li className="addMemberBtn">
              <a>
                <img src="/assets/images/icon_add_member.svg" alt="" />
              </a>
            </li>
          </ul>
        </div>
        <div className="member_list">
          <div className="my_profile">
            <span>내 프로필</span>
            <div
              data-long-press-delay="750"
              className="profile_row profile_row_my"
            ></div>
          </div>
          <div className="user_profile_wrapper">
            <div className="user_profile_state">
              <h1>
                <span>멤버</span>
                <span className="memberCount">0 명</span>
              </h1>
            </div>
            <div className="memberListBox"></div>
            <div className="no_data no_memberData">
              <span>등록된 멤버가 없습니다.</span>
              <a className="no_data_addMember">멤버 추가하기</a>
            </div>
          </div>
        </div>
        <SearchModuleDom />
      </div>
      <AddMember />
    </>
  );
};

export default MemberList;
