// 2022-02-24 인수인계
// 기능 : 더보기(/more) 페이지의 좌측 UI입니다.

import React, { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import {
  getParameterByName,
  toggleClass,
  IsLogin,
  fetcherWithToken,
} from "../shared/script.js";
import createPopup from "../components/createPopup";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
import useSWR from "swr";

const MoreMenu = () => {
  const { data: mypageInfo, error } = useSWR(
    [
      "https://api.olapse.fifteenh.io/api/mypage",
      localStorage.getItem("token"),
    ],
    fetcherWithToken
  );
  const navigate = useNavigate();
  const getNowTimeLine = () => {
    let now = new Date(); // 현재 날짜 및 시간
    let month = now.getMonth(); // 월
    return month + 1 + "월";
  };
  const logout = () => {
    fetch(`https://api.olapse.fifteenh.io/api/logout`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        navigate("/login");
      });
  };
  return (
    <>
      <div className="chat_wrapper">
        <div className="chat_header">
          <h1>더보기</h1>
        </div>
        <div className="more_area">
          <div
            onClick={() => navigate("/more/my_info")}
            className="more_my_info"
          >
            <p
              style={{
                background: `url(${
                  mypageInfo?.data.profile_url
                    ? mypageInfo?.data.profile_url
                    : "/assets/images/icon_profile_basic.svg"
                })`,
              }}
              className="more_my_info_profile_img"
            />
            <div className="more_my_info_col">
              <h1>{mypageInfo?.data.name}</h1>
              {mypageInfo?.data?.user_business == null && (
                <span>사업자 미인증</span>
              )}
            </div>
          </div>

          <ul className="more_cal_list">
            <li>
              <h1>{getNowTimeLine()}</h1>
            </li>
            <li
              onClick={() =>
                (window.location.href = "/more/schedule_list?isReq=0")
              }
            >
              <h1>{mypageInfo?.data.request_schedule_count}</h1>
              <span>보낸 일정</span>
            </li>
            <li
              onClick={() =>
                (window.location.href = "/more/schedule_list?isReq=1")
              }
            >
              <h1>{mypageInfo?.data.accept_schedule_count}</h1>
              <span>받은 일정</span>
            </li>
          </ul>

          <div className="data_save_wrapper">
            <h1
              onClick={() => {
                let isSubs;
                fetch(`https://api.olapse.fifteenh.io/api/data/store`, {
                  method: "GET",
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                })
                  .then(function (response) {
                    return response.json();
                  })
                  .then(function (data) {
                    if (data.data.user_data_store == null) {
                      createPopup({
                        type: "custom_select_2",
                        title: "데이터저장소 안내",
                        content: `<span>데이터 저장소 구독이 필요합니다.</span><br><span>데이터 저장소 구독은<br><p class="blue">[내정보 > 데이터저장소 > 구독]</p>에서 구독할 수 있습니다.</span>`,
                        firstButtonClickFunction: () => {
                          navigate("/more/data_save_2");
                        },
                        secondButtonClickFunction: () => {},
                        button_text_1: "취소",
                        button_text_2: "구독",
                      });
                    } else if (data.data.user_data_store.status == 1) {
                      navigate("/more/data_save_3");
                    } else {
                      navigate("/more/data_save_3");
                    }
                  });
              }}
            >
              데이터 저장소
            </h1>
            {/* {mypageInfo?.data.user_data_store?.status == 0 ? ( */}
            {mypageInfo?.data.user_data_store !== null ? (
              <div className="data_save_area">
                <ul>
                  <li onClick={() => navigate("/more/photo")}>
                    <img src="/assets/images/data_save_1.svg" alt="" />
                    <span>사진/동영상</span>
                  </li>
                  <li onClick={() => navigate("/more/file")}>
                    <img src="/assets/images/data_save_2.svg" alt="" />
                    <span>파일</span>
                  </li>
                  <li onClick={() => navigate("/more/date")}>
                    <img src="/assets/images/data_save_3.svg" alt="" />
                    <span>일정</span>
                  </li>
                </ul>
              </div>
            ) : (
              <div className="none_data_save">
                <span>데이터 저장소로 데이터관리를 시작해보세요!</span>
                <a onClick={() => navigate("/more/data_save_1")}>구독하기</a>
              </div>
            )}
          </div>

          <div className="payment_wrapper">
            <h1>결제/정산</h1>
            <ul className="payment_list">
              <li onClick={() => navigate("/more/card")}>
                <p className="payment_icon_1" />
                <span>카드결제</span>
              </li>
              <li onClick={() => navigate("/more/payment_detail")}>
                <p className="payment_icon_2" />
                <span>정산관리</span>
              </li>
            </ul>
          </div>

          <ul className="account_setting_wrapper">
            <li>
              <a
                onClick={() => {
                  createPopup({
                    type: "custom_select_2",
                    title: "계정 비밀번호 입력",
                    content:
                      "<input placeholder='계정 비밀번호 입력' id='accountPw' type='password'>",
                    button_text_1: "취소",
                    button_text_2: "확인",
                    firstButtonClickFunction: () => {
                      if (
                        document.querySelectorAll("#accountPw")[0].value == ""
                      ) {
                        setTimeout(() => {
                          createPopup({
                            type: "reTry",
                            title: "인증 실패",
                            content: "비밀번호를 입력해주세요.",
                          });
                        }, 350);
                      } else {
                        let formdata = new FormData();
                        formdata.append(
                          "password",
                          document.querySelectorAll("#accountPw")[0].value
                        );
                        fetch(
                          `https://api.olapse.fifteenh.io/api/password/check`,
                          {
                            method: "POST",
                            body: formdata,
                            headers: {
                              Authorization:
                                "Bearer " + localStorage.getItem("token"),
                            },
                          }
                        )
                          .then(function (response) {
                            return response.json();
                          })
                          .then(function (data) {
                            if (data.success == true) {
                              navigate("/more/setting");
                            } else {
                              setTimeout(() => {
                                createPopup({
                                  type: "reTry",
                                  title: "인증 실패",
                                  content: data.alert,
                                });
                              }, 350);
                            }
                          });
                      }
                    },
                    secondButtonClickFunction: () => {},
                  });
                }}
              >
                계정 설정
              </a>
            </li>
            <li>
              <a onClick={() => navigate("/more/alarm")}>알림 설정</a>
            </li>
            <li>
              <a onClick={() => navigate("/more/notice")}>공지사항</a>
            </li>
            <li>
              <a onClick={() => navigate("/more/customer_center/0")}>
                고객센터
              </a>
            </li>
          </ul>
          <button onClick={() => logout()} className="logout_button">
            로그아웃
          </button>
          <div className="sideMenuFooter">
            <h2 className="sideMenuFooterTitle">오랩스</h2>
            <p className="sideMenuFooterText">
              대표 : 이인구
              <br />
              사업자 번호 : 785-86-02476
              <br />
              개인정보 관리 책임자 : 이지만
              <br />
              제휴문의 : hello@olapse.com
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default MoreMenu;
