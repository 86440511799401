// 2022-02-24 인수인계
// 기능 : 채팅, 일정조회기능이 있는 페이지의 우측에서 나타나는 모든 팝업과 연관 기능이 포함된 컴포넌트입니다. d_n 으로 넘버링 되어있으며 기능은 각각 주석으로 표시해두었습니다.

import { useEffect } from "react";
import createPopup from "../components/createPopup";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
import Anime, { anime } from "react-anime";
import * as Calendar from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import {
  filteringBtns,
  getToday,
  lastWeek,
  phoneCall,
  getParameterByName,
  activeCh,
  noData,
  closeAllPopup,
  imgViewerFunction,
  inputChanger,
} from "../shared/script.js";
import Rolldate from "rolldate";
import "../assets/css/teamwork.css";
import {
  scheduleStay,
  scheduleReturn,
  scheduleCancel,
  scheduleRemove,
  editSchedule,
  editScheduleExcute,
  clearScheduleInfo,
  scheduleShare,
  shareExcute,
  changePaymentType,
  paymentShare,
  payment,
} from "../shared/schedule";
import { useNavigate } from "react-router-dom";

const TeamworkModule = (props) => {
  window.FullCalendar = Calendar;
  window.dayGridPlugin = dayGridPlugin;
  window.listPlugin = listPlugin;
  window.interactionPlugin = interactionPlugin;
  <script type="text/javascript" src="../assets/js/main.js"></script>;

  let requestData = {
    type: "request",
    start_date: lastWeek(),
    end_date: getToday(),
    sort: "newest",
    keyword: "",
    status: [],
  };
  let dateType = 0;
  const navigate = useNavigate();

  let uploadedfileArr = [],
    deleteFileIdArr = [];
  useEffect(() => {
    document
      .querySelectorAll(".chat_add_friend")[0]
      .addEventListener("click", function () {
        addMemberExcute(
          document
            .querySelectorAll(".d_5 .member_name")[0]
            .getAttribute("member_id")
        );
        //멤버추가 실행
        function addMemberExcute(id) {
          let formdata = new FormData();
          formdata.append("ids[]", id);
          fetch("https://api.olapse.fifteenh.io/api/member/add", {
            method: "POST",
            body: formdata,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (data) {
              if (data.success == true) {
                createPopup({
                  type: "custom",
                  title: "멤버 추가 완료",
                  content: "멤버로 추가되었습니다.",
                  custom: () => {
                    if (document.querySelectorAll(".no_memberData")[0]) {
                      document.querySelectorAll(
                        ".no_memberData"
                      )[0].style.display = "none";
                    }
                    window.location.reload(true);
                  },
                });
              } else {
                createPopup({
                  type: "confirm",
                  title: "추가 실패",
                  content: data.alert,
                });
              }
            });
        }
      });

    inputChanger();
    // let myId, myPermissionInfo;
    // fetch("https://api.olapse.fifteenh.io/api/member", {
    //     method: "GET",
    //     headers: {
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //     },
    // })
    //     .then(function (response) {
    //         return response.json();
    //     })
    //     .then(function (data) {
    //         myId = data.data.my_info.id;
    //         getParameterByName("idx") && myPermission();
    //     });
    let myId, myPermissionInfo, chatSetChk;
    if (chatSetChk !== "") {
      clearInterval(chatSetChk);
    }
    sessionStorage.setItem("teamSetChk", false);
    chatSetChk = setInterval(() => {
      if (sessionStorage.getItem("idSet") == "true") {
        myId = sessionStorage.getItem("myId");
        myPermissionInfo = sessionStorage.getItem("myPermissionInfo");
        getParameterByName("idx") && myPermission();
        clearInterval(chatSetChk);
      }
    }, 100);

    function myPermission() {
      if (myPermissionInfo == "팀원") {
        document.querySelectorAll(".notice_header_right")[0].style.display =
          "none";
      }
      sessionStorage.setItem("teamSetChk", true);
    }

    initCalendar();
    document.querySelectorAll(".start_time_data")[0].textContent = getToday();
    document.querySelectorAll(".end_time_data")[0].textContent = getToday();
    function endSet(setDate) {
      let setEndDatef = new Date(setDate);
      setEndDatef.setMinutes(setEndDatef.getMinutes() + 30);
      let endM = setEndDatef.getMonth() + 1,
        endD = setEndDatef.getDate(),
        endH = setEndDatef.getHours(),
        endMM = setEndDatef.getMinutes();

      if (endM < 10) {
        endM = "0" + endM;
      }
      if (endD < 10) {
        endD = "0" + endD;
      }
      if (endH < 10) {
        endH = "0" + endH;
      }
      if (endMM < 10) {
        endMM = "0" + endMM;
      }

      document.querySelectorAll(".end_time_data")[0].textContent =
        setEndDatef.getFullYear() + "-" + endM + "-" + endD;
      document.querySelector("#time_picker_2").value = endH + ":" + endMM;
    }
    function dateChChk() {
      var startChk = new Date(
          document
            .querySelectorAll(".start_time_data")[0]
            .textContent.replace(/-/g, "/") +
            " " +
            document.querySelector("#time_picker_1").value
        ),
        endChk = new Date(
          document
            .querySelectorAll(".end_time_data")[0]
            .textContent.replace(/-/g, "/") +
            " " +
            document.querySelector("#time_picker_2").value
        );
      if (
        document
          .querySelectorAll(".toggle_hide_end")[0]
          .classList.contains("active")
      ) {
        if (startChk > endChk) {
          alert("시작일은 종료일보다 이전으로 설정할 수 없습니다.");
          endSet(startChk);
        }
      } else {
        endSet(startChk);
      }
    }

    function initCalendar() {
      timePicker();
      const calendarEl = document.querySelectorAll(".fullcal_wrapper")[0],
        calendar = new FullCalendar.Calendar(calendarEl, {
          plugins: [dayGridPlugin, listPlugin, interactionPlugin],
          initialView: "dayGridMonth",
          dateClick: function (info) {
            document.querySelectorAll(
              "div.popup_wrapper_2 div.date_picker_box div.time_col div.col.active span"
            )[0].textContent = info.dateStr;
            if (document.querySelectorAll(".fc_actived_day")[0]) {
              document
                .querySelectorAll(".fc_actived_day")
                .forEach((item, idx) => {
                  item.classList.remove("fc_actived_day");
                });
            }
            info.dayEl.querySelectorAll("a")[0].classList.add("fc_actived_day");
            dateChChk();
          },
        });
      calendar.setOption("locale", "ko");
      calendar.render();
      document
        .querySelectorAll(".date_picker_container")
        .forEach((item, idx) => {
          item.addEventListener("click", function () {
            let setStartDate = "",
              setEndDate = "";

            document.querySelectorAll(".popup_wrapper_2")[0].style.display =
              "flex";
            if (
              document.querySelectorAll(
                "div.popup_wrapper_2 div.date_picker_box div.toggle_row div.toggle_button.active"
              )[0]
            ) {
              document
                .querySelectorAll(
                  "div.popup_wrapper_2 div.date_picker_box div.toggle_row div.toggle_button.active"
                )[0]
                .click();
            }
            if (
              document.querySelectorAll(".d_8 .date_picker_container")[0]
                .textContent !== "일시"
            ) {
              setStartDate = document
                .querySelectorAll(".d_8 .date_picker_container")[0]
                .textContent.split(" ");
              document.querySelectorAll(".start_time_data")[0].textContent =
                setStartDate[0];
              document.querySelector("#time_picker_1").value =
                setStartDate[1].split(":")[0] +
                ":" +
                setStartDate[1].split(":")[1];
            } else {
              let setStartDateSet = new Date(),
                setStartDateMin = setStartDateSet.getMinutes();
              for (var i = 0; i < 30; i++) {
                if (setStartDateMin == 0 || setStartDateMin == 30) {
                  setStartDate = setStartDateSet;
                  setEndDate = new Date(setStartDateSet);
                  setEndDate.setMinutes(setEndDate.getMinutes() + 30);

                  //시작일
                  let startM = setStartDate.getMonth() + 1,
                    satrtD = setStartDate.getDate(),
                    satrtH = setStartDate.getHours(),
                    satrtMM = setStartDate.getMinutes();

                  if (startM < 10) {
                    startM = "0" + startM;
                  }
                  if (satrtD < 10) {
                    satrtD = "0" + satrtD;
                  }
                  if (satrtH < 10) {
                    satrtH = "0" + satrtH;
                  }
                  if (satrtMM < 10) {
                    satrtMM = "0" + satrtMM;
                  }

                  document.querySelectorAll(".start_time_data")[0].textContent =
                    setStartDate.getFullYear() + "-" + startM + "-" + satrtD;
                  document.querySelector("#time_picker_1").value =
                    satrtH + ":" + satrtMM;

                  //종료일
                  let endM = setEndDate.getMonth() + 1,
                    endD = setEndDate.getDate(),
                    endH = setEndDate.getHours(),
                    endMM = setEndDate.getMinutes();

                  if (endM < 10) {
                    endM = "0" + endM;
                  }
                  if (endD < 10) {
                    endD = "0" + endD;
                  }
                  if (endH < 10) {
                    endH = "0" + endH;
                  }
                  if (endMM < 10) {
                    endMM = "0" + endMM;
                  }

                  document.querySelectorAll(".end_time_data")[0].textContent =
                    setEndDate.getFullYear() + "-" + endM + "-" + endD;
                  document.querySelector("#time_picker_2").value =
                    endH + ":" + endMM;

                  return false;
                } else {
                  setStartDateSet.setMinutes(setStartDateSet.getMinutes() + 1);
                  setStartDateMin = setStartDateSet.getMinutes();
                }
              }
            }
            if (dateType == 1) {
              setEndDate = document
                .querySelectorAll(".d_8 .date_picker_container")[1]
                .textContent.split(" ");
              document.querySelectorAll(".end_time_data")[0].textContent =
                setEndDate[0];
              document.querySelector("#time_picker_2").value =
                setEndDate[1].split(":")[0] + ":" + setEndDate[1].split(":")[1];
              document
                .querySelectorAll(".toggle_hide_end")[0]
                .classList.add("active");
              document.querySelectorAll(
                "div.popup_wrapper_2 div.date_picker_box .time_row.end"
              )[0].style.display = "flex";
            } else {
              setEndDate = new Date(
                document
                  .querySelectorAll(".d_8 .date_picker_container")[0]
                  .textContent.replace(/-/g, "/")
              );
              setEndDate.setMinutes(setEndDate.getMinutes() + 30);
              let endM = setEndDate.getMonth() + 1,
                endD = setEndDate.getDate(),
                endH = setEndDate.getHours(),
                endMM = setEndDate.getMinutes();

              if (endM < 10) {
                endM = "0" + endM;
              }
              if (endD < 10) {
                endD = "0" + endD;
              }
              if (endH < 10) {
                endH = "0" + endH;
              }
              if (endMM < 10) {
                endMM = "0" + endMM;
              }

              document.querySelectorAll(".end_time_data")[0].textContent =
                setEndDate.getFullYear() + "-" + endM + "-" + endD;
              document.querySelector("#time_picker_2").value =
                endH + ":" + endMM;
            }
            calendar.render();
          });
        });

      document
        .querySelectorAll(".hide_date_popup")[0]
        .addEventListener("click", function () {
          document.querySelectorAll(".popup_wrapper_2")[0].style.display =
            "none";
        });
      document
        .querySelectorAll(".submit_date_popup")[0]
        .addEventListener("click", function () {
          if (
            document.querySelectorAll(
              "div.popup_wrapper_2 div.date_picker_box div.toggle_row div.toggle_button.active"
            )[0]
          ) {
            document.querySelectorAll(".date_picker_container")[0].textContent =
              document.querySelectorAll(".start_time_data")[0].textContent +
              " " +
              document.querySelectorAll(
                "div.popup_wrapper_2 div.date_picker_box div.time_col div.col2 input"
              )[0].value;
            document.querySelectorAll(".date_picker_container")[1].textContent =
              document.querySelectorAll(".end_time_data")[0].textContent +
              " " +
              document.querySelectorAll(
                "div.popup_wrapper_2 div.date_picker_box div.time_col div.col2 input"
              )[1].value;
            document.querySelectorAll(
              ".date_picker_container"
            )[1].style.display = "flex";
          } else {
            document.querySelectorAll(".date_picker_container")[0].textContent =
              document.querySelectorAll(".start_time_data")[0].textContent +
              " " +
              document.querySelectorAll(
                "div.popup_wrapper_2 div.date_picker_box div.time_col div.col2 input"
              )[0].value;
            document.querySelectorAll(
              ".date_picker_container"
            )[1].style.display = "none";
          }
          document.querySelectorAll(".popup_wrapper_2")[0].style.display =
            "none";
        });
    }
    filteringBtns(".time_row .col", "active");
    filteringBtns(
      "div.popup_wrapper_2 div.date_picker_box div.time_col div.col2",
      "active"
    );

    function timePicker() {
      const option = (elementString) => {
        return {
          el: elementString,
          format: "hh:mm",
          lang: {
            title: "시간 선택",
            cancel: "취소",
            confirm: "적용",
            hour: "시",
            min: "분",
          },
          minStep: 10,
          confirm: function (date) {
            document.querySelectorAll(
              "div.popup_wrapper_2 div.date_picker_box div.time_col div.col2.active"
            )[0] &&
              document
                .querySelectorAll(
                  "div.popup_wrapper_2 div.date_picker_box div.time_col div.col2.active"
                )[0]
                .classList.remove("active");
            setTimeout(() => {
              dateChChk();
            }, 300);
          },
          cancel: function () {
            document.querySelectorAll(
              "div.popup_wrapper_2 div.date_picker_box div.time_col div.col2.active"
            )[0] &&
              document
                .querySelectorAll(
                  "div.popup_wrapper_2 div.date_picker_box div.time_col div.col2.active"
                )[0]
                .classList.remove("active");
          },
        };
      };

      const picker = new Rolldate(option("#time_picker_1"));
      const picker2 = new Rolldate(option("#time_picker_2"));
    }
    //파입 업로드 기능
    function fileUpload(trigger, appendTarget) {
      trigger.addEventListener("input", function (e) {
        let oldFiles = [];
        function fileUploadOption() {
          for (let i = 0; i < e.target.files.length; i++) {
            uploadedfileArr.push(e.target.files[i]);
          }
          function uploadEndChk(HTMLString, fileItem) {
            appendTarget.insertAdjacentHTML("afterbegin", HTMLString);
            if (fileItem.type.split("/")[0] == "image") {
              // imgViewerFunction(document.querySelectorAll(".new_added_file")[0], url2, "업로드 이미지");
              document
                .querySelectorAll(".new_added_file")[0]
                .classList.remove("new_added_file");
            }
            document
              .querySelectorAll(".new_file_del_button")[0]
              .addEventListener("click", function () {
                uploadedfileArr.splice(
                  Array.from(
                    document.querySelectorAll(".new_file_del_button")
                  ).indexOf(this),
                  1
                ),
                  this.parentNode.remove();
              });
            document
              .querySelectorAll(".new_file_del_button")[0]
              .classList.remove("new_file_del_button");
          }

          function uploadOption() {
            uploadedfileArr.forEach(function (item, idx) {
              let HTMLString;
              let url;
              let url2;
              if (item.type.split("/")[0] == "image") {
                let fr = new FileReader();
                fr.readAsDataURL(item);
                fr.onload = function () {
                  url = `url(${this.result}) no-repeat`;
                  url2 = this.result;
                  HTMLString = `<li style="background:${url};cursor: auto" class="added_file new_added_file"> <p class="new_file_del_button file_del_button"></p></li>`;
                  uploadEndChk(HTMLString, item);
                };
              } else {
                let fr = new FileReader();
                fr.readAsDataURL(item);
                fr.onload = function () {
                  HTMLString = `<li class="added_file"> <p class="new_file_del_button file_del_button"></p> <div class="file_icon"></div> <span class="file_name">${item.name}</span>${item.name}</span> <span class="file_size">${item.size}kb</span> </li>`;
                  uploadEndChk(HTMLString, item);
                };
              }
              if (uploadedfileArr.length - 1 == idx) {
                if (fileUploadOption) {
                  oldFiles.forEach(function (oldItem, indnx) {
                    appendTarget.insertAdjacentElement("afterbegin", oldItem);
                  });
                }
              }
            });
          }

          if (document.querySelectorAll(".added_file")[0]) {
            let added_fileLeng =
              document.querySelectorAll(".added_file").length;
            document.querySelectorAll(".added_file").forEach((item, idx) => {
              item.remove();
              if (idx == added_fileLeng - 1) {
                uploadOption();
              }
            });
          } else {
            uploadOption();
          }
        }
        if (document.querySelectorAll(".old_file")[0]) {
          document.querySelectorAll(".old_file").forEach(function (item, idx) {
            oldFiles.push(item.cloneNode(true));
            if (document.querySelectorAll(".old_file").length - 1 == idx) {
              fileUploadOption(true);
            }
          });
        } else {
          fileUploadOption(false);
        }
      });
    }

    fileUpload(
      document.querySelectorAll(
        "div.submit_date_wrapper div.input_wrapper div.file_add_section li.file_add_button input"
      )[0],
      document.querySelectorAll(
        "div.submit_date_wrapper div.input_wrapper div.file_add_section ul.b_submit_file_list"
      )[0]
    );

    document
      .querySelectorAll(".toggle_hide_end")[0]
      .addEventListener("click", function () {
        if (this.classList.contains("active")) {
          document.querySelectorAll(".time_row.end")[0].style.display = "none";
        } else {
          document.querySelectorAll(".time_row.end")[0].style.display = "flex";
        }
      });

    document
      .querySelectorAll(
        "div.date_detail_3 div.player_row ul.player_row_3 span.blue"
      )[0]
      .addEventListener("click", function () {
        if (this.textContent !== "일정 수락 후 노출됩니다.") {
          let copyArea = document.createElement("textarea");
          copyArea.classList.add("addrCopyTextArea");
          document.querySelector("body").append(copyArea);
          document.querySelectorAll(".addrCopyTextArea")[0].value =
            this.textContent;
          document.querySelectorAll(".addrCopyTextArea")[0].select();
          document.execCommand("copy");
          document.querySelectorAll(".addrCopyTextArea")[0].remove();

          createPopup({
            type: "confirm",
            title: "주소 복사",
            content: "주소가 클립보드에 복사되었습니다.",
          });

          // window.open(`https://map.kakao.com/?q=${this.textContent}`);
        }
      });
    document
      .querySelectorAll(".edit_schedule")[0]
      .addEventListener("click", function () {
        clearScheduleInfo();
        _animateRightModule(false, document.querySelectorAll(".d_4")[0]);
        _animateRightModule(true, document.querySelectorAll(".d_8")[0]);
        document.querySelectorAll(
          "div.chat_wrapper.white_m_wrapper.d_8 div.white_m_header span"
        )[0].textContent = "일정 수정하기";
        uploadedfileArr = [];
        //일정수정시 기데이터 뿌려줌
        const slideELement = document.querySelectorAll(".d_8")[0];
        fetch(
          `https://api.olapse.fifteenh.io/api/teamwork/schedule/detail?id=${this.getAttribute(
            "data-schedule_id"
          )}`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            const result = data.data.schedule;
            slideELement.querySelector("#content").value = result.contents;
            slideELement.querySelectorAll(
              ".date_picker_container"
            )[0].textContent = result.begin_date;
            if (result.end_date !== null) {
              slideELement.querySelectorAll(
                ".date_picker_container"
              )[1].style.display = "flex";
              slideELement.querySelectorAll(
                ".date_picker_container"
              )[1].textContent = result.end_date;
              dateType = 1;
            } else {
              dateType = 0;
            }
            slideELement.querySelector("#location").value = result.place;
            slideELement.querySelector("#phone_number").value = result.phone;
            slideELement.querySelector("#price").value = result.price;
            if (data.data.schedule_files.length !== 0) {
              data.data.schedule_files.forEach((item, idx) => {
                var imgExtension = [
                    "jpg",
                    "jpeg",
                    "gif",
                    "bmp",
                    "png",
                    "tif",
                    "tiff",
                    "tga",
                    "psd",
                    "ai",
                  ],
                  extensionTarget = item.file_url.split(".");
                let htmlString;
                if (
                  imgExtension.includes(
                    extensionTarget[extensionTarget.length - 1]
                  )
                ) {
                  htmlString = `<li fileIdx="${item.id}" style="background-image:url('${item.file_url}');cursor: auto" class="added_file old_file"> <p class="old_file_del_button file_del_button"></p></li>`;
                  document
                    .querySelectorAll(".d_8 .b_submit_file_list")[0]
                    .insertAdjacentHTML("afterbegin", htmlString);
                  document
                    .querySelectorAll(".old_file_del_button")[0]
                    .addEventListener("click", function () {
                      deleteFileIdArr.push(
                        this.parentNode.getAttribute("fileIdx")
                      );
                      this.parentNode.remove();
                    });
                  document
                    .querySelectorAll(".old_file_del_button")[0]
                    .classList.remove("old_file_del_button");
                } else {
                  htmlString = `<li fileIdx="${
                    item.id
                  }" class="added_file old_file">  <div class="file_icon"></div> <span class="file_name">${
                    item.file_url.split("/")[
                      item.file_url.split("/").length - 1
                    ]
                  }</span>${
                    item.file_url.split("/")[
                      item.file_url.split("/").length - 1
                    ]
                  }</span> <span class="file_size">${
                    item.file_size
                  }kb</span> </li>`;
                  document
                    .querySelectorAll(".d_8 .b_submit_file_list")[0]
                    .insertAdjacentHTML("afterbegin", htmlString);
                }
              });
            }
          });
      });

    document
      .querySelectorAll(
        "div.memeber_detail_wrapper ul.member_detail_menu_list li.member_call"
      )[0]
      .addEventListener("click", function () {
        phoneCall(this.querySelectorAll("span")[0].textContent);
      });
    document
      .querySelectorAll(".customer_phone_number_row span")[0]
      .addEventListener("click", function () {
        this.textContent !== "-" && phoneCall(this.textContent);
      });

    document
      .querySelectorAll(".view_schedule_more_button")[0]
      .addEventListener("click", function (e) {
        if (this.classList.contains("active")) {
          document.querySelectorAll(".common_more_wrapper")[0].style.display =
            "none";
          this.classList.remove("active");
        } else {
          document.querySelectorAll(".common_more_wrapper")[0].style.display =
            "flex";
          this.classList.add("active");
        }
      });

    //일정 상태 변경
    document
      .querySelectorAll(".common_more_wrapper li")
      .forEach((item, idx) => {
        item.addEventListener("click", function () {
          if (idx == 0) {
            scheduleStay(this.getAttribute("data-schedule_id"));
          } else if (idx == 1) {
            scheduleReturn(this.getAttribute("data-schedule_id"));
          } else if (idx == 2) {
            scheduleCancel(this.getAttribute("data-schedule_id"));
          } else if (idx == 3) {
            scheduleShare(this.getAttribute("data-schedule_id"));
          } else if (idx == 4) {
            scheduleRemove(this.getAttribute("data-schedule_id"));
          }
        });
      });
    // noticeFunction();
    function noticeFunction() {
      getParameterByName("idx") && teamworkNoticeList();
      function teamworkNoticeList() {
        inner(
          `https://api.olapse.fifteenh.io/api/teamwork/notice?page=1&id=${getParameterByName(
            "idx"
          )}`
        );
        function inner(url) {
          fetch(url, {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (data) {
              if (data.data.teamwork_notices.data.length !== 0) {
                document.querySelectorAll(".d_6 .no_data")[0].style.display =
                  "none";
                data.data.teamwork_notices.data.forEach((item, idx) => {
                  const htmlString = `<li class="new_item" idx="${
                    item.id
                  }"> <h1>${item.title}</h1> <span>${
                    item.created_at.split(" ")[0]
                  }</span> </li>`;
                  document
                    .querySelectorAll(".notice_list_wrapper")[0]
                    .insertAdjacentHTML("beforeend", htmlString);
                  document
                    .querySelectorAll(".new_item")[0]
                    .addEventListener("click", function () {
                      getTeamworkNoticeDetail(this.getAttribute("idx"));
                    });
                  document
                    .querySelectorAll(".new_item")[0]
                    .classList.remove("new_item");
                });
              } else if (data.data.teamwork_notices.data.length == 0) {
                document.querySelectorAll(".d_6 .no_data")[0].style.display =
                  "flex";
              }
              if (data.data.teamwork_notices.next_page_url) {
                inner(data.data.teamwork_notices.next_page_url);
              }
            });
        }
      }

      //공지사항 상세 불러오기
      function getTeamworkNoticeDetail(id) {
        fetch(
          `https://api.olapse.fifteenh.io/api/teamwork/notice/detail?id=${id}`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.success == true) {
              document
                .querySelectorAll(".notice_header_right")[0]
                .setAttribute("idx", id);
              (document.querySelectorAll(
                "div.notice_detail_wrapper.notice_detail_wrapper_2 div.title_area h1"
              )[0].textContent = data.data.teamwork_notice.title),
                (document.querySelectorAll(
                  "div.notice_detail_wrapper.notice_detail_wrapper_2 div.title_area span"
                )[0].textContent =
                  data.data.teamwork_notice.created_at.split(" ")[0]),
                (document.querySelectorAll(
                  "div.notice_detail_wrapper.notice_detail_wrapper_2 div.notice_contents span"
                )[0].textContent = data.data.teamwork_notice.contents);
              _animateRightModule(true, document.querySelectorAll(".d_7")[0]);
            }
          });
      }
      // 메뉴 토글
      document
        .querySelectorAll(".header_right")[0]
        .addEventListener("click", function () {
          if (this.classList.contains("active")) {
            this.classList.remove("active");
            document.querySelectorAll(".sub_popup_wrapper")[0].style.display =
              "none";
          } else {
            this.classList.add("active");
            document.querySelectorAll(".sub_popup_wrapper")[0].style.display =
              "flex";
          }
        });
      document
        .querySelectorAll("div.sub_popup_wrapper ul li")[0]
        .addEventListener("click", function () {
          //공지 수정
          disableAllPopup();
          document.querySelectorAll(".sub_popup_wrapper")[0].style.display =
            "none";
          document
            .querySelectorAll(".edit_checker")[0]
            .setAttribute(
              "notice",
              document
                .querySelectorAll(".notice_header_right")[0]
                .getAttribute("idx")
            );
          document.querySelectorAll(".chatting_area")[0].style.display = "none";
          document.querySelectorAll(".writeTeamworkNotice")[0].style.display =
            "flex";
          document.querySelectorAll(
            ".writeTeamworkNotice .right_area_title"
          )[0].textContent = "공지사항 수정";
          document.querySelectorAll(".submit_notice")[0].textContent =
            "수정완료";
          fetch(
            `https://api.olapse.fifteenh.io/api/teamwork/notice/detail?id=${document
              .querySelectorAll(".edit_checker")[0]
              .getAttribute("notice")}`,
            {
              method: "GET",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
            .then(function (response) {
              return response.json();
            })
            .then(function (data) {
              if (data.success == true) {
                document.querySelector("#noti_title").value =
                  data.data.teamwork_notice.title;
                document.querySelector("#noti_content").value =
                  data.data.teamwork_notice.contents;
              }
            });
        });
      document
        .querySelectorAll("div.sub_popup_wrapper ul li")[1]
        .addEventListener("click", function () {
          //공지 삭제
          removeTeamworkNotice(
            document
              .querySelectorAll(".notice_header_right")[0]
              .getAttribute("idx")
          );
        });
      function removeTeamworkNotice(id) {
        fetch(
          `https://api.olapse.fifteenh.io/api/teamwork/notice/delete?teamwork_notice_id=${id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.success == true) {
              createPopup({
                type: "custom",
                title: "공지사항 삭제 완료",
                content: "팀워크 공지사항을 삭제하였습니다.",
                custom: () => {
                  location.reload(true);
                },
              });
            } else {
              createPopup({
                type: "reTry",
                title: "삭제 불가능",
                content: data.alert,
              });
            }
          });
      }
    }
    shareLink();
    function shareLink() {
      const copyElement = document.querySelectorAll(".copy_target")[0];
      copyElement.value =
        "https://olapse.page.link/?link=" +
        `https://app.olapse.com/join_team.html?idx=${getParameterByName(
          "idx"
        )}` +
        "&apn=kr.olapse.app&ibi=io.fifteenh.olapse.app";

      function copy() {
        var obj = copyElement;
        obj.select();
        document.execCommand("copy"); //복사
        createPopup({
          type: "confirm",
          title: "복사완료",
          content: "참여링크를 클립보드에 복사하였습니다.",
        });
      }
      document
        .querySelector("#share_link")
        .addEventListener("click", function () {
          if (
            document
              .querySelectorAll("div.pers ul li")[0]
              .classList.contains("active")
          ) {
            if (myPermissionInfo == "팀장") {
              copy();
            } else {
              createPopup({
                type: "confirm",
                title: "공유 불가",
                content: "공유 권한이 없습니다.",
              });
            }
          }

          if (
            document
              .querySelectorAll("div.pers ul li")[1]
              .classList.contains("active")
          ) {
            if (myPermissionInfo == "팀장" || myPermissionInfo == "부팀장") {
              copy();
            } else {
              createPopup({
                type: "confirm",
                title: "공유 불가",
                content: "공유 권한이 없습니다.",
              });
            }
          }

          if (
            document
              .querySelectorAll("div.pers ul li")[2]
              .classList.contains("active")
          ) {
            copy();
          }
        });
    }
    function closePaymentTypeSelectTypePopup(element) {
      _animationDisappearBottomUp(
        element.querySelectorAll(".payment_select_popup_box")[0]
      );
      setTimeout(() => {
        element.style.display = "none";
      }, 400);
    }

    //일정 완료 취소(단순 팝업 닫기)
    document
      .querySelectorAll(
        ".payment_select_popup_wrapper_1 button:nth-of-type(1)"
      )[0]
      .addEventListener("click", function () {
        closePaymentTypeSelectTypePopup(
          document.querySelectorAll(".payment_select_popup_wrapper_1")[0]
        );
      });
    //일정 완료 취소(단순 팝업 닫기)
    document
      .querySelectorAll(
        ".payment_select_popup_wrapper_2 button:nth-of-type(1)"
      )[0]
      .addEventListener("click", function () {
        closePaymentTypeSelectTypePopup(
          document.querySelectorAll(".payment_select_popup_wrapper_2")[0]
        );
      });
  }, []);

  const disableAllPopup = () => {
    document.querySelectorAll(".right_popup").forEach((item, idx) => {
      item.style.display = "none";
    });
  };
  let isModify = false,
    clickCount = 0;
  const addDate = (e) => {
    if (clickCount == 0) {
      clickCount++;
      if (
        document.querySelectorAll(".d_8 div.white_m_header span")[0]
          .textContent == "일정 수정하기"
      ) {
        if (
          document.querySelectorAll(".date_picker_container")[0].textContent ==
            "" ||
          document.querySelectorAll(".date_picker_container")[0].textContent ==
            "일시"
        ) {
          clickCount = 0;
          createPopup({
            type: "reTry",
            title: "수정 실패",
            content: "일시를 입력해주세요",
          });
        } else {
          //일정 수정
          let formdata = new FormData();
          formdata.append(
            "begin_date",
            document.querySelectorAll(".date_picker_container")[0].textContent
          );
          formdata.append(
            "end_date",
            document.querySelectorAll(".date_picker_container")[1].textContent
          );
          formdata.append("contents", document.querySelector("#content").value);
          formdata.append("place", document.querySelector("#location").value);
          formdata.append(
            "phone",
            document.querySelector("#phone_number").value
          );
          formdata.append("price", document.querySelector("#price").value);
          formdata.append("is_individual", "0");
          formdata.append("teamwork_id", getParameterByName("idx"));
          formdata.append(
            "schedule_id",
            document
              .querySelectorAll(".edit_schedule")[0]
              .getAttribute("data-schedule_id")
          );
          if (deleteFileIdArr.length !== 0) {
            deleteFileIdArr.forEach((item) => {
              formdata.append("delete_files[]", item);
            });
          }

          if (uploadedfileArr.length !== 0) {
            uploadedfileArr.forEach((item, idx) => {
              formdata.append("files[]", item, item.name);
            });
          }

          fetch(`https://api.olapse.fifteenh.io/api/teamwork/schedule/modify`, {
            method: "POST",
            body: formdata,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (data) {
              if (document.querySelectorAll(".schedule_loading")[0]) {
                document.querySelectorAll(
                  ".schedule_loading"
                )[0].style.display = "flex";
              }

              if (data.success == true) {
                createPopup({
                  type: "custom",
                  title: "수정 완료",
                  content: "일정이 수정되었습니다.",
                  custom: () => {
                    location.reload(true);
                  },
                });
                if (document.querySelectorAll(".schedule_loading")[0]) {
                  document.querySelectorAll(
                    ".schedule_loading"
                  )[0].style.display = "none";
                }
              } else {
                clickCount = 0;
                createPopup({
                  type: "reTry",
                  title: "수정 실패",
                  content: data.alert,
                });
                if (document.querySelectorAll(".schedule_loading")[0]) {
                  document.querySelectorAll(
                    ".schedule_loading"
                  )[0].style.display = "none";
                }
              }
            });
        }
      } else {
        if (
          document.querySelectorAll(".date_picker_container")[0].textContent ==
            "" ||
          document.querySelectorAll(".date_picker_container")[0].textContent ==
            "일시"
        ) {
          clickCount = 0;
          createPopup({
            type: "reTry",
            title: "일정 생성 불가",
            content: "일시를 입력해주세요",
          });
        } else {
          //일정 등록
          let formdata = new FormData();
          formdata.append(
            "begin_date",
            document.querySelectorAll(".date_picker_container")[0].textContent
          );
          if (
            document
              .querySelectorAll(".toggle_hide_end")[0]
              .classList.contains("active")
          ) {
            formdata.append(
              "end_date",
              document.querySelectorAll(".date_picker_container")[1].textContent
            );
          }
          formdata.append("contents", document.querySelector("#content").value);
          formdata.append("place", document.querySelector("#location").value);
          formdata.append(
            "phone",
            document.querySelector("#phone_number").value
          );
          formdata.append("price", document.querySelector("#price").value);
          if (document.querySelectorAll(".team_work_title_1")[0]) {
            if (
              document.querySelectorAll(".team_work_title_1")[0].textContent ==
                "나와의 채팅" ||
              !getParameterByName("idx")
            ) {
              formdata.append("is_individual", 1);
              formdata.append(
                "teamwork_id",
                localStorage.getItem("chat_with_me_id")
              );
            } else {
              formdata.append("is_individual", 0);
              formdata.append("teamwork_id", props.teamworkId);
            }
          } else {
            if (localStorage.getItem("chat_with_me_id")) {
              formdata.append("is_individual", 1);
              formdata.append(
                "teamwork_id",
                localStorage.getItem("chat_with_me_id")
              );
            } else {
              createPopup({
                type: "custom",
                title: "일정 생성 불가",
                content: "나와의 채팅을 먼저 생성해주세요.",
                custom: () => {
                  navigate("/main");
                },
              });
            }
          }

          if (uploadedfileArr.length !== 0) {
            uploadedfileArr.forEach((item, idx) => {
              formdata.append("files[]", item, item.name);
            });
          }
          fetch(
            `https://api.olapse.fifteenh.io/api/teamwork/schedule/register`,
            {
              method: "POST",
              body: formdata,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
            .then(function (response) {
              return response.json();
            })
            .then(function (data) {
              if (document.querySelectorAll(".schedule_loading")[0]) {
                document.querySelectorAll(
                  ".schedule_loading"
                )[0].style.display = "flex";
              }

              if (data.success == true) {
                createPopup({
                  type: "custom",
                  title: "생성 완료",
                  content: "일정이 생성되었습니다.",
                  custom: () => {
                    if (window.location.pathname == "/cal") {
                      window.location.reload(true);
                    } else {
                      disableAllPopup();
                      clickCount = 0;
                    }
                  },
                });
                if (document.querySelectorAll(".schedule_loading")[0]) {
                  document.querySelectorAll(
                    ".schedule_loading"
                  )[0].style.display = "none";
                }
              } else {
                clickCount = 0;
                createPopup({
                  type: "reTry",
                  title: "일정 생성 불가",
                  content: data.alert,
                  // custom: () => {
                  //     navigate("/main");
                  // },
                });
                if (document.querySelectorAll(".schedule_loading")[0]) {
                  document.querySelectorAll(
                    ".schedule_loading"
                  )[0].style.display = "none";
                }
              }
            });
        }
      }
    }
  };
  //멤버 추가 기능
  function addMember() {
    let formdata = new FormData();
    formdata.append("teamwork_id", getParameterByName("idx"));

    if (document.querySelectorAll(".member_list_for_add li.active")[0]) {
      document
        .querySelectorAll(".member_list_for_add li.active")
        .forEach((item, idx) => {
          formdata.append("users[]", item.getAttribute("idx"));
        });
    }

    fetch(`https://api.olapse.fifteenh.io/api/teamwork/member/add`, {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          createPopup({
            type: "custom",
            title: "멤버 추가 완료",
            content: "선택한 멤버를 팀워크에 추가하였습니다.",
            custom: () => {
              location.reload(true);
            },
          });
        } else {
          createPopup({
            type: "reTry",
            title: "멤버 추가 실패",
            content: data.alert,
          });
        }
      });
  }

  const kakaoSearchAddress = (keyword) => {
    const kakaoRestApiKey = "ab81c6db1e33998b06bf891fd64dc5b3",
      appendTarget = document.querySelectorAll(".location_list")[0];
    if (keyword.length >= 2) {
      fetch(
        `https://dapi.kakao.com/v2/local/search/address.json?query=${String(
          keyword
        )}`,
        {
          method: "GET",
          headers: {
            Authorization: "KakaoAK " + kakaoRestApiKey,
          },
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.documents.length !== 0) {
            appendTarget.style.display = "flex";
            appendTarget.querySelectorAll("li").forEach((item, idx) => {
              item.remove();
            });
            data.documents.forEach((item, idx) => {
              appendTarget.insertAdjacentHTML(
                "beforeend",
                `<li class="new_address_item">${item.address_name}</li>`
              );
              document
                .querySelectorAll(".new_address_item")[0]
                .addEventListener("click", function () {
                  appendTarget.style.display = "none";
                  document.querySelector("#location").value = this.textContent;
                });
              document
                .querySelectorAll(".new_address_item")[0]
                .classList.remove("new_address_item");
            });
          } else {
            appendTarget.style.display = "none";
            appendTarget.querySelectorAll("li").forEach((item, idx) => {
              item.remove();
            });
          }
        });
    } else {
      appendTarget.style.display = "none";
      appendTarget.querySelectorAll("li").forEach((item, idx) => {
        item.remove();
      });
    }
  };
  const dirConfirm = () => {
    if (
      document.querySelectorAll(".d_11 .member_name")[0].textContent ==
      "내 매출/정산"
    ) {
      navigate(
        `/more/confirm?idx=${document
          .querySelectorAll(".d_11 .member_name")[0]
          .getAttribute("idx")}`
      );
    } else {
      navigate(
        `/more/confirm?idx=${document
          .querySelectorAll(".d_11 .member_name")[0]
          .getAttribute("idx")}&name=${document
          .querySelectorAll(".d_11 .member_name")[0]
          .getAttribute("name")}`
      );
    }
  };

  return (
    <>
      <input
        className="copy_target"
        style={{ width: "1px", height: "1px" }}
        type="text"
      />
      {/* 팀워크 세팅 팝업 */}
      <div className="chat_wrapper white_m_wrapper right_popup d_1">
        <div className="white_m_header">
          <img
            onClick={() =>
              _animateRightModule(false, document.querySelectorAll(".d_1")[0])
            }
            className="white_m_header_img_1"
            src="/assets/images/icon_close_type_1.svg"
            alt=""
          />
        </div>
        <div className="teamwork_row go_edit">
          <p className="teamwork_row_menu_img" />
          <div className="teamwork_col">
            <h1 />
            <span />
          </div>
        </div>
        <ul className="menu_list_1">
          <li id="share_link">
            <p>
              <img src="/assets/images/icon_menu_list_1.svg" alt="" />
            </p>
            <span>링크 공유</span>
          </li>
          <li id="member_list">
            <p>
              <img src="/assets/images/icon_menu_list_2.svg" alt="" />
            </p>
            <span>멤버 목록</span>
          </li>
          <li id="add_member">
            <p>
              <img src="/assets/images/icon_menu_list_3.svg" alt="" />
            </p>
            <span>멤버 추가</span>
          </li>
        </ul>
        <ul className="menu_list_2">
          <li
            className="btnMyCal"
            onClick={() =>
              _animateRightModule(true, document.querySelectorAll(".d_11")[0])
            }
          >
            <h1>내 매출/정산</h1>
            <span className="cal_count" />
          </li>
          <li
            onClick={() =>
              navigate(`/more/date?idx=${getParameterByName("idx")}`)
            }
          >
            <h1>일정 모아보기</h1>
            <span className="cal_count_2" />
          </li>
          <li
            onClick={() =>
              navigate(`/more/photo?idx=${getParameterByName("idx")}`)
            }
          >
            <h1>사진/동영상 모아보기</h1>
            <span className="photo_count" />
          </li>
          <li
            onClick={() =>
              navigate(`/more/file?idx=${getParameterByName("idx")}`)
            }
          >
            <h1>파일 모아보기</h1>
            <span className="file_count" />
          </li>
          <li
            onClick={() =>
              _animateRightModule(true, document.querySelectorAll(".d_6")[0])
            }
          >
            <h1>팀워크 공지사항</h1>
            <span className="notice_count" />
          </li>
        </ul>
        <ul className="menu_list_3">
          <li>
            <div className="menu_list_3_text">
              <h1>팀워크 메세지 숨김</h1>
              <span> 팀장 외 메세지, 사진/동영상, 파일을 팀워크에서 숨김 </span>
            </div>
            <div className="toggle_button hide_msg_button">
              <p />
            </div>
          </li>
          <li>
            <div className="menu_list_3_text">
              <h1>알림</h1>
            </div>
            <div className="toggle_button alarm_toggle_button">
              <p />
            </div>
          </li>
        </ul>
        <button className="end_teamwork">나가기</button>
      </div>
      {/* 팀워크 설정 팝업 */}
      <div className="chat_wrapper white_m_wrapper right_popup d_2">
        <div className="white_m_header">
          <img
            onClick={() =>
              _animateRightModule(false, document.querySelectorAll(".d_2")[0])
            }
            className="close_right_module_button"
            src="/assets/images/icon_close_type_1.svg"
            alt=""
          />
          <span>팀워크 설정</span>
        </div>
        <div className="create_wrapper_2">
          <div className="photo_area">
            <label>
              <input type="file" accept="image/*" />
              <p className="icon_photo" />
            </label>
          </div>
          <div className="teamword_info_row">
            <h1>팀워크 명</h1>
            <input
              id="modi_name"
              type="text"
              className="addTeam_nameEdit"
              onKeyUp={(e) => {
                var regExp =
                  /[0123456789\{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi;
                if (e.target.value.length > 10) {
                  e.target.value = e.target.value.substr(0, 10);
                } else {
                  if (regExp.test(e.target.value)) {
                    e.target.value = e.target.value.substring(
                      0,
                      e.target.value.length - 1
                    );
                  }
                }
              }}
              onKeyDown={(e) => {
                var regExp =
                  /[0123456789\{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi;
                if (e.target.value.length > 10) {
                  e.target.value = e.target.value.substr(0, 10);
                } else {
                  if (regExp.test(e.target.value)) {
                    e.target.value = e.target.value.substring(
                      0,
                      e.target.value.length - 1
                    );
                  }
                }
              }}
              maxLength="10"
            />
          </div>
          <ul>
            <li>
              <div className="create_col">
                <h1 className="nameBtnCom">
                  일정 카드 결제 받기
                  <button
                    className="btn_toolTip"
                    onClick={() => {
                      createPopup({
                        type: "confirm",
                        title: "일정 카드결제 안내",
                        content:
                          "해당 팀워크에서 이루어지는 모든 일정에 대한 카드결제 정산은 팀장의 사업자 및 계좌를 통해 이루어집니다.",
                      });
                    }}
                  >
                    <img src="/assets/images/icon_info.svg" />
                  </button>
                </h1>
                <span>사업자 인증 및 카드결제 연동 후 이용할 수 있습니다.</span>
              </div>
              <div className="toggle_button toggle_button_1">
                <p />
              </div>
            </li>
            <li>
              <div className="flex_inner">
                <div className="create_col">
                  <h1>팀워크 비밀번호 설정</h1>
                  <span>팀워크 링크로 합류하는 멤버에게만 적용합니다.</span>
                </div>
                <div className="toggle_button password_trigger toggle_button_2">
                  <p />
                </div>
              </div>
              <input
                className="teamwork_password modi_teamwork_password"
                style={{ fontSize: 14 }}
                placeholder="팀워크 비밀번호"
                type="password"
              />
              <button className="button_change_password">
                비밀번호 변경하기
              </button>
            </li>
            <li>
              <div className="create_col">
                <h1>멤버간 대화, 프로필보기 제한</h1>
                <span>
                  팀장 및 부팀장을 제외한 멤버들간의 대화 및 프로필 보기가
                  제한됩니다.
                </span>
              </div>
              <div className="toggle_button toggle_button_3">
                <p />
              </div>
            </li>
          </ul>
          <div className="pers">
            <h1>멤버 초대 권한</h1>
            <ul>
              <li idx={0}>팀장</li>
              <li idx={1}>팀/부팀장</li>
              <li idx={2}>모두</li>
            </ul>
          </div>
          <button className="submit_modi">수정완료</button>
        </div>
      </div>
      {/* 멤버 리스트 팝업 */}
      <div className="chat_wrapper white_m_wrapper right_popup d_3">
        <div className="white_m_header">
          <img
            onClick={() =>
              _animateRightModule(false, document.querySelectorAll(".d_3")[0])
            }
            src="/assets/images/icon_close_type_1.svg"
            alt=""
          />
          <span>멤버 목록</span>
          <button className="edit_member">편집</button>
        </div>
        <div className="all_memeber_list all_memeber_list_2">
          <ul className="member_list" />
        </div>
      </div>
      {/* 일정 상세 팝업 */}
      <div className="chat_wrapper white_m_wrapper right_popup d_4">
        <div className="white_m_header">
          <img
            onClick={() =>
              _animateRightModule(false, document.querySelectorAll(".d_4")[0])
            }
            src="/assets/images/icon_close_type_1.svg"
            alt=""
          />
          <span>일정</span>
          <ul className="right">
            <li className="edit_schedule">
              <img src="/assets/images/icon_editr.svg" alt="" />
            </li>
            <li className="view_schedule_more_button">
              <img src="/assets/images/icon_more_right.svg" alt="" />
            </li>
          </ul>
          <ul className="common_more_wrapper">
            <li className="schedule_stay">일정 보류</li>
            <li className="schedule_return">일정 반환</li>
            <li className="schedule_cancel">일정 취소</li>
            <li className="schedule_share">일정 공유</li>
            <li className="red schedule_remove">일정 삭제</li>
          </ul>
        </div>
        <div className="date_detail_3">
          <h1>
            완료
            <p />
          </h1>
          <div className="player_row">
            <h1 className="accept_request_row">요청/수락자</h1>
            <div className="player_row_inner accept_request_row">
              <h1 />
              <ul>
                <li />
                <li />
              </ul>
            </div>
            <div className="payment_info_row_3">
              <h1>결제/정산</h1>
              <span className="payment_type_text" />
            </div>
            <div className="date_detail_textArea">
              <h1>
                내용<b className="editType editTypeContents">(수정됨)</b>
              </h1>
              <span />
            </div>
            <ul className="player_row_2">
              <li>
                <h1>
                  시작일<b className="editType editTypeDate">(수정됨)</b>
                </h1>
                <span />
              </li>
              <li>
                <h1>
                  종료일<b className="editType editTypeEndDate">(수정됨)</b>
                </h1>
                <span />
              </li>
            </ul>
            <ul className="player_row_3 address_row">
              <li>
                <h1>
                  주소<b className="editType editTypeAddr">(수정됨)</b>
                </h1>
                <span className="blue" />
                {/* <textarea className="addrCopyTextArea"></textarea> */}
              </li>
              <li className="customer_phone_number_row">
                <h1>
                  고객 연락처<b className="editType editTypePhone">(수정됨)</b>
                </h1>
                <span className="blue" />
              </li>
            </ul>
            <div className="respect_price">
              <div className="respect_price_row">
                <h1>견적</h1>
                <span />
              </div>
            </div>
            <span className="file_add_subText">사진/파일 첨부</span>
            <div className="file_add_section">
              <ul className="b_submit_file_list" />
            </div>
            <ul className="date_detail_button_wrapper" />
          </div>
        </div>
      </div>
      {/* 멤버 상세정보 */}
      <div className="chat_wrapper white_m_wrapper right_popup d_5">
        <div className="white_m_header">
          <img
            onClick={() =>
              _animateRightModule(false, document.querySelectorAll(".d_5")[0])
            }
            className="white_m_header_img_1"
            src="/assets/images/icon_close_type_1.svg"
            alt=""
          />
          <span className="member_name" />
        </div>
        <div className="memeber_detail_wrapper">
          <div className="member_detail_info_top">
            <div className="member_detail_profile">
              <p className="mark_type_1" />
            </div>
          </div>
          <ul className="member_detail_menu_list">
            <li className="create_new_teamwork">
              <p />
              <span>새 팀워크 개설</span>
            </li>
            <li className="member_call">
              <a>
                <p />
                <span />
              </a>
            </li>
          </ul>
          <button className="chat_add_friend">멤버 목록에 추가</button>
          <button className="remove_permission">부팀장 권한 제거</button>
          <div className="member_detail_menu_list_wrapper">
            <ul>
              <li>
                <a>일정</a>
              </li>
              <li>
                <a>매출/정산</a>
              </li>
              <li>
                <a>내보내기</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* 공지사항 리스트 팝업 */}
      <div className="chat_wrapper white_m_wrapper right_popup d_6">
        <div className="white_m_header">
          <img
            onClick={() =>
              _animateRightModule(false, document.querySelectorAll(".d_6")[0])
            }
            src="/assets/images/icon_back.svg"
            alt=""
          />
          <span>팀워크 공지사항</span>
        </div>
        <ul className="notice_list_wrapper" />
        <div className="no_data">
          <span>공지사항이 없습니다.</span>
        </div>
      </div>
      {/* 공지사항 상세 팝업 */}
      <div className="chat_wrapper white_m_wrapper right_popup d_7">
        <div className="white_m_header">
          <img
            onClick={() =>
              _animateRightModule(false, document.querySelectorAll(".d_7")[0])
            }
            src="/assets/images/icon_back.svg"
            alt=""
          />
          <span>팀워크 공지사항</span>
          <img
            className="header_right notice_header_right"
            src="/assets/images/icon_more_right.svg"
            alt=""
          />
          <div className="sub_popup_wrapper">
            <ul>
              <li>공지사항 수정</li>
              <li className="red">공지사항 삭제</li>
            </ul>
          </div>
        </div>
        <div className="notice_detail_wrapper notice_detail_wrapper_2">
          <div className="title_area">
            <h1 />
            <span />
          </div>
          <div className="notice_contents">
            <span />
          </div>
        </div>
      </div>
      {/* 일정 카드 결제 팝업 */}
      <div className="chat_wrapper white_m_wrapper right_popup d_9">
        <div className="white_m_header">
          <img
            onClick={() =>
              _animateRightModule(false, document.querySelectorAll(".d_9")[0])
            }
            src="/assets/images/icon_close_type_1.svg"
            alt=""
          />
          <span>일정 카드결제</span>
        </div>
        <div className="submit_date_wrapper submit_card_wrapper">
          {/* row_1 */}
          <div className="submit_date_row">
            <div className="textArea_row">
              <h1>
                고객명
                <span className="red">*</span>
              </h1>
              <input
                id="payment_name"
                autocomplete="off"
                placeholder="고객명"
                type="text"
              />
            </div>
            <div className="date_picker_row">
              <h1>
                고객연락처
                <span className="red">*</span>
              </h1>
              <input
                id="payment_phone"
                autocomplete="off"
                placeholder="연락처 (‘-’ 제외 입력)"
                type="number"
              />
            </div>
          </div>
          {/* row_2 */}
          <div className="submit_date_row">
            <div className="textArea_row">
              <h1>
                결제금액
                <span className="red">*</span>
              </h1>
              <div
                style={{
                  marginTop: "5px",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  position: "static",
                  width: "100%",
                  height: "44px",
                  left: "16px",
                  top: "25px",
                  background: "#f2f2f2",
                  borderRadius: "8px",
                  flex: "none",
                  order: "1",
                  alignSelf: "stretch",
                  flexGrow: "0",
                  margin: "6px 0px",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
                className="input_outter"
              >
                <input
                  id="payment_price"
                  autocomplete="off"
                  placeholder="금액"
                  type="number"
                />
                <span>원</span>
              </div>
            </div>
            <div className="date_picker_row">
              <h1>
                카드번호
                <span className="red">*</span>
              </h1>
              <input
                id="payment_card_number"
                autocomplete="off"
                placeholder="카드번호 (‘-’ 제외)"
                type="number"
              />
            </div>
            <div className="date_picker_row">
              <h1>
                유효기간
                <span className="red">*</span>
              </h1>
              <input
                id="payment_due_date"
                autocomplete="off"
                className="half_input"
                placeholder="MMYY"
                type="number"
              />
            </div>
          </div>
          {/* row_3 */}
          <div style={{ borderBottom: 0 }} className="submit_date_row">
            <div className="textArea_row">
              <h1>
                할부
                <span className="red">*</span>
              </h1>
              <select id="payment_month">
                <option value={"00"}>일시불</option>
                <option value={"02"}>2개월</option>
                <option value={"03"}>3개월</option>
                <option value={"04"}>4개월</option>
                <option value={"05"}>5개월</option>
                <option value={"06"}>6개월</option>
                <option value={"07"}>7개월</option>
                <option value={"08"}>8개월</option>
                <option value={"09"}>9개월</option>
                <option value={"10"}>10개월</option>
                <option value={"11"}>11개월</option>
                <option value={"12"}>12개월</option>
              </select>
            </div>
          </div>
          <button
            onClick={(e) => payment(e.target.getAttribute("schedule_index"))}
            className="payment_submit_button"
          >
            등록
          </button>
        </div>
      </div>
      <div className="chat_loadingPay" style={{ display: "none" }}>
        <div className="load_item" />
        <span>결제 진행중 입니다.</span>
      </div>
      <div className="chat_wrapper white_m_wrapper right_popup d_10">
        <div className="white_m_header">
          <img
            onClick={() =>
              _animateRightModule(false, document.querySelectorAll(".d_10")[0])
            }
            src="/assets/images/icon_close_type_1.svg"
            alt=""
          />
          <span className="member_name" />
        </div>
        {/* 캘린더 */}
        <div className="calender_loading" style={{ display: "none" }}>
          <div className="load_item" />
          <span>일정을 불러오는 중 입니다.</span>
        </div>
        <div className="calendar" />
        {/* 캘린더 가이드 */}
        <div className="cal_guide">
          <ul>
            <li>
              <p />
              <span>요청</span>
            </li>
            <li>
              <p />
              <span>수락</span>
            </li>
            <li>
              <p />
              <span>완료</span>
            </li>
            <li>
              <p />
              <span>보류</span>
            </li>
            <li>
              <p />
              <span>취소</span>
            </li>
          </ul>
          <h1>
            <span>2021.08.08. ~ 2021.08.08. (7일)</span>
          </h1>
        </div>
        <div className="data_wrapper">
          {/* 일정 리스트 */}
          <ul className="data_list member_data_list" />
          <div className="no_data">
            <span>일정이 없습니다.</span>
          </div>
        </div>
      </div>
      {/* 내 매출, 정산 팝업 */}
      <div className="chat_wrapper white_m_wrapper right_popup d_11">
        <div className="white_m_header">
          <img
            onClick={() =>
              _animateRightModule(false, document.querySelectorAll(".d_11")[0])
            }
            src="/assets/images/icon_back.svg"
            alt=""
          />
          <span className="member_name">내 매출/정산</span>
        </div>
        <div className="memeber_price_wrapper">
          <div className="date_picker_row">
            <img src="/assets/images/icon_cal_typr_2.svg" alt="" />
            <span></span>
          </div>
          <div className="price_detail_info">
            <h1>
              <span></span>
              <span className="blue"></span>
            </h1>
            <div className="member_price_sort_row">
              <h1></h1>
              <button onClick={() => dirConfirm()}>정산확인</button>
            </div>
          </div>
          <div className="memeber_price_list_wrapper">
            <ul></ul>
          </div>
          <div className="no_data">
            <span>매출/정산 내역이 없습니다.</span>
          </div>
        </div>
      </div>
      {/* 정산 확인 */}
      <div className="chat_wrapper white_m_wrapper right_popup d_12">
        <div className="white_m_header">
          <img
            onClick={() =>
              _animateRightModule(false, document.querySelectorAll(".d_12")[0])
            }
            src="/assets/images/icon_close_type_1.svg"
            alt=""
          />
          <span className="member_name" />
          <button>완료</button>
        </div>
        <div className="view_price_wrapper">
          <ul>
            <li className="active">
              <div className="left">
                <h1>10,000원 (현금)</h1>
                <span>일정완료일 : 2021.08.08.</span>
              </div>
            </li>
            <li>
              <div className="left">
                <h1>10,000원 (현금)</h1>
                <span>일정완료일 : 2021.08.08.</span>
              </div>
            </li>
            <li>
              <div className="left">
                <h1>10,000원 (현금)</h1>
                <span>일정완료일 : 2021.08.08.</span>
              </div>
            </li>
            <li>
              <div className="left">
                <h1>10,000원 (현금)</h1>
                <span>일정완료일 : 2021.08.08.</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {/* 일정 등록 팝업 */}
      <div className="chat_wrapper white_m_wrapper right_popup d_8">
        <div className="white_m_header">
          <img
            onClick={() =>
              _animateRightModule(false, document.querySelectorAll(".d_8")[0])
            }
            src="/assets/images/icon_close_type_1.svg"
            alt=""
          />
          <span>
            {window.location.pathname ? <>개인 일정 등록</> : <>일정 등록</>}
          </span>
        </div>
        <div className="submit_date_wrapper">
          <div className="submit_date_row">
            <div className="textArea_row">
              <h1>
                내용
                <span className="red">*</span>
              </h1>
              <textarea id="content" placeholder="내용" />
            </div>
            <div className="date_picker_row">
              <h1>
                일시
                <span className="red">*</span>
              </h1>
              <div className="date_picker_container">일시</div>
              <div
                style={{ display: "none" }}
                className="date_picker_container"
              >
                일시
              </div>
            </div>
          </div>
          <div className="submit_date_row">
            <div className="input_wrapper">
              <h1>장소</h1>
              <input
                onChange={(e) => kakaoSearchAddress(e.target.value)}
                id="location"
                autocomplete="off"
                placeholder="장소"
                type="text"
              />
              <ul className="location_list">
                <li>서울특별시 무슨동 무슨리</li>
                <li>서울특별시 무슨동 무슨리</li>
                <li>서울특별시 무슨동 무슨리</li>
                <li>서울특별시 무슨동 무슨리</li>
              </ul>
            </div>
            <div className="input_wrapper">
              <h1>고객 연락처</h1>
              <input
                id="phone_number"
                placeholder="연락처 (‘-’제외 입력)"
                type="text"
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </div>
          </div>
          <div className="input_wrapper">
            <h1>
              견적
              <span className="red">*</span>
            </h1>
            <div
              style={{
                marginTop: "5px",
                position: "relative",
                display: "flex",
                alignItems: "center",
                position: "static",
                width: "100%",
                height: "44px",
                left: "16px",
                top: "25px",
                background: "#f2f2f2",
                borderRadius: "8px",
                flex: "none",
                order: "1",
                alignSelf: "stretch",
                flexGrow: "0",
                margin: "6px 0px",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
              className="input_outter"
            >
              <input id="price" placeholder="금액" type="number" />
              <span>원</span>
            </div>
            <span className="file_add_subText">사진/파일 첨부</span>
            <div className="file_add_section">
              <ul className="b_submit_file_list">
                <li className="file_add_button">
                  <label>
                    <input type="file" />
                  </label>
                </li>
              </ul>
            </div>
            <button onClick={(e) => addDate(e)} className="date_submit_button">
              등록
            </button>
          </div>
        </div>
        <div className="schedule_loading ">
          <div className="load_item" />
          <span>잠시만 기다려 주세요.</span>
        </div>
      </div>
      {/* 멤버 리스트 팝업_1(기능 팝업) */}
      <div className="memeber_list_popup memeber_list_popup_1">
        <div className="member_list_box">
          <h1>멤버 목록 편집</h1>
          <ul className="member_list member_list_editable" />
          <ul>
            <li className="cancel_popup_button">취소</li>
            <li className="red remove_member_button">내보내기</li>
          </ul>
        </div>
        <div className="background_black" />
      </div>
      {/* 멤버 리스트 팝업_2(기능 팝업) */}
      <div className="memeber_list_popup memeber_list_popup_2">
        <div className="member_list_box">
          <h1>멤버 추가</h1>
          <ul className="member_list member_list_for_add" />
          <ul>
            <li className="cancel_popup_button">취소</li>
            <li onClick={() => addMember()} className="blue">
              추가
            </li>
          </ul>
        </div>
        <div className="background_black" />
      </div>
      {/* 이미지 확대보기(공용) */}
      <div className="img_viewer">
        <a
          className="img_viewer_downloader"
          style={{ display: "none" }}
          download
        />
        <div className="img_viewer_header">
          <img
            className="close_viewer"
            src="/assets/images/icon_close_type_1.svg"
            alt=""
          />
          <span>이름</span>
          <img
            className="download_img"
            src="/assets/images/icon_download.svg"
            alt=""
          />
        </div>
        <div className="slide_left" />
        <div className="slide_right" />
        <div className="img_viewer_bottom">
          <div className="size_controller">
            <img src="/assets/images/icon_small.svg" alt="" />
            <span>120%</span>
          </div>
          <ul>
            <li>
              <img src="/assets/images/icon_minus.svg" alt="" />
            </li>
            <li>
              <img src="/assets/images/icon_plus_w.svg" alt="" />
            </li>
          </ul>
        </div>
      </div>
      {/* 일정 선택 팝업 */}
      <div className="popup_wrapper_2">
        <div className="date_picker_box">
          <h1>일시 설정</h1>
          <div className="toggle_row">
            <span>시작 - 종료일</span>
            <div className="toggle_button toggle_hide_end active">
              <p />
            </div>
          </div>
          <div className="time_col">
            <div className="time_row start">
              <div className="col active">
                <span className="start_time_data" />
                <img src="/assets/images/icon_cal_g.svg" alt="" />
              </div>
              <div className="col2">
                <input id="time_picker_1" defaultValue="00:00" type="text" />
                <img src="/assets/images/icon_time.svg" alt="" />
              </div>
            </div>
            <div className="time_row end">
              <div className="col">
                <span className="end_time_data" />
                <img src="/assets/images/icon_cal_g.svg" alt="" />
              </div>
              <div className="col2">
                <input id="time_picker_2" defaultValue="00:00" type="text" />
                <img src="/assets/images/icon_time.svg" alt="" />
              </div>
            </div>
          </div>
          <div className="fullcal_wrapper" />
          <ul className="button_wrapper">
            <li className="hide_date_popup">취소</li>
            <li className="blue submit_date_popup">적용</li>
          </ul>
        </div>
        <div className="background_black" />
      </div>
      {/* 결제 타입 선택 팝업 */}
      <div className="payment_select_popup_wrapper payment_select_popup_wrapper_1">
        <div className="payment_select_popup_box">
          <h1>결제 타입 선택</h1>
          <ul>
            <li
              className="active"
              onClick={() => {
                activeCh(
                  document.querySelectorAll(
                    ".payment_select_popup_wrapper_1 ul li"
                  )[0],
                  document.querySelectorAll(
                    ".payment_select_popup_wrapper_1 ul li"
                  )
                );
              }}
            >
              <p>
                <img src="/assets/images/icon_p_select_1.svg" alt="" />
              </p>
              <span>카드</span>
            </li>
            <li
              onClick={() => {
                activeCh(
                  document.querySelectorAll(
                    ".payment_select_popup_wrapper_1 ul li"
                  )[1],
                  document.querySelectorAll(
                    ".payment_select_popup_wrapper_1 ul li"
                  )
                );
              }}
            >
              <p>
                <img src="/assets/images/icon_p_select_2.svg" alt="" />
              </p>
              <span>현금</span>
            </li>
            <li
              onClick={() => {
                activeCh(
                  document.querySelectorAll(
                    ".payment_select_popup_wrapper_1 ul li"
                  )[2],
                  document.querySelectorAll(
                    ".payment_select_popup_wrapper_1 ul li"
                  )
                );
              }}
            >
              <p>
                <img src="/assets/images/icon_p_select_3.svg" alt="" />
              </p>
              <span>계좌이체</span>
            </li>
            <li
              onClick={() => {
                activeCh(
                  document.querySelectorAll(
                    ".payment_select_popup_wrapper_1 ul li"
                  )[3],
                  document.querySelectorAll(
                    ".payment_select_popup_wrapper_1 ul li"
                  )
                );
              }}
            >
              <p>
                <img src="/assets/images/icon_p_select_4.svg" alt="" />
              </p>
              <span>기타</span>
            </li>
          </ul>
          <div className="payment_select_popup_box_button_wrapper">
            <button>취소</button>
            <button>확인</button>
          </div>
        </div>
        <div className="payment_select_popup_wrapper_background_black" />
      </div>
      {/* 결제 타입 변경 팝업 */}
      <div className="payment_select_popup_wrapper payment_select_popup_wrapper_2">
        <div className="payment_select_popup_box">
          <h1>결제 타입 변경</h1>
          <ul>
            <li className="active">
              <p>
                <img src="/assets/images/icon_p_select_1.svg" alt="" />
              </p>
              <span>카드</span>
            </li>
            <li>
              <p>
                <img src="/assets/images/icon_p_select_2.svg" alt="" />
              </p>
              <span>현금</span>
            </li>
            <li>
              <p>
                <img src="/assets/images/icon_p_select_3.svg" alt="" />
              </p>
              <span>계좌이체</span>
            </li>
            <li>
              <p>
                <img src="/assets/images/icon_p_select_4.svg" alt="" />
              </p>
              <span>기타</span>
            </li>
          </ul>
          <div className="payment_select_popup_box_button_wrapper">
            <button>취소</button>
            <button>확인</button>
          </div>
        </div>
        <div className="payment_select_popup_wrapper_background_black" />
      </div>
      {/* 일정 공유 팝업 */}
      <div className="share_popup_wrapper share_popup_wrapper_1">
        <div className="share_popup_box">
          <h1>일정공유</h1>
          <ul className="share_team_work_list" />
          <div className="share_popup_box_button_wrapper">
            <button>취소</button>
            <button className="blue">공유</button>
          </div>
        </div>
        <div className="share_popup_wrapper_background_black" />
      </div>
      {/* 메세지 공유 팝업 */}
      <div className="share_popup_wrapper share_popup_wrapper_2">
        <div className="share_popup_box">
          <h1>메세지공유</h1>
          <ul className="share_team_work_list" />
          <div className="share_popup_box_button_wrapper">
            <button>취소</button>
            <button className="blue">공유</button>
          </div>
        </div>
        <div className="share_popup_wrapper_background_black" />
      </div>
      {/* 데이트피커 */}
      <div className="popup_wrapper_2">
        <div className="date_picker_box">
          <h1>일시 설정</h1>
          <div className="time_col">
            <div className="time_row start">
              <div className="col active">
                <span className="start_time_data" />
                <img src="/assets/images/icon_cal_g.svg" alt="" />
              </div>
            </div>
            <div className="time_row end">
              <div className="col">
                <span className="end_time_data" />
                <img src="/assets/images/icon_cal_g.svg" alt="" />
              </div>
            </div>
          </div>
          <div className="fullcal_wrapper"></div>
          <ul className="button_wrapper">
            <li className="hide_date_popup">취소</li>
            <li className="blue submit_date_popup">적용</li>
          </ul>
        </div>
        <div className="background_black" />
      </div>
      <div className="chat_loadingDate" style={{ display: "none" }}>
        <div className="load_item" />
        <span>일정을 불러오는 중 입니다.</span>
      </div>
    </>
  );
};
export default TeamworkModule;
