// 2022-02-24 인수인계
// 기능 : 비밀번호 변경 페이지입니다.

import React, { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import ElseHeader from "../components/elseHeader";
import {
  getParameterByName,
  toggleClass,
  inputChanger,
} from "../shared/script.js";
import createPopup from "../components/createPopup";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";

const ChangePassword = () => {
  const navigate = useNavigate();
  useEffect(() => {
    inputChanger();
    //비밀번호 변경

    document
      .querySelectorAll(".change_password_submit")[0]
      .addEventListener("click", function () {
        changePassword();
      });
    function changePassword() {
      let passwordRef =
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
      if (
        String(document.querySelector("#now_id").value).length !== 0 &&
        String(document.querySelector("#password").value).length !== 0 &&
        String(document.querySelector("#new_password").value).length !== 0 &&
        String(document.querySelector("#new_password_confirm").value).length !==
          0
      ) {
        if (
          document.querySelector("#new_password").value ==
          document.querySelector("#new_password_confirm").value
        ) {
          if (
            passwordRef.test(document.querySelector("#password").value) &&
            passwordRef.test(document.querySelector("#new_password").value) &&
            document.querySelector("#new_password").value ==
              document.querySelector("#new_password_confirm").value
          ) {
            let formdata = new FormData();
            formdata.append(
              "login_id",
              document.querySelector("#now_id").value
            );
            formdata.append(
              "password",
              document.querySelector("#password").value
            );
            formdata.append(
              "new_password",
              document.querySelector("#new_password").value
            );
            fetch("https://api.olapse.fifteenh.io/api/password/modify", {
              method: "POST",
              body: formdata,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
              .then(function (response) {
                return response.json();
              })
              .then(function (data) {
                if (data.success == true) {
                  createPopup({
                    type: "customT2",
                    title: "비밀번호 변경완료",
                    content:
                      "비밀번호가 성공적으로 변경되었습니다.<br> 새 비밀번호로 로그인해주세요.",
                    button_text_1: "로그인",
                    custom: () => {
                      navigate("/login");
                    },
                  });
                } else {
                  if (data.alert == "비밀번호가 일치하지 않습니다") {
                    createPopup({
                      type: "reTry",
                      title: "비밀번호 변경실패",
                      content: "현재 비밀번호가 일치하지 않습니다.",
                    });
                  } else {
                    createPopup({
                      type: "reTry",
                      title: "비밀번호 변경실패",
                      content: data.alert,
                    });
                  }
                }
              });
          } else {
            createPopup({
              type: "reTry",
              title: "비밀번호 변경 실패",
              content: "비밀번호는 영문+숫자+특수문자 8~20자리로 입력해주세요.",
            });
          }
        } else {
          createPopup({
            type: "reTry",
            title: "비밀번호 불일치",
            content: "입력하신 비밀번호가 일치하지 않습니다.",
          });
        }
      } else {
        createPopup({
          type: "reTry",
          title: "비밀번호 변경 실패",
          content: "모든 정보를 입력해주새요.",
        });
      }
    }
  }, []);
  return (
    <>
      <div id="wrap" class="change_pass_wrapper">
        <ElseHeader title="비밀번호 수정" />
        <div className="user_wrapper">
          <div className="input_wrapper">
            <div className="input_row">
              <h1>현재 아이디</h1>
              <div className="input_outter">
                <input id="now_id" placeholder="아이디" type="text" />
              </div>
            </div>
            <div className="input_row mt_15">
              <h1>현재 비밀번호</h1>
              <div className="input_outter">
                <input placeholder="비밀번호" id="password" type="password" />
              </div>
            </div>
            <div className="input_wrapper">
              <div className="input_row mt_15">
                <h1>새 비밀번호</h1>
                <div className="input_outter">
                  <input
                    id="new_password"
                    placeholder="새 비밀번호 (영문+숫자+특수문자 8~20자리)"
                    type="password"
                  />
                </div>
                <div className="input_outter">
                  <input
                    id="new_password_confirm"
                    placeholder="새 비밀번호 확인"
                    type="password"
                  />
                </div>
              </div>
            </div>
          </div>
          <button className="change_password_submit button_type_1 next_button">
            수정완료
          </button>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
