// 2022-02-24 인수인계
// 기능 : 알림 설정

import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ChatHeader from "./chat_header";
import { noData, getParameterByName } from "../shared/script";
import createPopup from "./createPopup";
const Alarm = () => {
  const navigate = useNavigate();
  useEffect(() => {
    fetch("https://api.olapse.fifteenh.io/api/mypage/alarm", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        data.data.use_teamwork_alarm == 1 &&
          document
            .querySelectorAll(".common_toggle_button")[0]
            .classList.add("active");
        data.data.use_send_schedule_alarm == 1 &&
          document
            .querySelectorAll(".common_toggle_button")[1]
            .classList.add("active");
        data.data.use_accept_schedule_alarm == 1 &&
          document
            .querySelectorAll(".common_toggle_button")[2]
            .classList.add("active");
        if (data.data.schedule_alarm_time) {
          document
            .querySelectorAll("ul.alarm_setting_list li.timer select option")
            .forEach((item, idx) => {
              if (data.data.schedule_alarm_time == item.value) {
                item.selected = true;
              }
            });
        }
      });

    document.querySelectorAll(".common_toggle_button").forEach((item, idx) => {
      item.addEventListener("click", function () {
        let formdata = new FormData();
        this.classList.contains("active")
          ? this.classList.remove("active")
          : this.classList.add("active");

        if (
          document
            .querySelectorAll(".common_toggle_button")[0]
            .classList.contains("active")
        ) {
          formdata.append("use_teamwork_alarm", "1");
        } else {
          formdata.append("use_teamwork_alarm", "0");
        }

        if (
          document
            .querySelectorAll(".common_toggle_button")[1]
            .classList.contains("active")
        ) {
          formdata.append("use_send_schedule_alarm", "1");
        } else {
          formdata.append("use_send_schedule_alarm", "0");
        }

        if (
          document
            .querySelectorAll(".common_toggle_button")[2]
            .classList.contains("active")
        ) {
          formdata.append("use_accept_schedule_alarm", "1");
        } else {
          formdata.append("use_accept_schedule_alarm", "0");
        }
        formdata.append(
          "schedule_alarm_time",
          document
            .querySelectorAll("ul.alarm_setting_list li.timer select")[0]
            .querySelectorAll("option:checked")[0].value
        );

        fetch("https://api.olapse.fifteenh.io/api/mypage/alarm/modify", {
          method: "POST",
          body: formdata,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {});
      });
    });

    document
      .querySelectorAll("ul.alarm_setting_list li.timer select")[0]
      .addEventListener("change", function () {
        const formdata = new FormData();
        if (
          document
            .querySelectorAll(".common_toggle_button")[0]
            .classList.contains("active")
        ) {
          formdata.append("use_teamwork_alarm", "1");
        } else {
          formdata.append("use_teamwork_alarm", "0");
        }

        if (
          document
            .querySelectorAll(".common_toggle_button")[1]
            .classList.contains("active")
        ) {
          formdata.append("use_send_schedule_alarm", "1");
        } else {
          formdata.append("use_send_schedule_alarm", "0");
        }

        if (
          document
            .querySelectorAll(".common_toggle_button")[2]
            .classList.contains("active")
        ) {
          formdata.append("use_accept_schedule_alarm", "1");
        } else {
          formdata.append("use_accept_schedule_alarm", "0");
        }
        formdata.append(
          "schedule_alarm_time",
          this.querySelectorAll("option:checked")[0].value
        );
        fetch("https://api.olapse.fifteenh.io/api/mypage/alarm/modify", {
          method: "POST",
          body: formdata,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.success == false) {
              createPopup({
                type: "reTry",
                title: "시간변경 실패",
                content: data.alert,
              });
            }
          });
      });
  }, []);

  return (
    <>
      <div className="pc_right_area">
        <ChatHeader title="알림설정" />
        <div className="setting_gray_wrapper">
          <div className="setting_contents_wrapper">
            <ul className="alarm_setting_list">
              <li>
                <span>모든 팀워크 알림</span>
                <div className="common_toggle_button">
                  <p />
                </div>
              </li>
              <li>
                <span>보낸 일정 알림</span>
                <div className="common_toggle_button">
                  <p className="active" />
                </div>
              </li>
              <li>
                <span>수락한 일정 알림</span>
                <div className="common_toggle_button">
                  <p />
                </div>
              </li>
              <li className="timer">
                <span>알림 시작 시간</span>
                <select>
                  <option value={1}>12시간 전</option>
                  <option value={2}>11시간 전</option>
                  <option value={3}>10시간 전</option>
                  <option value={4}>9시간 전</option>
                  <option value={5}>8시간 전</option>
                  <option value={6}>7시간 전</option>
                  <option value={7}>6시간 전</option>
                  <option value={8}>5시간 전</option>
                  <option value={9}>4시간 전</option>
                  <option value={10}>3시간 전</option>
                  <option value={11}>2시간 전</option>
                  <option value={12}>1시간 전</option>
                </select>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default Alarm;
