// 2022-02-24 인수인계
// 기능 : 결제 리스트 페이지입니다.

import React, { useEffect } from "react";
import "../assets/css/main_2.css";
import { useNavigate } from "react-router-dom";
import {
  getParameterByName,
  getToday,
  lastWeek,
  noData,
  numberWithCommas,
} from "../shared/script.js";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
import createPopup from "./createPopup";
import * as Calendar from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { filteringBtns } from "../shared/script.js";

const PaymentDetail = () => {
  const navigate = useNavigate();
  useEffect(() => {
    let requestData = {
      start_date: lastWeek(),
      end_date: getToday(),
      status: "complete",
    };

    let isSubs;
    fetch(`https://api.olapse.fifteenh.io/api/data/store`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.data.user_data_store == null) {
          isSubs = false;
        } else {
          isSubs = true;
        }
      });

    //디폴트 데이터
    excuteGetDetailInfo();

    window.FullCalendar = Calendar;
    window.dayGridPlugin = dayGridPlugin;
    window.listPlugin = listPlugin;
    window.interactionPlugin = interactionPlugin;

    document.querySelectorAll(
      "div.data_wrapper > div.data_select_row > div.data_info > span"
    )[0].textContent = `${lastWeek()} ~ ${getToday()}`;
    document.querySelectorAll(".start_time_data")[0].textContent = lastWeek();
    document.querySelectorAll(".end_time_data")[0].textContent = getToday();

    const calendarEl = document.querySelectorAll(".fullcal_wrapper")[0];
    const calendar = new FullCalendar.Calendar(calendarEl, {
      plugins: [dayGridPlugin, listPlugin, interactionPlugin],
      initialView: "dayGridMonth",
      dateClick: function (info) {
        if (document.querySelectorAll(".fc_actived_day")[0]) {
          document.querySelectorAll(".fc_actived_day").forEach((item, idx) => {
            item.classList.remove("fc_actived_day");
          });
        }
        info.dayEl.querySelectorAll("a")[0].classList.add("fc_actived_day");
        document.querySelectorAll(
          "div.popup_wrapper_2 div.date_picker_box div.time_col div.col.active span"
        )[0].textContent = info.dateStr;
      },
    });

    calendar.setOption("locale", "ko");
    calendar.render();
    document
      .querySelectorAll(
        "div.data_wrapper > div.data_select_row > div.data_info > span"
      )[0]
      .addEventListener("click", function () {
        document.querySelectorAll(".popup_wrapper_2")[0].style.display = "flex";
        calendar.render();
      });
    //적용
    document
      .querySelectorAll(".submit_date_popup")[0]
      .addEventListener("click", function () {
        requestData.start_date =
          document.querySelectorAll(".start_time_data")[0].textContent;
        requestData.end_date =
          document.querySelectorAll(".end_time_data")[0].textContent;
        document.querySelectorAll(
          "div.data_wrapper > div.data_select_row > div.data_info > span"
        )[0].textContent = `${requestData.start_date} ~ ${requestData.end_date}`;
        excuteGetDetailInfo();
        document.querySelectorAll(".popup_wrapper_2")[0].style.display = "none";
      });
    document
      .querySelectorAll(".hide_date_popup")[0]
      .addEventListener("click", function () {
        document.querySelectorAll(".popup_wrapper_2")[0].style.display = "none";
      });
    filteringBtns(
      "div.popup_wrapper_2 div.date_picker_box div.time_col div.col",
      "active"
    );

    document
      .querySelectorAll(".excel_download")[0]
      .addEventListener("click", function () {
        if (isSubs == false) {
          createPopup({
            type: "custom_select_2",
            title: "엑셀다운로드 안내",
            content: `<span>엑셀 다운로드를 받으시기 위해서는<br>데이터 저장소 구독이 필요합니다.</span><br><span>데이터 저장소 구독은<br><p class="blue">[내정보 > 데이터저장소 > 구독]</p>에서 구독할 수 있습니다.</span>`,
            firstButtonClickFunction: () => {
              navigate("/more/data_save_1");
            },
            secondButtonClickFunction: () => {},
            button_text_1: "취소",
            button_text_2: "구독",
          });
        } else {
          window.location.href = setDownloader();
        }
      });

    function excuteGetDetailInfo() {
      if (document.querySelectorAll(".payment_history_list li")[0]) {
        document
          .querySelectorAll(".payment_history_list li")
          .forEach((item, idx) => {
            item.remove();
          });
      }

      fetch(
        `https://api.olapse.fifteenh.io/api/teamwork/member/payment?id=my&begin_date=${requestData.start_date}&end_date=${requestData.end_date}&status=${requestData.status}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          document.querySelectorAll(
            "div.data_wrapper > div.data_price_row > h1"
          )[0].innerHTML = `<span>${
            data.data.schedules.length
          }건</span><span>${numberWithCommas(data.data.total_price)}원</span>`;
          if (data.data.schedules.length !== 0) {
            noData(false);
            data.data.schedules.forEach((item, idx) => {
              const htmlString = `<li idx="${
                item.id_
              }" class="new_item"><h1>${numberWithCommas(
                item.price
              )}원</h1><span>일정완료일 : ${
                item.completed_at.split(" ")[0]
              }</span></li>`;
              document
                .querySelectorAll(".payment_history_list")[0]
                .insertAdjacentHTML("beforeend", htmlString);
              document
                .querySelectorAll(".new_item")[0]
                .addEventListener("click", function () {
                  navigate(
                    `/more/payment_detail_2?idx=${item.id}&type=${item.type}`
                  );
                });
              document
                .querySelectorAll(".new_item")[0]
                .classList.remove("new_item");
            });
          } else {
            noData(true);
          }
        });
    }

    //status변경
    document
      .querySelectorAll(".send_data select")[0]
      .addEventListener("change", function () {
        requestData.status = this.querySelectorAll("option:checked")[0].value;
        excuteGetDetailInfo();
      });

    function setDownloader() {
      return `https://api.olapse.fifteenh.io/api/v2/teamwork/member/payment/excel?id=my&begin_date=${
        requestData.start_date
      }&end_date=${requestData.end_date}&status=${
        requestData.status
      }&access_token=${localStorage.getItem("token")}`;
    }
  }, []);

  return (
    <>
      <div className="pc_right_area">
        <div className="pc_right_area_top">
          <div className="left">
            <img
              onClick={() => navigate(-1)}
              src="/assets/images/icon_back.svg"
              alt=""
            />
          </div>
          <h1 className="right_area_title">결제내역관리</h1>
        </div>
        <div className="data_wrapper">
          {/* 설정 */}
          <div className="data_select_row">
            <div className="send_data">
              <select className="cal_select">
                <option value={"complete"}>결제 완료</option>
                <option value={"calculate"}>정산 완료</option>
                <option value={"cancel"}>결제 취소</option>
              </select>
            </div>
            <div className="data_info">
              <img src="/assets/images/icon_date.svg" alt="" />
              <span></span>
            </div>
          </div>
          {/* 금액 */}
          <div className="data_price_row">
            <h1>
              <span></span>
              <span></span>
            </h1>
            <span className="date_price_sub_notice">
              매월 1일, 정산일에 대한 안내
              <br />
              (주말, 공휴일일 경우 다음 영업일)
            </span>
          </div>

          <div className="payment_history_wrapper">
            <div className="data_detail_select">
              <div className="select_sort"></div>
              <div className="select_right">
                <img
                  className="excel_download"
                  src="/assets/images/icon_download.svg"
                  alt=""
                />
              </div>
            </div>
            <ul className="payment_history_list"></ul>
            <div className="no_data">
              <span>결제내역이 없습니다.</span>
            </div>
          </div>
        </div>
        <div className="popup_wrapper_2">
          <div className="date_picker_box">
            <h1>일시 설정</h1>
            <div className="time_col">
              <div className="time_row start">
                <div className="col active">
                  <span className="start_time_data" />
                  <img src="/assets/images/icon_cal_g.svg" alt="" />
                </div>
              </div>
              <div className="time_row end">
                <div className="col">
                  <span className="end_time_data" />
                  <img src="/assets/images/icon_cal_g.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="fullcal_wrapper"></div>
            <ul className="button_wrapper">
              <li className="hide_date_popup">취소</li>
              <li className="blue submit_date_popup">적용</li>
            </ul>
          </div>
          <div className="background_black" />
        </div>
      </div>
    </>
  );
};
export default PaymentDetail;
