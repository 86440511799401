// 2022-02-24 인수인계
// 기능 : 공지사항 상세 페이지입니다.

import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ChatHeader from "./chat_header";
import { noData, getParameterByName } from "../shared/script";
const NoticeDetail = () => {
  const navigate = useNavigate();
  useEffect(() => {
    fetch(
      `https://api.olapse.fifteenh.io/api/notice/detail?id=${getParameterByName(
        "idx"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        document.querySelectorAll(
          "div.notice_detail_wrapper div.title_area h1"
        )[0].textContent = data.data.notice.title;
        document.querySelectorAll(
          "div.notice_detail_wrapper div.title_area span"
        )[0].textContent = data.data.notice.created_at.split(" ")[0];
        document.querySelectorAll(".notice_contents")[0].innerHTML =
          data.data.notice.contents;
      });
  }, []);

  return (
    <>
      <div className="pc_right_area">
        <ChatHeader title="공지사항" />
        <div className="notice_detail_wrapper">
          <div className="title_area">
            <h1 />
            <span />
          </div>
          <div className="notice_contents" />
        </div>
      </div>
    </>
  );
};
export default NoticeDetail;
