// 2022-02-24 인수인계
// 기능 : 데이터 저장소 구독(결제) 페이지입니다.

import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  numberWithCommas,
  toggleClass,
  getToday,
  filteringBtns,
} from "../shared/script";
import ChatHeader from "./chat_header";
import createPopup from "./createPopup";
const Subs = () => {
  const navigate = useNavigate();
  useEffect(() => {
    filteringBtns(
      "div.data_save_subs_wrapper div.added_info_row ul li",
      "active"
    );

    //구독하기
    var orderTime = 0;

    function order() {
      if (
        String(document.querySelector("#card_number").value).length !== 0 &&
        String(document.querySelector("#due_Date").value).length !== 0 &&
        String(document.querySelector("#card_password").value).length !== 0 &&
        String(document.querySelector("#birth").value).length !== 0
      ) {
        //주문코드 발행
        let formdata = new FormData();
        fetch(`https://api.olapse.fifteenh.io/api/data/store/payment`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.success == true) {
              excuteOrder(data.data.order_number);
            } else {
              orderTime = 0;
              createPopup({
                type: "reTry",
                title: "결제 실패",
                content: data.alert,
              });
            }
          });
      } else {
        orderTime = 0;
        createPopup({
          type: "reTry",
          title: "요청 실패",
          content: "모든 정보를 입력해주세요.",
        });
      }

      function receivePurchase(result) {
        if (result.success == "true") {
          createPopup({
            type: "custom",
            title: "구독성공",
            content: "데이터저장소 구독을 시작하였습니다.",
            custom: () => {
              navigate("/more");
            },
          });
        } else {
          orderTime = 0;
          createPopup({
            type: "reTry",
            title: "결제 실패",
            content:
              result.alert == undefined ||
              result.alert == null ||
              result.alert == ""
                ? "결제에 실패하였습니다.<br>다시 시도해주세요."
                : result.alert,
          });
        }
      }

      function excuteOrder(orderNumber) {
        let isIos = navigator.userAgent.toLowerCase();
        let formdata = new FormData();
        let varUAApp = navigator.userAgent;

        // if (varUAApp.match('olapse/iOS') != null || varUAApp.match('olapse/ios') != null) {
        //     window.webkit.messageHandlers.inAppPurchases.postMessage({});
        // }else{
        formdata.append("order_number", orderNumber);
        formdata.append(
          "card_num",
          document.querySelector("#card_number").value
        );

        let dueDate = document.querySelector("#due_Date").value;
        formdata.append(
          "card_expire",
          dueDate.substr(2, 2) + dueDate.substr(0, 2)
        );

        formdata.append(
          "card_pwd",
          document.querySelector("#card_password").value
        );
        formdata.append("id_num", document.querySelector("#birth").value);
        fetch(`https://api.olapse.fifteenh.io/api/data/store/payment/begin`, {
          method: "POST",
          body: formdata,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.success == true) {
              createPopup({
                type: "custom",
                title: "구독성공",
                content: "데이터저장소 구독을 시작하였습니다.",
                custom: () => {
                  navigate("/more");
                },
              });
            } else {
              orderTime = 0;
              createPopup({
                type: "reTry",
                title: "결제 실패",
                content: data.alert,
              });
            }
          });
        // }
      }
    }

    document
      .querySelectorAll("div.data_save_subs_wrapper div.added_info_row ul li")
      .forEach((item, idx) => {
        item.addEventListener("click", function () {
          if (this.textContent == "개인") {
            document.querySelectorAll(".switch_target")[0].textContent =
              "생년월일";
            document
              .querySelector("#birth")
              .setAttribute("placeholder", "yymmdd");
          } else {
            document.querySelectorAll(".switch_target")[0].textContent =
              "법인등록번호";
            document
              .querySelector("#birth")
              .setAttribute("placeholder", "법인등록번호");
          }
        });
      });

    document
      .querySelectorAll(".payment_submit_button")[0]
      .addEventListener("click", function () {
        if (orderTime == 0) {
          orderTime = 1;
          order();
        }
      });
  }, []);
  return (
    <>
      <div className="pc_right_area">
        <ChatHeader title="데이터저장소 구독하기" />
        <div className="data_save_subs_wrapper">
          <div className="data_save_subs_row">
            <h1>카드번호</h1>
            <input
              id="card_number"
              placeholder="카드번호 (‘-’ 제외)"
              type="number"
            />
          </div>
          <div className="data_save_subs_row_type_2">
            <h1>유효기간</h1>
            <input id="due_Date" placeholder="MMYY" type="number" />
          </div>
          <div className="data_save_subs_row_type_3">
            <h1>비밀번호 앞 2자리</h1>
            <input
              style={{ paddingLeft: "1rem" }}
              id="card_password"
              type="number"
            />
            <span>**</span>
          </div>
          <div className="added_info_row">
            <h1>추가정보</h1>
            <ul>
              <li className="active">개인</li>
              <li>법인</li>
            </ul>
          </div>
          <div className="data_save_subs_row">
            <h1 className="switch_target">생년월일</h1>
            <input id="birth" placeholder="yymmdd" type="number" />
          </div>
          <button className="payment_submit_button">결제 등록</button>
        </div>
      </div>
    </>
  );
};
export default Subs;
