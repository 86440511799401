// 2022-02-24 인수인계
// 기능 : 멤버 추가

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getParameterByName,
  toggleClass,
  noData,
  phoneCall,
  closeAllPopup,
} from "../shared/script.js";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
import createPopup from "../components/createPopup";
const AddMember = () => {
  const navigate = useNavigate();
  useEffect(() => {
    initAddMember();

    function addMember() {
      const triggerElement = document.querySelectorAll(
        "div.chat_header ul li.addMemberBtn"
      )[0];
      if (triggerElement) {
        triggerElement.addEventListener("click", function () {
          document.querySelectorAll(".member_add_module")[0].style.display =
            "flex";
          _animationAppearTopDown(
            document.querySelectorAll(".member_add_module_box")[0]
          );
        });
      }
      if (document.querySelectorAll(".no_data_addMember")[0]) {
        document
          .querySelectorAll(".no_data_addMember")[0]
          .addEventListener("click", function () {
            document.querySelectorAll(".member_add_module")[0].style.display =
              "flex";
            _animationAppearTopDown(
              document.querySelectorAll(".member_add_module_box")[0]
            );
          });
      }
    }
    //전화번호로 추가 트리거
    function addForNumber() {
      const triggerElement = document.querySelectorAll(
        "div.member_add_module div.member_add_module_box ul li"
      )[0];
      if (triggerElement) {
        triggerElement.addEventListener("click", function () {
          _animationDisappearBottomUpV3(
            document.querySelectorAll(".member_add_module_box")[0]
          );
          setTimeout(() => {
            _animationAppearTopDown(
              document.querySelectorAll(".add_for_number_box")[0]
            );
          }, 300);
        });
      }
    }
    //멤버추가 실행
    function addMemberExcute(id) {
      let formdata = new FormData();
      formdata.append("ids[]", id);
      fetch("https://api.olapse.fifteenh.io/api/member/add", {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            createPopup({
              type: "custom",
              title: "멤버 추가 완료",
              content: "멤버로 추가되었습니다.",
              custom: () => {
                if (document.querySelectorAll(".no_memberData")[0]) {
                  document.querySelectorAll(".no_memberData")[0].style.display =
                    "none";
                }
                window.location.reload(true);
              },
            });
          } else {
            createPopup({
              type: "confirm",
              title: "추가 실패",
              content: data.alert,
            });
          }
        });
    }

    //멤버추가 복수
    function addMemberExcuteArr(idArr) {
      let formdata = new FormData();

      idArr.forEach((item) => {
        formdata.append("ids[]", item);
      });

      fetch("https://api.olapse.fifteenh.io/api/member/add", {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            createPopup({
              type: "custom",
              title: "멤버 추가 완료",
              content: "멤버로 추가되었습니다.",
              custom: () => {
                if (document.querySelectorAll(".no_memberData")[0]) {
                  document.querySelectorAll(".no_memberData")[0].style.display =
                    "none";
                }
                window.location.reload(true);
              },
            });
          }
        });
    }

    //팝업 전체 닫기
    function cancelAllPopup() {
      document.querySelectorAll(".member_add_module_box")[0].style.display =
        "none";
      document.querySelectorAll(".member_add_module")[0].style.display = "none";
      document.querySelectorAll(".add_for_number_box")[0].style.display =
        "none";
      document.querySelectorAll(".add_for_number_result_box")[0].style.display =
        "none";
      document.querySelectorAll(".add_me_wrapper_box")[0].style.display =
        "none";
      document.querySelectorAll(".add_for_id_box")[0].style.display = "none";
    }

    //전화번호로 검색
    function searchForPhoneNumber(phoneNumber) {
      fetch(
        `https://api.olapse.fifteenh.io/api/member/find/phone?phone=${phoneNumber}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true && data.data.user) {
            _animationDisappearBottomUpV3(
              document.querySelectorAll(".add_for_number_box")[0]
            );
            setTimeout(() => {
              _animationAppearTopDown(
                document.querySelectorAll(".add_for_number_result_box")[0]
              );
              document.querySelectorAll(
                "div.add_for_number_result_box div.profile p.profile_img"
              )[0].style.background = `url('${
                data.data.user.profile_url
                  ? data.data.user.profile_url
                  : "/assets/images/icon_profile_basic.svg"
              }')`;
              document.querySelectorAll(
                "div.add_for_number_result_box div.profile h1"
              )[0].textContent = data.data.user.name;
              document
                .querySelectorAll(
                  "div.add_for_number_result_box ul.popup_button_wrapper li"
                )[1]
                .setAttribute("idx", data.data.user.id);
            }, 300);
          } else if (data.data.user == null) {
            createPopup({
              type: "reTry",
              title: "조회 불가",
              content: "입력하신 전화번호로 조회된 회원이 없습니다.",
            });
          }
        });
    }

    //아이디로 검색
    function searchForIdNumber(userId) {
      fetch(`https://api.olapse.fifteenh.io/api/member/find/id?id=${userId}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true && data.data.user) {
            _animationDisappearBottomUpV3(
              document.querySelectorAll(".add_for_id_box")[0]
            );
            setTimeout(() => {
              _animationAppearTopDown(
                document.querySelectorAll(".add_for_number_result_box")[0]
              );
              document.querySelectorAll(
                "div.add_for_number_result_box div.profile p.profile_img"
              )[0].style.background = `url('${
                data.data.user.profile_url
                  ? data.data.user.profile_url
                  : "/assets/images/icon_profile_basic.svg"
              }')`;
              document.querySelectorAll(
                "div.add_for_number_result_box div.profile h1"
              )[0].textContent = data.data.user.name;
              document
                .querySelectorAll(
                  "div.add_for_number_result_box ul.popup_button_wrapper li"
                )[1]
                .setAttribute("idx", data.data.user.id);
            }, 300);
          } else if (data.data.user == null) {
            createPopup({
              type: "reTry",
              title: "조회 불가",
              content: "입력하신 아이디로 조회된 회원이 없습니다.",
            });
          }
        });
    }

    //나를 추가한 멤버
    function getAddMeList() {
      fetch("https://api.olapse.fifteenh.io/api/member/follower", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.data.user_friends.data.length !== 0) {
            document.querySelectorAll(".memeber_none")[0].style.display =
              "none";
            const appendTarget = document.querySelectorAll(
              "div.add_me_wrapper_box ul:nth-of-type(1)"
            )[0];
            appendTarget.querySelectorAll("li").forEach((item, idx) => {
              item.remove();
            });
            data.data.user_friends.data.forEach((item, idx) => {
              let HTMLString = `<li class="new_added_item" idx="${
                item.id
              }"> <p class="new_added_profile_img"></p> <div class="added_itemNameBox"><span>${
                item.name
              }</span><b>${
                item.message == null ? "" : item.message
              }</b></div> </li>`;
              appendTarget.insertAdjacentHTML("beforeend", HTMLString);
              document.querySelectorAll(
                ".new_added_profile_img"
              )[0].style.background = `url('${
                item.profile_url
                  ? item.profile_url
                  : "/assets/images/icon_profile_basic.svg"
              }')`;

              document
                .querySelectorAll(".new_added_item")[0]
                .addEventListener("click", function () {
                  this.classList.contains("active")
                    ? this.classList.remove("active")
                    : this.classList.add("active");
                });
              document
                .querySelectorAll(".new_added_profile_img")[0]
                .classList.remove("new_added_profile_img");
              document
                .querySelectorAll(".new_added_item")[0]
                .classList.remove("new_added_item");
            });
          } else {
            document.querySelectorAll(".memeber_none")[0].style.display =
              "block";
          }
        });
    }

    //시작
    function initAddMember() {
      addMember();
      addForNumber();
      const cancelElementArr = [
        document.querySelectorAll(
          "div.add_for_number_result_box ul.popup_button_wrapper li button"
        )[0],
        document.querySelectorAll(
          "div.add_for_number_box ul.popup_button_wrapper li button"
        )[0],
        document.querySelectorAll(
          "div.add_for_id_box ul.popup_button_wrapper li"
        )[0],
        document.querySelectorAll(
          "div.add_me_wrapper_box ul.popup_button_wrapper li"
        )[0],
      ];
      if (
        document.querySelectorAll(
          "div.add_for_number_result_box ul.popup_button_wrapper li button"
        )[0]
      ) {
        cancelElementArr.forEach((item, idx) => {
          item.addEventListener("click", function () {
            cancelAllPopup();
          });
        });
        document
          .querySelectorAll(
            "div.add_for_number_box ul.popup_button_wrapper li:nth-of-type(2) button"
          )[0]
          .addEventListener("click", function () {
            String(document.querySelector("#add_phone_number").value).length >
              0 &&
              searchForPhoneNumber(
                document.querySelector("#add_phone_number").value
              );
          });

        document
          .querySelectorAll(
            "div.add_for_number_result_box ul.popup_button_wrapper li"
          )[1]
          .addEventListener("click", function () {
            addMemberExcute(this.getAttribute("idx"));
          });

        document
          .querySelectorAll(".member_add_module_box ul li")[2]
          .addEventListener("click", function () {
            _animationDisappearBottomUpV3(
              document.querySelectorAll(".member_add_module_box")[0]
            );
            setTimeout(() => {
              _animationAppearTopDown(
                document.querySelectorAll(".add_me_wrapper_box")[0]
              );
              getAddMeList();
            }, 300);
          });

        document
          .querySelectorAll(".member_add_module_box ul li")[1]
          .addEventListener("click", function () {
            _animationDisappearBottomUpV3(
              document.querySelectorAll(".member_add_module_box")[0]
            );
            setTimeout(() => {
              _animationAppearTopDown(
                document.querySelectorAll(".add_for_id_box")[0]
              );
            }, 300);
          });

        document
          .querySelectorAll("div.add_for_id_box ul.popup_button_wrapper li")[1]
          .addEventListener("click", function () {
            String(document.querySelector("#add_id_number").value).length > 1 &&
              searchForIdNumber(document.querySelector("#add_id_number").value);
          });

        document
          .querySelectorAll(
            "div.add_me_wrapper_box ul.popup_button_wrapper li"
          )[1]
          .addEventListener("click", function () {
            let activedArr = [];
            if (
              document.querySelectorAll(
                "div.add_me_wrapper_box > ul:nth-of-type(1) li.active"
              )[0]
            ) {
              document
                .querySelectorAll(
                  "div.add_me_wrapper_box > ul:nth-of-type(1) li.active"
                )
                .forEach((item, idx) => {
                  activedArr.push(item.getAttribute("idx"));
                });
            }
            addMemberExcuteArr(activedArr);
          });
      }
    }

    function chatWithMe() {
      let formdata = new FormData();
      formdata.append("title", "나와의 채팅");
      formdata.append("use_card", "0");
      formdata.append("use_password", "0");
      formdata.append("use_view_profile", "0");
      fetch(`https://api.olapse.fifteenh.io/api/teamwork/register`, {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            if (localStorage.getItem("chat_with_me_id")) {
              navigate(`/chat?idx=${localStorage.getItem("chat_with_me_id")}`);
            } else {
              navigate("/chat");
            }
          } else {
            createPopup({
              type: "confirm",
              title: "생성 실패",
              content: data.alert,
            });
          }
        });
    }

    document
      .querySelectorAll(".chat_with_me")[0]
      .addEventListener("click", function () {
        chatWithMe();
      });
  }, []);
  return (
    <>
      <div className="member_add_module">
        <div className="member_add_module_box">
          <h1>멤버 추가</h1>
          <ul>
            <li>
              <p /> <span>연락처로 추가</span>
            </li>
            <li>
              <p /> <span>ID로 추가</span>
            </li>
            <li>
              <p /> <span>나를 추가한 멤버</span>
            </li>
          </ul>
        </div>
        <div className="add_for_number_box">
          <h1>연락처로 추가</h1>{" "}
          <input
            placeholder="연락처 입력 (‘-’ 제외)"
            id="add_phone_number"
            type="number"
          />
          <ul className="popup_button_wrapper">
            <li>
              <button>취소</button>
            </li>
            <li>
              <button>검색</button>
            </li>
          </ul>
        </div>
        <div className="add_for_id_box">
          <h1>아이디로 추가</h1>{" "}
          <input placeholder="아이디 입력" id="add_id_number" type="text" />
          <ul className="popup_button_wrapper">
            <li>
              <button>취소</button>
            </li>
            <li>
              <button>검색</button>
            </li>
          </ul>
        </div>

        <div className="add_for_number_result_box">
          <div className="profile">
            <p className="profile_img" />
            <h1></h1>
          </div>
          <ul className="popup_button_wrapper">
            <li>
              <button>취소</button>
            </li>
            <li>
              <button>멤버 추가</button>
            </li>
          </ul>
        </div>
        <div className="add_me_wrapper_box">
          <h1>나를 추가한 멤버</h1>
          <ul />
          <span className="memeber_none">나를 추가한 멤버가 없습니다.</span>
          <ul className="popup_button_wrapper">
            <li>
              <button>취소</button>
            </li>
            <li>
              <button>멤버 추가</button>
            </li>
          </ul>
        </div>

        <div onClick={() => closeAllPopup()} className="background_black" />
      </div>
      <div className="view_profile_module">
        <div className="view_my_profile">
          <img
            onClick={() => navigate("/more/edit_info")}
            className="view_my_profile_edit_button"
            src="/assets/images/icon_editr.svg"
            alt=""
          />
          <div className="view_profile_inner">
            <p>
              <b>i</b>
            </p>
            <h1 /> <span />
            <h5></h5>
          </div>
          <ul className="view_profile_sub_list">
            <li className="chat_with_me">
              <p /> <span>나와의 채팅</span>
            </li>
            <li onClick={() => navigate("/cal")}>
              <p /> <span>내 일정 보기</span>
            </li>
          </ul>
        </div>
        <div className="view_my_profile view_member_profile">
          <img
            className="view_my_profile_more_button"
            src="/assets/images/icon_more_right.svg"
            alt=""
          />
          <ul className="report_sub_popup">
            <li>멤버 신고</li> <li>멤버 삭제</li>
          </ul>
          <div className="view_profile_inner">
            <p>
              <b>i</b>
            </p>
            <h5></h5>
            <h1 /> <span />
          </div>
          <ul className="view_profile_sub_list">
            <li>
              <p /> <span>새 팀워크 개설</span>
            </li>
            <li>
              <p /> <span>010-1111-1111</span>
            </li>
          </ul>
        </div>
        <div className="report_popup_wrapper">
          <h1>신고 사유 선택</h1>
          <ul className="report_type_list">
            <li>
              <label>
                <input
                  defaultChecked
                  reason="광고/사기"
                  name="report_type"
                  type="radio"
                />{" "}
                <span>광고/사기</span>
              </label>
            </li>
            <li>
              <label>
                <input reason="유해성 메세지" name="report_type" type="radio" />{" "}
                <span>유해성 메세지</span>
              </label>
            </li>
            <li>
              <label>
                <input reason="기타" name="report_type" type="radio" />{" "}
                <span>기타</span>
              </label>
            </li>
          </ul>
          <div className="file_add_area">
            <h1>스크린샷 첨부</h1>
            <ul className="b_submit_file_list">
              <li className="file_add_button">
                <label>
                  <input type="file" />
                </label>
              </li>
            </ul>
          </div>
          <ul className="report_popup_wrapper_button_list">
            <li>취소</li> <li className="red">신고</li>
          </ul>
        </div>
        <div onClick={() => closeAllPopup()} className="background_black" />
      </div>
    </>
  );
};
export default AddMember;
