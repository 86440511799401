// 2022-02-24 인수인계
// 기능 : 뒤로가기와 페이지 타이틀만 존재하는 페이지의 헤더입니다.

import {useNavigate} from "react-router-dom";

const ElseHeader = (props) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="header">
                <div onClick={() => navigate(-1)} className="left">
                    <img src="/assets/images/icon_back.svg" alt="" />
                </div>
                <div className="center">{props.title}</div>
            </div>
        </>
    );
};

export default ElseHeader;
