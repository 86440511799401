// 2022-02-24 인수인계
// 기능 : 아이디 변경 페이지입니다.

import React, { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import ElseHeader from "../components/elseHeader";
import {
  getParameterByName,
  toggleClass,
  inputChanger,
} from "../shared/script.js";
import createPopup from "../components/createPopup";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";

const ChangeId = () => {
  const navigate = useNavigate();
  useEffect(() => {
    inputChanger();
    let availableId = false;
    let idPass = /^[a-z0-9_-]{4,12}$/;
    document
      .querySelectorAll(".changeId_submit")[0]
      .addEventListener("click", function () {
        changeId();
      });
    //아이디 변경
    function changeId() {
      if (
        String(document.querySelector("#now_id").value).length !== 0 &&
        String(document.querySelector("#password").value).length !== 0 &&
        String(document.querySelector("#new_name").value).length !== 0
      ) {
        if (availableId) {
          let formdata = new FormData();
          formdata.append("login_id", document.querySelector("#now_id").value);
          formdata.append(
            "password",
            document.querySelector("#password").value
          );
          formdata.append(
            "new_login_id",
            document.querySelector("#new_name").value
          );
          fetch("https://api.olapse.fifteenh.io/api/id/modify", {
            method: "POST",
            body: formdata,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (data) {
              if (data.success == true) {
                createPopup({
                  type: "custom",
                  title: "아이디 완료",
                  content: "아이디를 변경하였습니다.",
                  custom: () => {
                    navigate("/login");
                  },
                });
              } else {
                document.querySelectorAll(".error_1")[0].textContent =
                  data.alert;
              }
            });
        } else {
          document.querySelectorAll(".error_3")[0].textContent =
            "아이디 중복확인을 진행해주세요.";
        }
      } else {
        createPopup({
          type: "reTry",
          title: "아이디 변경 실패",
          content: "모든 정보를 입력해주새요.",
        });
      }
    }

    document
      .querySelectorAll(".id_check_button")[0]
      .addEventListener("click", function () {
        if (String(document.querySelector("#new_name").value).length !== 0) {
          checkId(document.querySelector("#new_name").value);
        } else {
          createPopup({
            type: "reTry",
            title: "아이디 확인",
            content: "변경할 아이디를 입력해주세요.",
          });
        }
      });

    function checkId(id) {
      if (idPass.test(document.querySelector("#new_name").value)) {
        let formdata = new FormData();
        formdata.append("login_id", id);
        fetch(`https://api.olapse.fifteenh.io/api/id/check`, {
          method: "POST",
          body: formdata,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.success == true) {
              availableId = true;
              createPopup({
                type: "confirm",
                title: "아이디 중복확인",
                content: "사용가능한 아이디입니다.",
              });
            } else {
              availableId = false;
              createPopup({
                type: "confirm",
                title: "아이디 중복확인",
                content: "이미 사용중인 아이디입니다.",
              });
            }
          });
      } else {
        createPopup({
          type: "reTry",
          title: "아이디 재확인",
          content:
            "영문 소문자,숫자,언더바,하이픈만 이용 가능하며, <br /> 4~12자리로 입력해주세요.",
        });
      }
    }
  }, []);
  return (
    <>
      <div id="wrap">
        <ElseHeader title="아이디 수정" />
        <div className="user_wrapper">
          <div className="input_wrapper">
            <div className="input_row">
              <h1>현재 아이디</h1>
              <div className="input_outter">
                <input id="now_id" placeholder="아이디" type="text" />
              </div>
              <span className="red error_1" />
            </div>
            <div className="input_row mt_15">
              <h1>비밀번호</h1>
              <div className="input_outter">
                <input placeholder="비밀번호" id="password" type="password" />
              </div>
              <span className="red error_2" />
            </div>
            <div className="input_row mt_15">
              <h1>새 아이디</h1>
              <div className="input_outter">
                <input id="new_name" placeholder="새 아이디" type="text" />
                <button className="id_check_button">중복확인</button>
              </div>
              <span className="red error_3" />
            </div>
          </div>
          <button className="changeId_submit button_type_1 next_button">
            수정완료
          </button>
        </div>
      </div>
    </>
  );
};

export default ChangeId;
