// 2022-02-24 인수인계
// 기능 : 일정 상세 페이지입니다.

import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import "../assets/css/cal.css";
import {getParameterByName, toggleClass, IsLogin, noData, getToday, numberWithCommas} from "../shared/script.js";
import createPopup from "../components/createPopup";
import {_animationAppearTopDown, _animationAppearLeft, _animationDisappearRight, _animationAppearRight, _animationDisappearLeft, _animationDisappearBottomUp, _animationDisappearBottomUpV2, _animationDisappearBottomUpV3, _popupAnimationAppearTopDown, _popupAnimationDisappearBottomUp, _agreePopupActive, _agreePopupOut, _controllerActive, _controllerDisappear, _animateRightModule} from "../components/animation.js";
import MainMenu from "../components/main_menu";
import TeamworkList from "../components/teamworkList";
import anime from "animejs";
import Basic from "../components/basic";
import ChatInner from "../components/chat_inner";
import ChatHeader from "../components/chat_header";
import * as Calendar from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import {updateScheduleData} from "../shared/chat";
import TeamworkModule from "../components/teamworkModules";
import {inputChanger} from "../shared/script.js";
const DateDetail = () => {
    const navigate = useNavigate();
    useEffect(() => {
        inputChanger();
        updateScheduleData(getParameterByName("idx"));
        _animateRightModule(true, document.querySelectorAll(".d_4")[0]);
    }, []);
    return (
        <>
            <div className="wrap">
                <MainMenu />
                <TeamworkModule />
                <Basic />
            </div>
        </>
    );
};

export default DateDetail;
