// 2022-02-24 인수인계
// 기능 : 회원탈퇴 페이지입니다.

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import ElseHeader from "../components/elseHeader";
import {
  getParameterByName,
  toggleClass,
  inputChanger,
} from "../shared/script.js";
import createPopup from "../components/createPopup";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";

const Exit = () => {
  const navigate = useNavigate();
  useEffect(() => {
    inputChanger();
    document
      .querySelectorAll("div.exit_notice_wrapper button.exit_excute_button")[0]
      .addEventListener("click", function () {
        fetch("https://api.olapse.fifteenh.io/api/mypage", {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.success == true) {
              if (data.data.user_data_store !== null) {
                createPopup({
                  type: "confirm",
                  title: "회원탈퇴 불가",
                  content:
                    "데이터 저장소 구독 중인 회원은<br>회원탈퇴가 불가능합니다.",
                });
              } else {
                endUser();
              }
            } else {
              createPopup({
                type: "confirm",
                title: "회원탈퇴 불가",
                content: data.alert,
              });
            }
          });
      });

    function endUser() {
      fetch("https://api.olapse.fifteenh.io/api/withdrawal", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            createPopup({
              type: "custom",
              title: "회원탈퇴 완료",
              content:
                "회원탈퇴가 완료되었습니다.<br />그동안 오랩스를 이용해주셔 감사합니다.",
              custom: function () {
                localStorage.removeItem("token");
                localStorage.removeItem("refresh_token");
                navigate("/login");
              },
            });
          } else {
            createPopup({
              type: "confirm",
              title: "회원탈퇴 불가",
              content: data.alert,
            });
          }
        });
    }
  }, []);
  return (
    <>
      <div id="wrap">
        <ElseHeader title="회원탈퇴" />
        <div className="exit_notice_wrapper">
          <div className="exit_notice_row">
            <h1>회원 탈퇴시 삭제 정보</h1>
            <span>
              - 멤버 목록, 팀워크 목록, 내가 생성한 팀워크 및 팀워크 내
              일정/사진/동영상/파일 등 모든 활동정보가 영구적으로 삭제되며, 복구
              불가능합니다.
            </span>
          </div>
          <div className="exit_notice_row">
            <h1>회원 탈퇴 불가 요건</h1>
            <span>
              - 결제 받은 일정 중 카드결제 연동(PG) 및 카드사를 통해
              [정산완료]처리 되지 않은 결제건이 존재할 경우 회원탈퇴가
              불가합니다.{" "}
            </span>
            <span>
              - 데이터 저장소의 구독 서비스 (정기 결제)는 자동으로 취소되지
              않습니다. 더 이상 이용을 원하지 않으실 경우 별도의 구독 취소 후
              회원탈퇴가 가능합니다.
            </span>
          </div>
          <button className="exit_excute_button">회원탈퇴</button>
        </div>
      </div>
    </>
  );
};

export default Exit;
